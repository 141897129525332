import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useApiUrl from '../../framework/hooks/useApiUrlHook';
import useAuth from '../../framework/hooks/useAuth';
import useRouteList from '../../framework/hooks/useRouteList';

export default function ForgotPasswordpage() {
    const [username, setUsername] = useState('');

    const routes = useRouteList();
    const auth = useAuth();
    const navigate = useNavigate();
    const apiUrl = useApiUrl();

    const handleLogin = (e) => {
        e.preventDefault();

        auth.forgotPasswordGetLink(username)
    };

    return (
        <>
            <div className="page-content pt-150 pb-150">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-10 col-md-12 m-auto">
                            <div className="row">
                                <div className="col-lg-6 pr-30 d-none d-lg-block">
                                    <img className="border-radius-15" src={`/assets/imgs/page/login-1.png`} alt="" />
                                </div>
                                <div className="col-lg-6 col-md-8">
                                    <div className="login_wrap widget-taber-content background-white">
                                        <div className="padding_eight_all bg-white">
                                            <div className="heading_s1">
                                                <h1 className="mb-5">Wachtwoord vergeten</h1>
                                                <p className="mb-30">
                                                    Al een account? <Link to={routes.login}>Inloggen</Link>
                                                </p>
                                                <p className="mb-30">
                                                    Nog geen account? <Link to={routes.register}>Registreer hier</Link>
                                                </p>
                                            </div>
                                            <form onSubmit={handleLogin}>
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        required=""
                                                        name="email"
                                                        placeholder="Username or Email *"
                                                        value={username}
                                                        onChange={(e) => setUsername(e.target.value)}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-heading btn-block hover-up"
                                                        name="login"
                                                    >
                                                        Nieuw wachtwoord aanamaken
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
