import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import StatusPill from '../../../components/backoffice/orders/StatusPill';
import useApiUrl from '../../../framework/hooks/useApiUrlHook';
import useHelpers from '../../../framework/hooks/useHelpers';
import useRouteList from '../../../framework/hooks/useRouteList';

export default function OrderOverview() {
    const routes = useRouteList();

    const apiUrl = useApiUrl();
    const { money } = useHelpers();

    const [orders, setOrders] = useState([]);
    const [filters, setFilters] = useState({});
    const [statuses, setStatuses] = useState([]);
    const [time, setTime] = useState(0);
    const [allowFetch, setAllowFetch] = useState(true);

    // Fetch orders.
    useEffect(() => {
        if (apiUrl !== null && allowFetch) {
            axios
                .post(`${apiUrl}/order/admin/get`, {filters})
                .then((response) => {
                    toast.info(`${response.data.length ?? 0} orders gevonden`);
                    setOrders(response.data);
                })
                .catch((e) => {
                    toast.error('Kon orders niet ophalen, filters mogelijk niet correct.');
                });
        }
    }, [apiUrl, allowFetch]);

    // Fetch list of available statuses
    useEffect(() => {
        if (apiUrl !== null && statuses.length == 0) {
            axios
                .get(`${apiUrl}/status`)
                .then((response) => {
                    setStatuses(response.data);
                })
                .catch(() => {
                    toast.error('Er ging iets mis met het ophalen van de statussen');
                });
        }
    }, [apiUrl]);

    // Monitor time elapsed since last filter update
    useEffect(() => {
        let interval = null;

        if (allowFetch === false) {
            interval = setInterval(() => {
                setTime((time) => time + 100);
            }, 100);
        } else {
            clearInterval(interval);
        }
        return () => {
            clearInterval(interval);
        };
    }, [allowFetch]);

    // Allow getting new list of order after 1 second of no filter updates
    useEffect(() => {
        if(time >= 500) {
            setAllowFetch(true);
        }   
    }, [time]);

    // Disallow getting new list of orders after updating filters
    useEffect(() => {
        if(apiUrl !== null) {
            setTime(0);
            setAllowFetch(false);
        }
    }, [filters]);

    return (
        <section className="content-main">
            <div className="content-header">
                <div>
                    <h2 className="content-title card-title">Order List</h2>
                    <p>Lorem ipsum dolor sit amet.</p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-9">
                    <div className="card mb-4">
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Ordernummer</th>
                                            <th>Debiteur</th>
                                            <th>Totaal (excl)</th>
                                            <th>Status</th>
                                            <th>Besteldatum</th>
                                            <th>Leverdatum</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orders?.map((item, index) => (
                                            <tr key={index}>
                                                <td width="10%">{item.id}</td>
                                                <td width="40%">
                                                    <Link
                                                        to={routes.backoffice.order_detail.replace(
                                                            ':order_id',
                                                            item.id,
                                                        )}
                                                        className="itemside"
                                                    >
                                                        <div className="info pl-3">
                                                            <h6 className="mb-0 title">{item?.company?.name}</h6>
                                                            {item?.user ? (
                                                                <small className="text-muted">
                                                                    Gebruiker: {item?.user?.name}
                                                                </small>
                                                            ) : null}
                                                        </div>
                                                    </Link>
                                                </td>
                                                <td>{money(item?.total_gross)}</td>
                                                <td>
                                                    <StatusPill statusHistory={item?.status} />
                                                </td>
                                                <td>{new Date(item?.created_at * 1000).toLocaleDateString('nl-NL')}</td>
                                                <td>{new Date(item?.delivery_date).toLocaleDateString('nl-NL')}</td>
                                                <td className="text-end">
                                                    <Link
                                                        to={routes.backoffice.order_detail.replace(
                                                            ':order_id',
                                                            item.id,
                                                        )}
                                                        className="btn btn-sm btn-brand rounded font-sm mt-15"
                                                    >
                                                        Bekijk
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card mb-4">
                        <div className="card-body">
                            <h5 className="mb-3">Filters</h5>
                            <form>
                                <div className="mb-4">
                                    <label htmlFor="order_id" className="form-label">
                                        Ordernummer
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Type here"
                                        className="form-control"
                                        id="order_id"
                                        value={filters?.orderId ?? ''}
                                        onChange={(e) => setFilters({ ...filters, orderId: e.target.value })}
                                    />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="order_customer" className="form-label">
                                        Debiteur (bedrijf)
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Type here"
                                        className="form-control"
                                        id="order_customer"
                                        value={filters?.debtor ?? ''}
                                        onChange={(e) => setFilters({ ...filters, debtor: e.target.value })}
                                    />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="order_customer_1" className="form-label">
                                        Gebruikers (klant)
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Type here"
                                        className="form-control"
                                        id="order_customer_1"
                                        value={filters?.user ?? ''}
                                        onChange={(e) => setFilters({ ...filters, user: e.target.value })}
                                    />
                                </div>
                                <div className="mb-4">
                                    <label className="form-label">Status</label>
                                    <select
                                        className="form-select"
                                        value={filters?.status ?? -1}
                                        onChange={(e) => setFilters({ ...filters, status: e.target.value })}
                                    >
                                        <option value={-1}>Kies een status</option>
                                        {statuses.map((item, index) => (
                                            <option key={index} value={item.id}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="order_created_date" className="form-label">
                                        Besteldatum
                                    </label>
                                    <input
                                        type="date"
                                        placeholder="Type here"
                                        className="form-control"
                                        id="order_created_date"
                                        value={filters?.orderDate ?? ''}
                                        onChange={(e) => setFilters({ ...filters, orderDate: e.target.value })}
                                    />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="order_modified_date" className="form-label">
                                        Leverdatum
                                    </label>
                                    <input
                                        type="date"
                                        placeholder="Type here"
                                        className="form-control"
                                        id="order_modified_date"
                                        value={filters?.deliveryDate ?? ''}
                                        onChange={(e) => setFilters({ ...filters, deliveryDate: e.target.value })}
                                    />
                                </div>
                                <div className="mb-4">
                                    <div onClick={() => setFilters({})}>Reset filters</div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
