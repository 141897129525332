import axios from 'axios';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import useApiUrl from '../../../framework/hooks/useApiUrlHook';
import useHelpers from '../../../framework/hooks/useHelpers';
import useRouteList from '../../../framework/hooks/useRouteList';

function Column({ title, field }) {
    return <td data-for={field}>{title}</td>;
}

export default function PromotionsOverview() {
    const [results, setResults] = useState(null);
    const [query, setQuery] = useState('');
    const [amount, setAmount] = useState(100);

    const { money } = useHelpers();
    const routes = useRouteList();
    const apiUrl = useApiUrl();
    const navigate = useNavigate();

    useEffect(() => {
        if (apiUrl !== null) {
            axios.get(`${apiUrl}/products/promotions`).then((response) => setResults(response.data));
        }
    }, [apiUrl, query, amount]);
    return (
        <>
            <section className="content-main">
                <div className="content-header">
                    <div>
                        <h2 className="content-title card-title">Huidige aanbiedingen</h2>
                        <p>Voeg een nieuwe aanbieding toe vanuit een product.</p>
                    </div>
                    <div>
                        <Link to={routes.backoffice.products} className="btn btn-primary btn-sm rounded">
                            Naar producten
                        </Link>
                    </div>
                </div>
                <div className="card mb-4">
                    <header className="card-header">
                        <div className="row align-items-center">
                        </div>
                    </header>
                    <div className="card-body">
                        {results?.map((item, i) => {
                            return (
                                <article
                                    className="itemlist"
                                    onClick={() =>
                                        navigate(routes.backoffice.product_edit.replace(':prod_id', item?.product?.id))
                                    }
                                >
                                    <div className="row align-items-center">
                                        <div className="col-lg-1 col-sm-1 col-1 col-check flex-grow-0">
                                            <div className="form-check">{item?.product?.item_number}</div>
                                        </div>
                                        <div className="col-lg-4 col-sm-4 col-8 flex-grow-1 col-name">
                                            <div className="info">
                                                <h6 className="mb-0">{item?.product?.name}</h6>
                                                <div className="sub">{item?.product?.brand?.name}</div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-sm-3 col-3 col-price">
                                            {item?.percentage !== null
                                                ? `${item?.percentage}% korting`
                                                : `${money(item?.price_gross)} per artikel`}{' '}
                                            vanaf {item?.amount ?? 1}{' '}
                                            {(item?.amount ?? 1) > 1 ? 'producten' : 'product'}.
                                        </div>
                                        <div className="col-lg-4 col-sm-4 col-4 col-status">
                                            <span>
                                                Geldig van{' '}
                                                {new Date(item?.start).toLocaleString('nl-NL', { timeZone: 'UTC' })} tot{' '}
                                                {new Date(item?.end).toLocaleString('nl-NL', { timeZone: 'UTC' })}
                                            </span>
                                        </div>
                                    </div>
                                </article>
                            );
                        }) ?? 'Loading...'}
                    </div>
                </div>
            </section>
        </>
    );
}
