import axios from 'axios';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import Invoice from '../../components/ecommerce/Invoice';
import useApiUrl from '../../framework/hooks/useApiUrlHook';

export default function ConfirmationPage() {
    const { orderId, type } = useParams();
    const [order, setOrder] = useState(null);

    const apiUrl = useApiUrl();

    useEffect(() => {
        const getOrder = () => {
            axios.get(`${apiUrl}/order/${orderId}/get`).then((response) => setOrder(response.data));
        };

        if (apiUrl !== null && orderId !== null) getOrder();
    }, [apiUrl, orderId]);

    return (
        <>
            {type == 'success' ? (
                <>
                    <Helmet
                        style={[
                            {
                                cssText: `
                        @media print {
                            .printHidden { display: none; }
                        }
                        `,
                            },
                        ]}
                    />
                    <div className="printHidden page-content pt-50">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-10 col-lg-12 m-auto">
                                    <section className="row align-items-center mb-50">
                                        <div className="row mb-50 align-items-center">
                                            <div className="col-lg-7 pr-30">
                                                <img
                                                    src="/assets/imgs/page/about-5.png"
                                                    alt=""
                                                    className="mb-md-3 mb-lg-0 mb-sm-4"
                                                />
                                            </div>
                                            <div className="col-lg-5">
                                                <h4 className="mb-20 text-muted">Bestelling geslaagd</h4>
                                                <h1 className="heading-1 mb-40">We hebben je bestelling ontvangen!</h1>
                                                <p className="mb-30">
                                                    We gaan zo snel mogelijk aan de slag, hieronder vind je een
                                                    overzicht van de bestelling.
                                                </p>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
            <Invoice order={order} />
        </>
    );
}
