import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import useApiUrl from "../../../framework/hooks/useApiUrlHook";

export default function DeliveryDateSelector({ companyId, deliveryDates, callback }) {
    const [checkedDays, setCheckedDays] = useState([]);

    const apiUrl = useApiUrl();
    
    const days = [
        { id: 1, name: 'Maandag' },
        { id: 2, name: 'Dinsdag' },
        { id: 3, name: 'Woensdag' },
        { id: 4, name: 'Donderdag' },
        { id: 5, name: 'Vrijdag' },
        { id: 6, name: 'Zaterdag' },
        { id: 7, name: 'Zondag' },
    ];

    const handleSaveDays = () => {
        axios.post(`${apiUrl}/company/admin/${companyId}/updateDeliveryDays`, {
            days: checkedDays
        }).then(() => {
            toast('Leverdagen opgeslagen');
            callback();
        })
    }

    const handleCheck = (event) => {
        var updatedList = [...checkedDays];
        if (event.target.checked) {
            updatedList = [...checkedDays, event.target.value];
        } else {
            updatedList.splice(checkedDays.indexOf(event.target.value), 1);
        }
        setCheckedDays(updatedList);
    };

    useEffect(() => {
        setCheckedDays(deliveryDates?.map(i => i.day.toString()));

    }, [deliveryDates]);

    const renderDay = (item) => {
        return (
            <div key={`a${item.id}`} className="category category--depth--0">
                <label className="form-check">
                    <input
                        className="form-check-input"
                        value={item.id}
                        type="checkbox"
                        checked={checkedDays?.includes(item.id + '')}
                        onChange={handleCheck}
                    />
                    <span className="form-check-label">{item.name}</span>
                </label>
            </div>
        );
    };

    return (
        <div className="card mb-4">
            <div className="card-header">
                <h4>Beschikbare leverdagen</h4>
            </div>
            <div className="card-body">
                <div className="row gx-2">
                    <div className="col-12">
                        <div className="mb-4">
                            <div className="category-group">
                                {days.map((item) => renderDay(item))}
                                <button onClick={handleSaveDays} className="btn btn-md rounded font-sm hover-up">
                                    Opslaan
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
