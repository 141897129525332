import axios from 'axios';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import useApiUrl from '../../../framework/hooks/useApiUrlHook';
import useRouteList from '../../../framework/hooks/useRouteList';

export default function CompanyBasedataManager({ company, callback }) {
    const [discountGroups, setDiscountGroups] = useState(null);
    const apiUrl = useApiUrl();
    const navigate = useNavigate();
    const routes = useRouteList()

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        if (company) {
            setValue('debtor_code', company?.debtor_code ?? null);
            setValue('name', company?.name ?? null);
            setValue('email_contact', company?.email_contact ?? null);
            setValue('email_invoice', company?.email_invoice ?? null);
            setValue('discount_group', company?.discount_group_id ?? 0);
        }
    }, [company]);

    useEffect(() => {
        const getDiscountGroups = () => {
            axios.get(`${apiUrl}/discountgroup`).then((response) => setDiscountGroups(response.data));
        };

        if (apiUrl !== null) getDiscountGroups();
    }, [apiUrl]);

    const onSubmit = (data) => {
        if(company?.id) {
            axios.post(`${apiUrl}/company/admin/${company.id}/update`, {
                id: company.id,
                ...data
            }).then(() => {
                toast('Stamgegevens bijgewerkt!');
                callback(data);
            });
        } else {
            axios.post(`${apiUrl}/company/admin/save`, {
                ...data
            }).then((response) => {
                toast('Debiteur toegevoegd!');
                navigate(routes.backoffice.companies_edit.replace(':comp_id', response.data));
            });
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="card mb-4">
                <div className="card-header">
                    <h4>Stamgegevens</h4>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="mb-4">
                                <label className="form-label">Debiteurnummer</label>
                                <div className="row gx-2">
                                    <input
                                        type="text"
                                        placeholder="Debiteurnummer..."
                                        className="form-control"
                                        {...register('debtor_code', {})}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="mb-4">
                                <label className="form-label">Bedrijfsnaam</label>
                                <div className="row gx-2">
                                    <input
                                        type="text"
                                        placeholder="Naam..."
                                        className="form-control"
                                        {...register('name', {})}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6">
                            <div className="mb-4">
                                <label className="form-label">Contact email:</label>
                                <div className="row gx-2">
                                    <input
                                        placeholder="voorbeeld@email.nl"
                                        className="form-control"
                                        {...register('email_contact', {})}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="mb-4">
                                <label className="form-label">Factuur email:</label>
                                <div className="row gx-2">
                                    <input
                                        placeholder="voorbeeld@email.nl"
                                        className="form-control"
                                        {...register('email_invoice', {})}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="mb-4">
                                <label className="form-label">
                                    Kortingsgroep
                                    <select className="form-control" {...register('discount_group', {})}>
                                        <option value="0">Geen kortingsgroep</option>
                                        {discountGroups?.map((item, key) => <option value={item.id} key={`d${key}`}>{item.name}</option>)}
                                    </select>
                                </label>
                            </div>
                        </div>

                        <div className="col-lg-12">
                            <button type="submit" className="btn btn-md rounded font-sm hover-up">
                                Opslaan
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}
