import axios from "axios";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import useApiUrl from "../../../framework/hooks/useApiUrlHook";

export default function EditCategoryForm({ id, category, callback }) {
    const apiUrl = useApiUrl();
    const {
        register,
        handleSubmit,
        setValue,
        control,
        formState: { errors },
    } = useForm();

    const onSubmit = (data) => {
        axios.post(`${apiUrl}/product-categories/admin/${id}/update`, {
            name: data.category_title
        }).then(() => {
            toast(`Categorie '${data.category_title}' bewerkt!`);
            if(callback) callback();
        });
    }
    
    useEffect(() => {
        if(category && category.name) {
            setValue('category_title', category.name);
        }
    }, [category, id]);

    return (
        <div className="card mb-4">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="card-body">
                    <h3>Bewerk categorie</h3>
                    <div className="mb-4">
                        <label className="form-label">Categorietitel</label>
                        <div className="row gx-2">
                            <input
                                type="text"
                                placeholder="Categorietitel..."
                                className="form-control"
                                {...register('category_title', {})}
                            />
                        </div>
                    </div>
                    <button className="btn btn-primary btn-sm rounded mr-4">Opslaan</button>{' '}
                    <div className="btn btn-danger btn-sm rounded">verwijderen</div>
                </div>
            </form>
        </div>
    );
}
