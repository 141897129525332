import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useApiUrl from '../../../framework/hooks/useApiUrlHook';
import useRolesList from '../../../framework/hooks/useRolesList';
import useRouteList from '../../../framework/hooks/useRouteList';

export default function UserOverview() {
    const [users, setUsers] = useState(null);

    const routes = useRouteList();
    const apiUrl = useApiUrl();
    const roles = useRolesList();

    useEffect(() => {
        const getUsers = () => {
            axios.get(`${apiUrl}/user/admin`).then((response) => setUsers(response.data));
        };

        if (apiUrl !== null && users == null) getUsers();
    }, [apiUrl]);

    return (
        <section className="content-main">
            <div className="content-header">
                <h2 className="content-title">Gebruikers</h2>
                <div>
                    <a href="#" className="btn btn-primary">
                        <i className="material-icons md-plus"></i> Create new
                    </a>
                </div>
            </div>
            <div className="card mb-4">
                <header className="card-header">
                    <div className="row gx-3">
                        <div className="col-lg-4 col-md-6 me-auto">
                            <input type="text" placeholder="Search..." className="form-control" />
                        </div>
                    </div>
                </header>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th>Naam en debiteur</th>
                                    <th>Email</th>
                                    <th>Rol</th>
                                    <th>Geregistreerd op</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {users?.map((item, index) => (
                                    <tr key={index}>
                                        <td width="40%">
                                            <Link to={routes.backoffice.users_edit.replace(':usr_id', item.id)} className="itemside">
                                                <div className="info pl-3">
                                                    <h6 className="mb-0 title">{item?.name}</h6>
                                                    <small className="text-muted">
                                                        Debiteur: {item?.company?.name}
                                                    </small>
                                                </div>
                                            </Link>
                                        </td>
                                        <td>{item?.email}</td>
                                        <td>
                                            <span className="badge rounded-pill alert-success">
                                                {Object.keys(roles).find((index) => roles[index] === item.role_id)}
                                            </span>
                                        </td>
                                        <td>{ (new Date(item?.created_at)).toLocaleDateString('nl-NL') }</td>
                                        <td className="text-end">
                                            <Link to={routes.backoffice.users_edit.replace(':usr_id', item.id)} className="btn btn-sm btn-brand rounded font-sm mt-15">
                                                Bekijk
                                            </Link>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
    );
}
