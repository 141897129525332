import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import useApiUrl from '../../framework/hooks/useApiUrlHook';
import useHelpers from '../../framework/hooks/useHelpers';
import useRouteList from '../../framework/hooks/useRouteList';

export default function Breadcrumbs({ cat_id, title = null }) {
    const [breadcrumbs, setBreadCrumbs] = useState(null);
    const [current, setCurrent] = useState(null);

    const apiUrl = useApiUrl();
    const routes = useRouteList();
    const helpers = useHelpers();

    const getCategoryBreadcrumbs = async (id) => {
        axios.get(`${apiUrl}/product-categories/breadcrumbs/${id}`).then((response) => {
            const crumbs = response.data;
            const last = crumbs.pop();
            setBreadCrumbs(crumbs);
            setCurrent(last);
        });
    };

    useEffect(() => {
        if (apiUrl !== null) getCategoryBreadcrumbs(cat_id);
    }, [apiUrl, cat_id]);

    return (
        <>
            <div className="page-header mt-30 mb-50">
                <div className="container">
                    <div className="archive-header">
                        <div className="row align-items-center">
                            <div className="col-xl-12">
                                <h1 className="mb-15 text-capitalize">{title ?? current?.name}</h1>
                                <div className="breadcrumb">
                                    <Link to="/">
                                        <i className="fi-rs-home mr-5"></i>Home
                                    </Link>
                                    {breadcrumbs?.map((item, index) => (
                                        <>
                                            <span></span> <Link to={ routes.products.category.replace(":cat_id", item.id).replace(":cat_name", helpers.urlEncode(item.name)) }>{item.name}</Link>
                                        </>
                                    ))}
                                    <span></span> {current?.name}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
