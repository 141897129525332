import axios from 'axios';
import { useEffect, useState } from 'react';
import useApiUrl from './useApiUrlHook';

export default function useProductDetails(id = null) {
    const [product, setProduct] = useState(null);

    const [brands, setBrands] = useState([]);
    const [taxes, setTaxes] = useState([]);
    const [sizeUnits, setSizeUnits] = useState([]);
    const [packagingTypes, setPackagingTypes] = useState([]);
    const [categories, setCategories] = useState([]);

    const apiUrl = useApiUrl();

    async function getBrands() {
        return await axios.get(`${apiUrl}/brands`).then((response) => {
            setBrands(response.data);
        });
    }

    async function getTaxes() {
        return await axios.get(`${apiUrl}/taxes`).then((response) => {
            setTaxes(response.data);
        });
    }

    async function getSizeUnits() {
        return await axios.get(`${apiUrl}/size-units`).then((response) => {
            setSizeUnits(response.data);
        });
    }

    async function getPackagingTypes() {
        return await axios.get(`${apiUrl}/packaging`).then((response) => {
            setPackagingTypes(response.data);
        });
    }

    async function getCategories() {
        return await axios.get(`${apiUrl}/product-categories/admin`).then((response) => {
            setCategories(response.data);
        });
    }

    async function getProduct() {
        await axios.get(`${apiUrl}/products/admin/${id}`).then((response) => {
            setProduct(response.data);
        });
    }


    function getDependencies() {
        if (apiUrl !== null && brands.length < 1) getBrands();
        if (apiUrl !== null && taxes.length < 1) getTaxes();
        if (apiUrl !== null && sizeUnits.length < 1) getSizeUnits();
        if (apiUrl !== null && packagingTypes.length < 1) getPackagingTypes();
        if (apiUrl !== null && categories.length < 1) getCategories();
    }

    useEffect(() => {
        if (apiUrl !== null && id !== null) getProduct();
    }, [id]);

    useEffect(() => {
        getDependencies();
    }, [apiUrl]);

    return {
        product,
        brands,
        taxes,
        sizeUnits,
        packagingTypes,
        categories,
        getBrands,
        getTaxes,
        getSizeUnits,
        getPackagingTypes,
        getCategories,
        getProduct,
        getDependencies
    };
}
