import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import useApiUrl from '../../framework/hooks/useApiUrlHook';

export default function UserBasedataManager({ user, callback }) {
    const [userRoles, setUserRoles] = useState([]);

    const apiUrl = useApiUrl();

    const {
        register,
        handleSubmit,
        setValue,
        control,
        reset,
        formState: { errors },
    } = useForm();

    const onSubmit = (data) => console.log(data);

    useEffect(() => {
        if(apiUrl !== null && userRoles.length === 0) {
            axios.get(`${apiUrl}/userRoles`)
                .then((response) => setUserRoles(response.data));
        }
    }, [apiUrl]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="card mb-4">
                <div className="card-header">
                    <h4>Stamgegevens</h4>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="mb-4">
                                <label className="form-label">Naam</label>
                                <div className="row gx-2">
                                    <input
                                        type="text"
                                        placeholder="Naam- en achternaam"
                                        className="form-control"
                                        {...register('name', {})}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="mb-4">
                                <label className="form-label">Emailadres:</label>
                                <div className="row gx-2">
                                    <input
                                        placeholder="voorbeeld@email.nl"
                                        className="form-control"
                                        {...register('email', {})}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="mb-4">
                                <label className="form-label">Nieuw wachtwoord:</label>
                                <div className="row gx-2">
                                    <input type="password" className="form-control" {...register('password', {})} />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="mb-4">
                                <label className="form-label">Herhaal nieuw wachtwoord:</label>
                                <div className="row gx-2">
                                    <input
                                        type="password"
                                        className="form-control"
                                        {...register('password_repeat', {})}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="mb-4">
                            <label className="form-label">
                                Rol
                                <select className="form-control" {...register('role', {})}>
                                    {userRoles?.map((item, key) => (
                                        <option value={item.id} key={`d${key}`}>
                                            {item.name}
                                        </option>
                                    ))}
                                </select>
                            </label>
                        </div>
                    </div>

                    <div className="row"></div>

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="mb-4">
                                {/* <label className="form-label">
                                    Kortingsgroep
                                    <select className="form-control" {...register('discount_group', {})}>
                                        <option value="0">Geen kortingsgroep</option>
                                        {discountGroups?.map((item, key) => (
                                            <option value={item.id} key={`d${key}`}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </select>
                                </label> */}
                            </div>
                        </div>

                        <div className="col-lg-12">
                            <button type="submit" className="btn btn-md rounded font-sm hover-up">
                                Opslaan
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}
