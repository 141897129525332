import { Link } from 'react-router-dom';
import useHelpers from '../../../framework/hooks/useHelpers';
import useRouteList from '../../../framework/hooks/useRouteList';

export default function CategoryProduct({ categories }) {
    const routes = useRouteList();
    const helpers = useHelpers();

    return (
        <>
            <ul>
                <li>
                    <a>Alle</a>
                </li>
                {categories?.map((item, key) => {
                    if (item.total < 1) return null;

                    return (
                        <li key={key}>
                            <Link
                                to={routes.products.category
                                    .replace(':cat_id', item.id)
                                    .replace(':cat_name', helpers.urlEncode(item.name))}
                            >
                                {item.name}
                            </Link>
                            {item.total ? <span className="count">{item.total}</span> : null}
                        </li>
                    );
                })}
            </ul>
        </>
    );
}
