import { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import useAuth from '../../../framework/hooks/useAuth';
import useRouteList from '../../../framework/hooks/useRouteList';

export default function AccountHomeTab() {
    const [greeting, setGreeting] = useState('Hallo');

    const routes = useRouteList();

    useEffect(() => {
        const hour = new Date().getHours();

        if (hour > 3 && hour < 12) {
            setGreeting('Goedemorgen');
        } else if (hour < 18) {
            setGreeting('Goedemiddag');
        } else {
            setGreeting('Goedenavond');
        }
    }, []);

    const auth = useAuth();

    return (
        <div className="card">
            <div className="card-header">
                <h3 className="mb-0">
                    {greeting} {auth?.user?.name}!
                </h3>
            </div>
            <div className="card-body">
                <p>
                    Vanuit uw accountdashboard kunt u eenvoudig uw <Link to={routes.account.bestellingen}>recente bestellingen</Link> controleren en bekijken,
                    <br />
                    beheer uw <Link to={routes.account.adressen}>verzend- en factuuradres</Link> en{' '}
                    <Link to={routes.account.gegevens}>accountgegevens.</Link>
                </p>
            </div>
        </div>
    );
}
