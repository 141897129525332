import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import useAuth from '../../framework/hooks/useAuth';
import useCart from '../../framework/hooks/useCart';
import useHelpers from '../../framework/hooks/useHelpers';
import useRouteList from '../../framework/hooks/useRouteList';

import './scss/cartPage.scss';

function CartRow({ item }) {
    const { modifyQuantity, deleteFromCart } = useCart();
    const { urlEncode, money, localImage, getMissingDiscount, getProductCurrentPrice, getProductOldPrice } =
        useHelpers();
    const routes = useRouteList();

    const [localQuantity, setLocalQuantity] = useState(item?.amount);

    useEffect(() => {
        if (localQuantity !== item?.amount) {
            setLocalQuantity(item?.amount);
        }
    }, [item]);

    const handleAmountChange = (value) => {
        let int = parseInt(value);
        if (isNaN(int)) {
            int = 1;
        }
        setLocalQuantity(value);
    };

    const saveAmountChange = () => {
        modifyQuantity(item.product.id, localQuantity);
    };
    
    return (
        <>
            <tr>
                {item?.product?.images === undefined || item?.product?.images.length < 1 ? (
                    <td className="image product-thumbnail" />
                ) : (
                    <td className="image product-thumbnail">
                        <img
                            src={localImage(item?.product?.images[0].path, {
                                width: 150,
                                height: 150,
                            })}
                        />
                    </td>
                )}
                <td className="product-des product-name">
                    <div className="product-category">
                        <Link to="/products">{item?.product?.brand?.name}</Link>
                    </div>
                    <h6 className="product-name">
                        <Link
                            to={routes.products.detail
                                .replace(':prod_id', item?.product?.id)
                                .replace(':prod_name', urlEncode(item?.product?.name))}
                        >
                            {item?.product?.name}
                        </Link>
                    </h6>
                    <div>
                        <span className="font-small text-muted">
                            {item?.product?.amount > 1 ? item?.product?.amount : null}{' '}
                            {item?.product?.packaging_type?.name} {item?.product?.size} {item?.product?.size_unit?.unit}
                        </span>
                    </div>
                </td>
                <td className="price" data-title="Price">
                    <h4 className="text-brand">
                        {money(getProductCurrentPrice(item?.product, item?.amount))}
                        {getProductOldPrice(item?.product) !== null ? (
                            <span className="old-price">{money(getProductOldPrice(item?.product))}</span>
                        ) : null}
                    </h4>
                </td>
                <td className="text-center detail-info" data-title="Stock">
                    <div className="detail-extralink mr-15">
                        <div className="detail-qty border radius ">
                            <div onClick={(e) => modifyQuantity(item?.product?.id, '-1')} className="qty-down">
                                <i className="fi-rs-angle-small-down"></i>
                            </div>
                            <input
                                type="text"
                                className="qty-val"
                                value={localQuantity}
                                onChange={(e) => handleAmountChange(e.target.value)}
                                onBlur={saveAmountChange}
                            />
                            <div onClick={(e) => modifyQuantity(item?.product?.id, '+1')} className="qty-up">
                                <i className="fi-rs-angle-small-up"></i>
                            </div>
                        </div>
                    </div>
                </td>
                <td className="text-right price" data-title="Cart">
                    <h4 className="text-body">
                        {money(item?.amount * getProductCurrentPrice(item?.product, item?.amount))}
                        {item?.product?.discount_price_gross && item?.product?.discount_price_gross !== null ? (
                            <span className="old-price">{money(item?.amount * getProductOldPrice(item?.product))}</span>
                        ) : null}
                    </h4>
                </td>
                <td className="action" data-title="Remove">
                    <div onClick={(e) => deleteFromCart(item.id)} className="text-muted">
                        <i className="fi-rs-trash"></i>
                    </div>
                </td>
            </tr>
            {getMissingDiscount(item?.product, item?.amount) === false ? null : (
                <tr className="missingDiscount">
                    <td></td>
                    <td></td>
                    <td className='content' colSpan={3}>{getMissingDiscount(item?.product, item?.amount)}</td>
                    <td></td>
                </tr>
            )}
        </>
    );
}

export default function CartPage() {
    const dispatch = useDispatch();
    const { cart } = useSelector((state) => state);

    const { money } = useHelpers();
    const { user } = useAuth();
    const routes = useRouteList();

    const { clearCart } = useCart();

    return (
        <section className="mt-50 mb-50">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 mb-40">
                        <h1 className="heading-2 mb-10">
                            Winkelwagen van {user?.company?.name ?? user?.name ?? '...'}
                        </h1>
                        <div className="d-flex justify-content-between">
                            <h6 className="text-body">
                                Je hebt <span className="text-brand">{cart?.items?.length}</span>{' '}
                                {cart?.items?.length > 1 ? 'producten' : 'product'} in je winkelwagen
                            </h6>
                            <h6 className="text-body">
                                <div onClick={clearCart} className="text-muted">
                                    <i className="fi-rs-trash mr-5"></i>
                                    Winkelwagen legen
                                </div>
                            </h6>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-8">
                        <div className="table-responsive shopping-summery">
                            {cart?.items?.length <= 0 && 'Je winkelwagen is leeg'}
                            <table className={cart?.items?.length > 0 ? 'table table-wishlist' : 'd-none'}>
                                <thead>
                                    <tr className="main-heading">
                                        <th className="custome-checkbox start pl-30" colSpan={2}>
                                            Product
                                        </th>
                                        <th scope="col">Stuksprijs</th>
                                        <th scope="col">Aantal</th>
                                        <th scope="col">Subtotaal</th>
                                        <th scope="col" className="end">
                                            Verwijderen
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {cart?.items?.map((item, i) => (
                                        <CartRow item={item} key={i} />
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="cart-action" style={{display: "flex", justifyContent: "space-between"}}>
                            <Link className="btn btn-ghost" to={routes.home}>
                                <i className="fi-rs-shopping-bag mr-10"></i>
                                Ga verder met winkelen
                            </Link>
                            <Link className="btn" to={routes.order.checkout}>
                                <i className="fi-rs-box-alt mr-10"></i>
                                Naar de kassa
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <div className="border p-md-4 p-30 border-radius cart-totals">
                            <div className="heading_s1 mb-3">
                                <h4>Winkelwagen totaal</h4>
                            </div>
                            <div className="table-responsive">
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <td className="cart_total_label">Totaal excl. BTW</td>
                                            <td className="cart_total_amount">
                                                <span className="font-lg fw-900 text-brand">
                                                    {money(cart?.total_gross ?? 0)}
                                                </span>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="cart_total_label">Totaal incl. BTW</td>
                                            <td className="cart_total_amount">
                                                <strong>
                                                    <span className="font-xl fw-900 text-brand">
                                                        {money(cart?.total_net ?? 0)}
                                                    </span>
                                                </strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <Link to={routes.order.checkout} className="btn ">
                                <i className="fi-rs-box-alt mr-10"></i>
                                Naar de kassa
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
