import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import useApiUrl from '../../../framework/hooks/useApiUrlHook';

export default function AssignProductSelector({
    title = '',
    productAlternatives = null,
    changeCallback = (alternatives) => {},
    newProduct = true,
    text = '',
}) {
    const [query, setQuery] = useState('');
    const [alternatives, setAlternatives] = useState(null);
    const [results, setResults] = useState([]);

    const apiUrl = useApiUrl();

    useEffect(() => {
        if (query !== null) {
            if (query === '') {
                setResults([]);
            } else {
                axios
                    .post(`${apiUrl}/products/admin/search`, {
                        query,
                        exclude: alternatives?.map((alt) => alt.id),
                    })
                    .then((response) => setResults(response.data));
            }
        }
    }, [query, alternatives]);

    useEffect(() => changeCallback(alternatives), [alternatives]);

    useEffect(() => {
        if (alternatives === null && !newProduct) {
            setAlternatives(productAlternatives);
        }
    }, [productAlternatives]);

    const handleAddAlternative = (prod) => {
        setAlternatives([...alternatives, prod]);
    };

    const handleRemoveAlternative = (prod) => {
        var updatedList = [...alternatives];

        const index = updatedList.findIndex((item) => item.id === prod);

        if (index > -1) {
            updatedList.splice(index, 1);
            setAlternatives(updatedList);
        }
    };

    return (
        <div className="listmember card mb-4">
            <div className="card-header">
                <h4>{title}</h4>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-lg-6">
                        <label className="form-label">Zoek artikel</label>
                        <div className="row gx-2">
                            <input
                                type="text"
                                placeholder="Artikel..."
                                className="form-control"
                                onChange={(e) => setQuery(e.target.value)}
                                value={query}
                            />
                        </div>
                    </div>
                    <div className="col-lg-6"><div className="titleCol">{title}:</div></div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="mb-4">
                            <div className="results">
                                {results?.map((curProduct, index) => {
                                    return (
                                        <div className="resultRow" key={index}>
                                            <div className="code">{curProduct.item_number}</div>
                                            <div className="title">
                                                {curProduct.name}, {curProduct.brand}
                                            </div>
                                            <div className="action" onClick={() => handleAddAlternative(curProduct)}>
                                                +
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="mb-4">
                            <div className="results">
                                {alternatives?.map((curProduct, index) => {
                                    return (
                                        <div className="resultRow" key={index}>
                                            <div className="code">{curProduct.item_number}</div>
                                            <div className="title">
                                                {curProduct.name}, {curProduct.brand}
                                            </div>
                                            <div
                                                className="action"
                                                onClick={() => handleRemoveAlternative(curProduct.id)}
                                            >
                                                X
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <small>{text}</small>
                    </div>
                </div>
            </div>
        </div>
    );
}
