export default function useRouteList() {
    return {
        home: "/",
        login: "/account/login",
        register: "/account/register",
        favourites: "/favorieten",
        search: "/assortiment/zoeken/:term",
        
        order: {
            cart: "/bestellen/winkelwagen",
            checkout: "/bestellen/gegevens",
            payment: "/bestellen/betaling/:transactionId",
            complete: "/bestellen/bedankt/:orderId",
        },

        account: {
            dashboard: "/account/dashboard",
            bestellingen: "/account/dashboard/bestellingen",
            bestelling_detail: "/account/dashboard/bestellingen/:order_id",
            adressen: "/account/dashboard/adressen",
            gegevens: "/account/dashboard/gegevens",
            password_reset_request: "/account/passwordreset",
        },

        products: {
            detail: "/assortiment/:prod_id/:prod_name",
            category: "/assortiment/categorie/:cat_id/:cat_name",
            category_product: "/assortiment/categorie/:cat_id/:cat_name/product/:cat_id/:cat_name",
            promotions: "/assortiment/aanbiedingen",
        },

        backoffice: {
            dashboard: "/backoffice",
            products: "/backoffice/products",
            products_create: "/backoffice/products/create",
            product_view: "/backoffice/products/:prod_id",
            product_edit: "/backoffice/products/:prod_id/edit",

            product_categories: "/backoffice/product-categories",
            product_categories_edit: "/backoffice/product-categories/:cat_id",

            companies: "/backoffice/companies",
            companies_edit: "/backoffice/companies/:comp_id",
            companies_create: "/backoffice/companies/create",

            users: "/backoffice/users",
            users_edit: "/backoffice/users/:usr_id",

            promotions: "/backoffice/promotions",
            promotions_edit: "/backoffice/promotions/:comp_id",
            promotions_create: "/backoffice/promotions/create",

            orders: "/backoffice/orders",
            order_detail: "/backoffice/orders/:order_id",
        }
    }
}