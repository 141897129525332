import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import useApiUrl from '../../../framework/hooks/useApiUrlHook';

export default function AddressManager({ title, addresses, companyId, callback }) {
    const [selectedAddress, setSelectedAddress] = useState('null');
    const [address, setAddress] = useState(null);
    const apiUrl = useApiUrl();

    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        if (selectedAddress == 'null' || selectedAddress == '-1') {
            reset();
        } else {
            const findAddress = addresses.find((item) => item.id == selectedAddress);
            
            if (!findAddress) return;
            
            setAddress(findAddress);

            setValue('name', findAddress?.name);
            setValue('telephone', findAddress?.telephone);
            setValue('street_address', findAddress?.street_address);
            setValue('postal_code', findAddress?.postal_code);
            setValue('city', findAddress?.city);
            setValue('administrative_area', findAddress?.administrative_area);
            setValue('country', findAddress?.country);
        }
    }, [selectedAddress]);

    const onSubmit = (data) => {
        axios
            .post(`${apiUrl}/address/${selectedAddress === '-1' ? companyId : selectedAddress}/${selectedAddress === '-1' ? 'add' : 'update'}`, {
                type: 'delivery',
                ...address,
                ...data,
            })
            .then((response) => {
                if(selectedAddress === '-1') {
                    toast('Adres opgeslagen!');
                    setSelectedAddress(response.data);
                } else {
                    toast('Adres bijgewerkt!');
                }
                callback(data);
            });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="card mb-4">
                <div className="card-header">
                    <h4>{title ?? 'Adressen'}</h4>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="mb-4">
                                <label className="form-label">
                                    Selecteer adres
                                    <select
                                        className="form-control"
                                        value={selectedAddress}
                                        onChange={(e) => setSelectedAddress(e.target.value)}
                                    >
                                        <option value="null" disabled>
                                            Kies een adres om te bewerken
                                        </option>
                                        {addresses?.map((item, key) => (
                                            <option key={key} value={item.id}>
                                                {item.name}: {item.street_address}, {item.postal_code} {item.city}
                                            </option>
                                        ))}
                                        <option value="-1">Nieuw adres</option>
                                    </select>
                                </label>
                            </div>
                            <hr />
                        </div>
                        <div
                            className="addressGroup"
                            style={{
                                opacity: selectedAddress != 'null' ? 1 : 0.3,
                                pointerEvents: selectedAddress != 'null' ? 'all' : 'none',
                            }}
                        >
                            <div className="col-lg-12">
                                <div className="mb-4">
                                    <label className="form-label">
                                        Adres naam
                                        <input
                                            placeholder="Factuuradres"
                                            className="form-control"
                                            {...register('name', {})}
                                        />
                                    </label>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="mb-4">
                                    <label className="form-label">
                                        Telefoonnummer
                                        <input
                                            placeholder="Overijssel"
                                            className="form-control"
                                            {...register('telephone', {})}
                                        />
                                    </label>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="mb-4">
                                    <label className="form-label">
                                        Straat en huisnummer
                                        <input
                                            placeholder="Schoolstraat 1"
                                            className="form-control"
                                            {...register('street_address', {})}
                                        />
                                    </label>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="mb-4">
                                    <label className="form-label">
                                        Postcode
                                        <input
                                            placeholder="1234 AB"
                                            className="form-control"
                                            {...register('postal_code', {})}
                                        />
                                    </label>
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="mb-4">
                                    <label className="form-label">
                                        Plaats
                                        <input
                                            placeholder="Albasserdam"
                                            className="form-control"
                                            {...register('city', {})}
                                        />
                                    </label>
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="mb-4">
                                    <label className="form-label">
                                        Provincie
                                        <input
                                            placeholder="Overijssel"
                                            className="form-control"
                                            {...register('administrative_area', {})}
                                        />
                                    </label>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="mb-4">
                                    <label className="form-label">
                                        Land
                                        <select className="form-control" {...register('country', {})}>
                                            <option value="NL">NL</option>
                                            <option value="DE">DE</option>
                                            <option value="BE">BE</option>
                                        </select>
                                    </label>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <button type="submit" className="btn btn-md rounded font-sm hover-up">
                                    Opslaan
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}
