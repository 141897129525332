import { useEffect } from 'react';
import { useState } from 'react';
import SwiperCore, { Navigation, Thumbs } from 'swiper';
// import "swiper/css/thumbs";
import { Swiper, SwiperSlide } from 'swiper/react';
import useHelpers from '../../framework/hooks/useHelpers';

SwiperCore.use([Navigation, Thumbs]);

const ThumbSlider = ({ product }) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    const { productImage, localImage } = useHelpers();

    return (
        <div>
            <Swiper
                style={{
                    '--swiper-navigation-color': '#fff',
                    '--swiper-pagination-color': '#fff',
                }}
                spaceBetween={10}
                navigation={true}
                thumbs={{ swiper: thumbsSwiper }}
                className="mySwiper2"
            >
                {product?.images?.length > 0 ? (
                    product?.images?.map((item) => {
                        return (
                            <SwiperSlide>
                                <img src={localImage(productImage([item], 0), { width: 550, height: 550 })} />
                            </SwiperSlide>
                        );
                    })
                ) : (
                    <SwiperSlide>
                        <img src={localImage('')} />
                    </SwiperSlide>
                )}
            </Swiper>
            <Swiper
                onSwiper={setThumbsSwiper}
                spaceBetween={10}
                slidesPerView={4}
                freeMode={true}
                watchSlidesProgress={true}
                className="mySwiper"
            >
                {product?.images?.map((item, index) => (
                    <SwiperSlide>
                        <img src={localImage(productImage([item], 0), { width: 125, height: 125 })} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default ThumbSlider;
