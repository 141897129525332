import axios from 'axios';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import useApiUrl from '../../../framework/hooks/useApiUrlHook';
import useHelpers from '../../../framework/hooks/useHelpers';

import './scss/assignProductPromotion.scss';

function PromotionRow({ promotion }) {}

export default function AssignProductPromotion({ product, callback = () => {} }) {
    const apiUrl = useApiUrl();
    const { money } = useHelpers();

    const [discountUnit, setDiscountUnit] = useState(
        product !== undefined && product?.promotions !== undefined
            ? product?.price_gross !== null
                ? 'price_gross'
                : 'percentage'
            : 'percentage',
    );

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    const handleDelete = (id) => {
        axios.delete(`${apiUrl}/products/promotions/admin/${id}`)
            .then(() => {
                callback();
                toast.success('Aanbieding is verwijderd!');
            })
            .catch((error) => {
                toast.error(`Kon de aanbieding niet verwijderen: ${error?.response?.data}`);
            })
    }

    const submit = (data) => {
        const obj = {
            start: data.start,
            end: data.end,
            product_id: product.id,
            amount: data.amount,
        };

        if (discountUnit === 'percentage') {
            obj.percentage = data.percentage;
        } else {
            obj.price_gross = data.price_gross;
        }

        axios
            .post(`${apiUrl}/products/promotions/admin/create`, obj)
            .then(() => {
                callback();
                reset();
                toast("Aanbieding toegevoegd.");
            })
            .catch((error) => {
                toast.warn(`Aanbieding niet toegevoegd: ${error?.response?.data}`);
            });
    };

    return (
        <div className="card mb-4 promotionsCard">
            <div className="card-header">
                <h4>Aanbiedingen</h4>
            </div>
            <div className="card-body">
                <form onSubmit={handleSubmit(submit)}>
                    <div className="row">
                        <div className="col-lg-3">
                            <div>
                                <label className="form-check">
                                    <input
                                        onChange={(event) => setDiscountUnit(event.target.value)}
                                        checked={discountUnit === 'percentage'}
                                        type="radio"
                                        value="percentage"
                                        className="form-check-input"
                                    />
                                    Kortingspercentage
                                </label>
                                <label className="form-check">
                                    <input
                                        onChange={(event) => setDiscountUnit(event.target.value)}
                                        checked={discountUnit === 'price_gross'}
                                        type="radio"
                                        value="price_gross"
                                        className="form-check-input"
                                    />
                                    Bruto prijs
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3">
                            {discountUnit === 'percentage' ? (
                                <div className="mb-4">
                                    <label className="form-label">Kortingspercentage</label>
                                    <div className="row gx-2">
                                        <input
                                            placeholder="0%"
                                            {...register('percentage', {})}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className="mb-4">
                                    <label className="form-label">Actieprijs bruto</label>
                                    <div className="row gx-2">
                                        <input
                                            placeholder="0.00"
                                            {...register('price_gross', {})}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="col-lg-3">
                            <div className="mb-4">
                                <label className="form-label">Actie vanaf aantal producten</label>
                                <div className="row gx-2">
                                    <input placeholder="" {...register('amount', {})} className="form-control" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="mb-4">
                                <label className="form-label">Geldig van:</label>
                                <div className="row gx-2">
                                    <input
                                        type="datetime-local"
                                        placeholder=""
                                        {...register('start', {})}
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="mb-4">
                                <label className="form-label">Geldig tot:</label>
                                <div className="row gx-2">
                                    <input
                                        type="datetime-local"
                                        placeholder=""
                                        {...register('end', {})}
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <button type="submit" className="btn btn-md rounded font-sm hover-up">
                                Aanbieding toevoegen en opslaan
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <hr />
            <div className="card-body">
                {product?.promotions?.map((item, index) => {
                    const start = new Date(item.start);
                    const end = new Date(item.end);
                    const now = new Date();
                    const active = (now > start && now < end);
                    return (
                        <div className={`promotionRow ${active ? 'active' : ''}`} key={index}>
                            <div className="description">
                                {item.percentage !== null
                                    ? `${item.percentage}% korting`
                                    : `${money(item.price_gross)} per artikel`}{' '}
                                vanaf {item.amount ?? 1} {(item.amount ?? 1) > 1 ? 'producten' : 'product'}. 
                            </div>
                            <div className="duration">
                                Geldig van{' '}
                                {start.toLocaleString('nl-NL', { timeZone: 'UTC' })} tot{' '}
                                {end.toLocaleString('nl-NL', { timeZone: 'UTC' })}
                            </div>
                            <div className="actions" onClick={() => handleDelete(item.id)}>
                                verwijder
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
