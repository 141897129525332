import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import CategoryProduct from '../../components/ecommerce/Filter/CategoryProduct';
import PriceRangeSlider from '../../components/ecommerce/Filter/PriceRangeSlider';
import ShowSelect from '../../components/ecommerce/Filter/ShowSelect';
import SizeFilter from '../../components/ecommerce/Filter/SizeFilter';
import SortSelect from '../../components/ecommerce/Filter/SortSelect';
import VendorFilter from '../../components/ecommerce/Filter/VendorFilter';
import Pagination from '../../components/ecommerce/Pagination';
import SingleProduct from '../../components/ecommerce/SingleProduct';
import Breadcrumbs from '../../components/layout/Breadcrumbs';
import useApiUrl from '../../framework/hooks/useApiUrlHook';

import './scss/products.scss';

export default function CategoryPage() {
    const [category, setCategory] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedVendors, setVendors] = useState([]);

    const { cat_id, query } = useParams();
    const [customTitle, setCustomTitle] = useState(null);

    const apiUrl = useApiUrl();

    const getCategory = async () => {
        const filters = {
            brands: selectedVendors
        }

        if (cat_id) {
            axios.post(`${apiUrl}/product-categories/products/${cat_id}/40/${currentPage}`, {filters}).then((response) => {
                setCategory(response.data);
            });
        } else if (query) {
            axios.post(`${apiUrl}/product-categories/search/${query}/40/${currentPage}`, {filters}).then((response) => {
                setCategory(response.data);
                setCustomTitle(`Zoekresultaten voor '${query}'`);
            });
        }
    };

    useEffect(() => {
        if (
            (category === null && apiUrl !== null && cat_id) ||
            (category !== null && cat_id !== category.id) ||
            (category === null && apiUrl !== null && query) ||
            (category !== null && query !== category.query)
        )
            getCategory();
    }, [apiUrl, cat_id, selectedVendors]);

    const handlePaginate = (pageNo) => {
        let gotoPage = pageNo;

        if (pageNo === '+1') gotoPage = currentPage + 1;
        if (pageNo === '-1') gotoPage = currentPage - 1;

        setCurrentPage(parseInt(gotoPage, 10));

        getCategory();
    };

    return (
        <>
            <Breadcrumbs cat_id={cat_id} title={customTitle} />
            <section className="mt-50 mb-50">
                <div className="container">
                    <div className="row flex-row-reverse">
                        <div className="col-lg-4-5">
                            <div className="shop-product-fillter">
                                <div className="totall-product">
                                    <p>
                                        <strong className="text-brand">{category?.total}</strong>
                                        resultaten
                                    </p>
                                </div>
                                <div className="sort-by-product-area">
                                    <div className="sort-by-cover mr-10">
                                        <ShowSelect />
                                    </div>
                                    <div className="sort-by-cover">
                                        <SortSelect />
                                    </div>
                                </div>
                            </div>
                            <div className="row product-grid-3">
                                {category?.length === 0 && <h3>No Products Found </h3>}

                                {category?.products?.map((item, i) => {
                                        return (
                                            <div className="col-lg-3" key={i}>
                                                <SingleProduct
                                                    product={item}
                                                    />
                                            </div>
                                        );
                                })}
                            </div>

                            <div className="pagination-area mt-15 mb-sm-5 mb-lg-0">
                                <nav aria-label="Page navigation example">
                                    <Pagination
                                        totalPages={Math.ceil(category?.total / 40)}
                                        currentClientPage={currentPage}
                                        callback={handlePaginate}
                                    />
                                </nav>
                            </div>
                        </div>

                        <div className="col-lg-1-5 primary-sidebar sticky-sidebar">
                            {category?.children == undefined ? null : (
                                <div className="sidebar-widget widget-category-2 mb-30">
                                    <h5 className="section-title style-1 mb-30">{category?.name}</h5>
                                    <CategoryProduct categories={category?.children} />
                                </div>
                            )}

                            <div className="sidebar-widget price_range range mb-30">
                                <h5 className="section-title style-1 mb-30">Verfijn resulaten</h5>
                                <div className="list-group">
                                    <div className="list-group-item mb-10 mt-10">
                                        <label className="fw-900">Merk</label>
                                        <VendorFilter updateVendors={setVendors} activeFilters={selectedVendors} brands={category?.brands} />
                                    </div>
                                </div>
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
