import { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import useCart from '../../framework/hooks/useCart';
import useHelpers from '../../framework/hooks/useHelpers';
import useRouteList from '../../framework/hooks/useRouteList';

export default function SingleProduct({ product }) {
    const [isFavourite, setIsFavourite] = useState(false);

    const helpers = useHelpers();
    const routes = useRouteList();
    const { addToCart, toggleWishlist } = useCart();

    const handleCart = (product) => {
        addToCart(product.id);
    };

    const handleWishlist = async (product) => {
        const res = await toggleWishlist(product.id);
        setIsFavourite(res);
    };

    useEffect(() => {
        if (product) {
            setIsFavourite(product?.isFavourite ? true : false);
        }
    }, [product]);

    return (
        <>
            <div className="product-cart-wrap">
                <div className="product-img-action-wrap">
                    <div className="product-img">
                        <Link
                            to={routes.products.detail
                                .replace(':prod_id', product?.id)
                                .replace(':prod_name', helpers.urlEncode(product?.name))}
                        >
                            <img
                                className="default-img"
                                src={helpers.localImage(helpers.productImage(product?.images, 0), {
                                    width: 250,
                                    height: 250,
                                })}
                                alt=""
                            />
                        </Link>
                    </div>

                    <div className="product-badges product-badges-position product-badges-mrg">
                        {product?.trending && <span className="hot">Hot</span>}
                        {product?.created && <span className="new">New</span>}
                        {product?.totalSell > 100 && <span className="best">Best Sell</span>}
                        {helpers.getProductDiscountString(product) !== null && (
                            <span className="sale">Aanbieding!</span>
                        )}
                        {product?.discount?.percentage >= 5 && (
                            <span className="hot">{product?.discount?.percentage}%</span>
                        )}
                    </div>
                </div>
                <div className="product-content-wrap">
                    <div className="product-category">
                        <Link to="/products">{product?.brand?.name}</Link>
                    </div>
                    <h2>
                        <Link
                            to={routes.products.detail
                                .replace(':prod_id', product.id)
                                .replace(':prod_name', helpers.urlEncode(product.name))}
                        >
                            {product?.name}
                        </Link>
                    </h2>

                    <div>
                        <span className="font-small text-muted">
                            {product?.amount > 1 ? product?.amount : null} {product?.packaging_type?.name}{' '}
                            {product?.size} {product?.size_unit?.unit}
                        </span>
                    </div>

                    {product.price_gross ? (
                        <>
                            <div className="font-small text-muted sale">
                                {helpers.getProductDiscountString(product)}
                            </div>
                            <div className="product-card-bottom">
                                <div className="product-price">
                                    <span>{helpers.money(helpers.getProductCurrentPrice(product))}</span>
                                    {helpers.getProductOldPrice(product) !== null ? (
                                        <span className="old-price">
                                            {helpers.money(helpers.getProductOldPrice(product))}
                                        </span>
                                    ) : null}
                                </div>
                                <div className="add-cart">
                                    <div
                                        className={`love ${isFavourite ? 'active' : ''}`}
                                        onClick={() => handleWishlist(product)}
                                    >
                                        <i className="fi-rs-heart"></i>
                                    </div>
                                    <div className="add" onClick={() => handleCart(product)}>
                                        <i className="fi-rs-shopping-cart mr-5"></i> toevoegen
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <Link to={routes.login}>Log in om prijzen te zien</Link>
                    )}
                </div>
            </div>
        </>
    );
}
