import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import useApiUrl from "./useApiUrlHook";

export default function usePayments(id = null) {
    const [paymentMethods, setPaymentMethods] = useState([]);

    const apiUrl = useApiUrl();

    async function getMethods() {
        if(paymentMethods.length) return paymentMethods;

        return await axios.get(`${apiUrl}/payment/methods`).then((response) => {
            const array = Object.values(response.data);

            setPaymentMethods(array);
            return array;
        });
    }

    useEffect(() => {
        if(apiUrl !== null) {
            getMethods();
        }
    }, [apiUrl]);

    return {
        paymentMethods
    };
}
