import { useEffect } from "react";
import { useState } from "react";

export default function StatusPill({ statusHistory = null }) {
    const [activeStatus, setActiveStatus] = useState(null);

    useEffect(() => {
        if(statusHistory !== null) {
            const draftStatus = statusHistory[statusHistory.length - 1];
            setActiveStatus(draftStatus ?? null);
        }
    }, [statusHistory]);

    if(!activeStatus) return null;

    return (
        <span className={`badge rounded-pill ${activeStatus.className}`}>
            {activeStatus.name}
        </span>
    );
}
