import { ActionTypes } from "../constants/action-types"

const initialState = { list: [], type: null };

const productReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ActionTypes.SET_PRODUCTS:
            return {...state, ...payload};
        case ActionTypes.SELECTED_PRODUCT:
            return {state};
        default:
            return state;
    }
}

export default productReducer;