import { Link } from 'react-router-dom';
import '../../../sass/theme_backend/assets/sass/main.scss';
import useRouteList from '../../framework/hooks/useRouteList';

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

export default function HeaderBackend() {
    const routes = useRouteList();

    const handleSubMenu = (e) => {
        const target = e.target;
        const menuItem = target.closest('.has-submenu');
        if (!menuItem.classList.contains('open')) {
            menuItem.classList.add('open');
        } else {
            menuItem.classList.remove('open');
        }
    };

    return (
        <>
            <aside className="navbar-aside" id="offcanvas_aside">
                <div className="aside-top">
                    <a href="index.html" className="brand-wrap">
                        <img src="/assets/imgs/theme/logo.svg" className="logo" alt="Nest Dashboard" />
                    </a>
                </div>
                <nav>
                    <ul className="menu-aside">
                        <li className="menu-item active">
                            <Link className="menu-link" to={routes.backoffice.dashboard}>
                                <i className="icon material-icons md-home"></i>
                                <span className="text">Dashboard</span>
                            </Link>
                        </li>
                        <li className="menu-item has-submenu">
                            <div className="menu-link" onClick={handleSubMenu}>
                                <i className="icon material-icons md-shopping_bag"></i>
                                <span className="text">Producten</span>
                            </div>
                            <div className="submenu">
                                <Link to={routes.backoffice.products}>Producten</Link>
                                <Link to={routes.backoffice.product_categories}>Categorieën</Link>
                            </div>
                        </li>
                        <li className="menu-item has-submenu">
                            <div className="menu-link" onClick={handleSubMenu}>
                                <i className="icon material-icons md-person"></i>
                                <span className="text">Debiteuren</span>
                            </div>
                            <div className="submenu">
                                <Link to={routes.backoffice.companies}>Bedrijven</Link>
                                <Link to={routes.backoffice.users}>Gebruikers</Link>
                            </div>
                        </li>
                        <li className="menu-item">
                            <Link className="menu-link" to={routes.backoffice.promotions}>
                                <i className="icon material-icons md-stars"></i> <span className="text">Aanbiedingen</span>
                            </Link>
                        </li>
                        <li className="menu-item">
                            <Link className="menu-link" to={routes.backoffice.orders}>
                                <i className="icon material-icons md-shopping_cart"></i> <span className="text">Bestellingen</span>
                            </Link>
                        </li>
                        {/**
                        <li className="menu-item has-submenu">
                            <a className="menu-link" href="page-sellers-cards.html">
                                <i className="icon material-icons md-store"></i>
                                <span className="text">Sellers</span>
                            </a>
                            <div className="submenu">
                                <a href="page-sellers-cards.html">Sellers cards</a>
                                <a href="page-sellers-list.html">Sellers list</a>
                                <a href="page-seller-detail.html">Seller profile</a>
                            </div>
                        </li>
                        <li className="menu-item has-submenu">
                            <a className="menu-link" href="page-form-product-1.html">
                                <i className="icon material-icons md-add_box"></i>
                                <span className="text">Add product</span>
                            </a>
                            <div className="submenu">
                                <a href="page-form-product-1.html">Add product 1</a>
                                <a href="page-form-product-2.html">Add product 2</a>
                                <a href="page-form-product-3.html">Add product 3</a>
                                <a href="page-form-product-4.html">Add product 4</a>
                            </div>
                        </li>
                        <li className="menu-item has-submenu">
                            <a className="menu-link" href="page-transactions-1.html">
                                <i className="icon material-icons md-monetization_on"></i>
                                <span className="text">Transactions</span>
                            </a>
                            <div className="submenu">
                                <a href="page-transactions-1.html">Transaction 1</a>
                                <a href="page-transactions-2.html">Transaction 2</a>
                            </div>
                        </li>
                        <li className="menu-item has-submenu">
                            <a className="menu-link" href="#">
                                <i className="icon material-icons md-person"></i>
                                <span className="text">Account</span>
                            </a>
                            <div className="submenu">
                                <a href="page-account-login.html">User login</a>
                                <a href="page-account-register.html">User registration</a>
                                <a href="page-error-404.html">Error 404</a>
                            </div>
                        </li>
                        <li className="menu-item">
                            <a className="menu-link" href="page-reviews.html">
                                <i className="icon material-icons md-comment"></i>
                                <span className="text">Reviews</span>
                            </a>
                        </li>
                        <li className="menu-item">
                            <a className="menu-link" href="page-brands.html">
                                {' '}
                                <i className="icon material-icons md-stars"></i> <span className="text">Brands</span>{' '}
                            </a>
                        </li>
                        <li className="menu-item">
                            <a className="menu-link" disabled href="#">
                                <i className="icon material-icons md-pie_chart"></i>
                                <span className="text">Statistics</span>
                            </a>
                        </li> */}
                    </ul>
                    <hr />
                    <ul className="menu-aside">
                        <li className="menu-item has-submenu">
                            <a className="menu-link" href="#">
                                <i className="icon material-icons md-settings"></i>
                                <span className="text">Settings</span>
                            </a>
                            <div className="submenu">
                                <a href="page-settings-1.html">Setting sample 1</a>
                                <a href="page-settings-2.html">Setting sample 2</a>
                            </div>
                        </li>
                        <li className="menu-item">
                            <a className="menu-link" href="page-blank.html">
                                <i className="icon material-icons md-local_offer"></i>
                                <span className="text"> Starter page </span>
                            </a>
                        </li>
                    </ul>
                    <br />
                    <br />
                </nav>
            </aside>
            <ToastContainer />
        </>
    );
}
