import { useEffect, useState } from 'react';
import useAuth from '../../framework/hooks/useAuth';

export default function ContentBackend({ children }) {
    const [darkMode, setDarkMode] = useState(
        eval(window.localStorage.getItem('darkmode')) ??
            (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches),
    );
    const auth = useAuth();

    useEffect(() => {
        if (darkMode) {
            document.querySelector('body').classList.add('dark');
        } else {
            document.querySelector('body').classList.remove('dark');
        }
    }, [darkMode]);

    const handleDarkMode = () => {
        setDarkMode(!darkMode);
        window.localStorage.setItem('darkmode', !darkMode + '');
    };

    return (
        <main className="main-wrap">
            <header className="main-header navbar">
                <div className="col-search"></div>
                <div className="col-nav">
                    <ul className="nav">
                        <li className="nav-item">
                            <div onClick={handleDarkMode} className="nav-link btn-icon darkmode" href="#">
                                {' '}
                                <i className="material-icons md-nights_stay"></i>{' '}
                            </div>
                        </li>
                        <li className="dropdown nav-item">
                            <div
                                className="dropdown-toggle"
                                data-bs-toggle="dropdown"
                                id="dropdownAccount"
                                aria-expanded="false"
                            >
                                Welkom, {auth?.user?.name}
                            </div>
                            <div className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownAccount">
                                <a className="dropdown-item text-danger" href="#">
                                    <i className="material-icons md-exit_to_app"></i>Logout
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
            </header>
            <section className="content-main">{children}</section>
            <footer className="main-footer font-xs">
                <div className="row">
                    <div className="col-sm-6">
                        <script>document.write(new Date().getFullYear());</script>
                        &copy; CombiGro x Bandwerk
                    </div>
                    <div className="col-sm-6">
                        <div className="text-sm-end">All rights reserved</div>
                    </div>
                </div>
            </footer>
        </main>
    );
}
