import axios from 'axios';
import Fuse from 'fuse.js';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useApiUrl from '../../../framework/hooks/useApiUrlHook';
import useHelpers from '../../../framework/hooks/useHelpers';
import useRouteList from '../../../framework/hooks/useRouteList';

function Column({ title, field }) {
    return <td data-for={field}>{title}</td>;
}

export default function ProductsTable() {
    const [results, setResults] = useState(null);
    const [query, setQuery] = useState('');
    const [amount, setAmount] = useState(100);

    const { money } = useHelpers();
    const routes = useRouteList();
    const apiUrl = useApiUrl();
    const navigate = useNavigate();

    useEffect(() => {
        if (apiUrl !== null) {
            axios
                .post(`${apiUrl}/products/minified`, {
                    query: query === '' ? null : query,
                    amount: amount,
                })
                .then((response) => setResults(response.data));
        }
    }, [apiUrl, query, amount]);

    const handleAmount = (event) => {
        const value = parseInt(event.target.value);
        setAmount(value);
    };

    return (
        <>
            <div className="card mb-4">
                <header className="card-header">
                    <div className="row align-items-center">
                        <div className="col col-check flex-grow-0">Zoeken:</div>
                        <div className="col-md-3 col-12 me-auto mb-md-0 mb-3">
                            <input
                                className="form-control"
                                type="text"
                                value={query}
                                placeholder={`Zoek in ${results?.total ?? 0} producten`}
                                onChange={(e) => setQuery(e.target.value)}
                            />
                        </div>
                        <div className="col-md-2 col-6">Aantal producten tonen:</div>
                        <div className="col-md-2 col-6">
                            <select className="form-select" value={amount.toString()} onChange={handleAmount}>
                                <option value={200}>100</option>
                                <option value={250}>250</option>
                                <option value={500}>500</option>
                                <option value={1000}>1000</option>
                            </select>
                        </div>
                    </div>
                </header>
                <div className="card-body">
                    {results?.products?.map((product, i) => {
                        return (
                            <article className="itemlist" onClick={() => navigate(routes.backoffice.product_edit.replace(':prod_id', product.id))}>
                                <div className="row align-items-center">
                                    <div className="col-lg-2 col-sm-1 col-1 col-check flex-grow-0">
                                        <div className="form-check">{product.item_number}</div>
                                    </div>
                                    <div className="col-lg-4 col-sm-4 col-8 flex-grow-1 col-name">
                                        <div className="info">
                                            <h6 className="mb-0">{product.name}</h6>
                                            <div className="sub">{product.brand}</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-1 col-sm-1 col-4 col-price">
                                        <span>{money(product.price_gross)}</span>
                                    </div>
                                    <div className="col-lg-2 col-sm-2 col-4 col-status">
                                        <span>
                                            {product.amount} {product.packaging_type} {product.size} {product.size_unit}
                                        </span>
                                    </div>
                                    <div className="col-lg-1 col-sm-2 col-4 col-date">
                                        <span>BTW {product.tax}</span>
                                    </div>
                                </div>
                            </article>
                        );
                    }) ?? 'Loading...'}
                </div>
            </div>
        </>
    );
}
