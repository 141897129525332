import axios from 'axios';
import { useEffect, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import useApiUrl from '../../framework/hooks/useApiUrlHook';
import useHelpers from '../../framework/hooks/useHelpers';
import useRouteList from '../../framework/hooks/useRouteList';

const Search = ({ header = true }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [results, setResults] = useState(null);
    const [allowVisible, setAllowVisible] = useState(false);

    const helpers = useHelpers();
    const routes = useRouteList();
    const navigate = useNavigate();

    const apiUrl = useApiUrl();

    useEffect(() => {
        if (apiUrl !== null) {
            axios
                .post(`${apiUrl}/search`, { query: searchTerm })
                .then((response) => {
                    if (response.data === null || Object.keys(response.data).length === 0) {
                        setResults(null);
                    } else {
                        setResults(response.data);
                    }
                })
                .catch((e) => {
                    toast.error('Oeps! Er ging iets mis bij het uitvoeren van je zoekopdracht.');
                });
        }
    }, [apiUrl, searchTerm]);

    return (
        <div className={`search-style-2 ${header ? '' : 'notheader'}`}>
            <form>
                {header ? (
                    <div
                        className={`backdrop ${allowVisible ? 'active' : ''}`}
                        onClick={() => setAllowVisible(false)}
                    />
                ) : null}
                <DebounceInput
                    debounceTimeout={300}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    type="text"
                    placeholder="Zoeken..."
                    onFocus={() => setAllowVisible(true)}
                />
                <button
                    className="searchButton"
                    onClick={() => {
                        navigate(routes.search.replace(':term', encodeURIComponent(searchTerm)));
                    }}
                />

                {results === null || !allowVisible ? null : (
                    <div className="searchResults">
                        <div className="categories">
                            {results?.categories?.map((item, index) => {
                                return (
                                    <Link
                                        to={routes.products.category
                                            .replace(':cat_id', item.id)
                                            .replace(':cat_name', helpers.urlEncode(item.name))}
                                        key={index}
                                    >
                                        {item.name}
                                    </Link>
                                );
                            })}
                        </div>
                        <div className="products">
                            {results?.products?.map((item, index) => {
                                return (
                                    <Link
                                        to={routes.products.detail
                                            .replace(':prod_id', item.id)
                                            .replace(':prod_name', helpers.urlEncode(item.name))}
                                        key={index}
                                        className="product"
                                    >
                                        <div className="test">
                                            <div className="product-img">
                                                <div className="default-img">
                                                    <img
                                                        className="default-img"
                                                        src={helpers.localImage(helpers.productImage(item?.images, 0), {
                                                            width: 100,
                                                            height: 100,
                                                        })}
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="product-info">
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                {helpers.getProductDiscountString(item) !== null && (
                                                    <span className="sale">Aanbieding!</span>
                                                )}
                                            </div>
                                            <div className="product-content-wrap">
                                                <div className="product-category">{item?.brand?.name}</div>
                                                <h2>{item?.name}</h2>

                                                <div>
                                                    <span className="font-small text-muted">
                                                        {item?.amount > 1 ? item?.amount : null}{' '}
                                                        {item?.packaging_type?.name} {item?.size}{' '}
                                                        {item?.size_unit?.unit}
                                                    </span>
                                                </div>

                                                {item.price_gross ? (
                                                    <>
                                                        <div className="font-small text-muted sale">
                                                            {helpers.getProductDiscountString(item)}
                                                        </div>
                                                        <div className="product-card-bottom">
                                                            <div className="product-price">
                                                                <span>
                                                                    {helpers.money(
                                                                        helpers.getProductCurrentPrice(item),
                                                                    )}
                                                                </span>
                                                                {helpers.getProductOldPrice(item) !== null ? (
                                                                    <span className="old-price">
                                                                        {helpers.money(
                                                                            helpers.getProductOldPrice(item),
                                                                        )}
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : null}
                                            </div>
                                        </div>
                                    </Link>
                                );
                            })}
                        </div>
                    </div>
                )}
            </form>
        </div>
    );
};

export default Search;
