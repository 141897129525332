import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import useApiUrl from '../../framework/hooks/useApiUrlHook';
import useRouteList from '../../framework/hooks/useRouteList';

import './scss/paymentPage.scss';

export default function PaymentPage() {
    const [title, setTitle] = useState('Ga naar de betaalomgeving');
    const [text, setText] = useState('Kom terug zodra de betaling is voltooid.');
    const [button, setButton] = useState('Klik hier om de betaling te starten');

    const apiUrl = useApiUrl();
    const navigate = useNavigate();
    const routes = useRouteList();

    const [transactionActive, setTransactionActive] = useState(false);
    const { transactionId } = useParams();
    const [transaction, setTransaction] = useState(null);

    const getTransaction = () => {
        axios.get(`${apiUrl}/payment/transaction/${transactionId}`).then((response) => {
            setTransaction(response.data);
            console.log(response.data.state);
        });
    };

    useEffect(() => {
        if (!transaction && apiUrl && transactionId) {
            getTransaction();
        }
    }, [apiUrl, transactionId]);

    useEffect(() => {
        let interval;
        if (apiUrl && transactionActive) {
            interval = setInterval(() => {
                getTransaction();
            }, 2500);
        } else {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [apiUrl, transactionActive]);

    useEffect(() => {
        if (transaction?.state !== null) {
            if (transaction?.state == 100) {
                // Succesvol
                setTransactionActive(false);
                setTitle('Betaling succesvol!');
                setText('We zijn je betaling aan het verwerken, een ogenblik geduld.');
                setButton(null);

                setTimeout(() => {
                    navigate(routes.order.complete.replace(':orderId', transaction.order_id));
                }, 3000);
            } else if (transaction?.state > 0) {
                // Waiting
                setTitle('Betaling in afwachting!');
                setText('Je bent bijna zo ver, volg de instructies op de pagina van de betalingsprovider.');
            } else if (transaction?.state < 0) {
                // Cancelled.
                setTransactionActive(false);
                setTitle('Betaling ongeldig');
                setText('Het is niet gelukt de betaling te voltooien, probeer het opnieuw.');
                setButton(null);
            }
        }
    }, [transaction]);

    return (
        <section className="mt-50 mb-50">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 m-auto">
                        <div className="featured-card text-center">
                            {/* <img src="/assets/imgs/theme/icons/icon-2.svg" alt="" /> */}
                            <div className="animated_icon">
                                {transaction?.state == 100 ? (
                                    <svg
                                        className="checkmark animateElement"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 52 52"
                                    >
                                        <circle
                                            className="checkmark__circle animateElement"
                                            cx="26"
                                            cy="26"
                                            r="25"
                                            fill="none"
                                        />
                                        <path
                                            className="checkmark__check animateElement"
                                            fill="none"
                                            d="M14.1 27.2l7.1 7.2 16.7-16.8"
                                        />
                                    </svg>
                                ) : null}
                                {transaction?.state < 0 ? (
                                    <svg
                                        className="crossmark failed animateElement"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 52 52"
                                    >
                                        <circle
                                            className="crossmark__circle animateElement"
                                            cx="26"
                                            cy="26"
                                            r="25"
                                            fill="none"
                                        />
                                        <path
                                            className="cross__path cross__path--right animateElement"
                                            fill="none"
                                            d="M16,16 l20,20"
                                        />
                                        <path
                                            className="cross__path cross__path--left animateElement"
                                            fill="none"
                                            d="M16,36 l20,-20"
                                        />
                                    </svg>
                                ) : null}
                                {(transaction?.state > 0 && transaction?.state < 100) || transaction?.state == null  ? (
                                    <svg
                                        className="crossmark animateElement"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 52 52"
                                    >
                                        <circle
                                            className="crossmark__circle animateElement"
                                            cx="26"
                                            cy="26"
                                            r="25"
                                            fill="none"
                                        />
                                        <circle
                                            className="loader-path"
                                            cx="26"
                                            cy="26"
                                            r="17"
                                            fill="none"
                                            stroke="#ffffff"
                                            strokeWidth="3"
                                        />
                                    </svg>
                                ) : null}
                            </div>
                            <h4>{title}</h4>
                            <p>{text}</p>
                            {button !== null ? (
                                <a
                                    target="_blank"
                                    className="btn"
                                    onClick={() => {
                                        window.open(transaction.paymentUrl, '_blank').focus();
                                        setTransactionActive(true);
                                        setText('Voltooi de betaling in het scherm van de betaalprovider');
                                    }}
                                >
                                    {button}
                                </a>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
