import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import AssignProductPromotion from '../../../components/backoffice/components/AssignProductPromotion';
import AssignProductSelector from '../../../components/backoffice/products/AssignProductSelector';
import useApiUrl from '../../../framework/hooks/useApiUrlHook';
import useHelpers from '../../../framework/hooks/useHelpers';
import useProductDetails from '../../../framework/hooks/useProductDetails';
import useRouteList from '../../../framework/hooks/useRouteList';

import '../scss/edit.scss';

export default function ProductManage() {
    const [checkedCategories, setcheckedCategories] = useState([]);
    const [productAlternatives, setProductAlternatives] = useState([]);
    const [productImages, setProductImages] = useState([]);
    const [productUpsells, setProductUpsells] = useState([]);
    const [productId, setProductId] = useState(null);
    const { product, brands, taxes, sizeUnits, packagingTypes, categories, getDependencies, getProduct } =
        useProductDetails(productId);

    const apiUrl = useApiUrl();
    const navigate = useNavigate();
    const routes = useRouteList();
    const helpers = useHelpers();

    const {
        register,
        handleSubmit,
        setValue,
        control,
        formState: { errors },
    } = useForm();

    const packaging_type = useWatch({
        control,
        name: 'packaging_type',
    });

    const size_unit = useWatch({
        control,
        name: 'size_unit',
    });

    const brand = useWatch({
        control,
        name: 'brand',
    });

    const onSubmit = (data) => {
        const formData = data;

        formData.categories = checkedCategories;
        formData.productAlternatives = productAlternatives.map((i) => i.id);
        formData.productUpsells = productUpsells.map((i) => i.id);

        if (productId !== null) {
            axios.post(`${apiUrl}/products/admin/${productId}/update`, { product: data }).then(() => {
                getProduct();
                getDependencies();
            });
        } else {
            axios.post(`${apiUrl}/products/admin/new`, { product: data }).then((response) => {
                const { id } = response.data;
                navigate(routes.backoffice.product_edit.replace(':prod_id', id));
            });
        }
    };

    const handleFileUpload = (e) => {
        const formData = new FormData();

        formData.append('image', e.target.files[0]);

        if (apiUrl !== null && productId !== null) {
            axios
                .post(`${apiUrl}/products/admin/${productId}/updateImage`, formData, {
                    headers: {
                        'content-type': 'multipart/form-data',
                    },
                })
                .then((response) => setProductImages(response.data));
        }
    };

    const handleDeleteImage = (id) => {
        const res = window.confirm('Weet je zeker dat je deze afbeelding wil verwijderen?');

        if (!res) return;

        axios.delete(`${apiUrl}/products/admin/${id}/deleteImage`).then((response) => {
            setProductImages(response.data);
            toast('Afbeelding verwijderd');
        });
    };

    const params = useParams();

    useEffect(() => {
        setProductId(params.prod_id);
    }, [params.prod_id]);

    useEffect(() => {
        if (
            product != null &&
            brands != null &&
            taxes != null &&
            sizeUnits != null &&
            packagingTypes != null &&
            categories != null
        ) {
            setValue('item_number', product?.item_number);
            setValue('name', product?.name);
            setValue('price_gross', product?.price_gross);
            setValue('amount', product?.amount);
            setValue('size', product?.size);
            setValue('active', product?.active == '1');

            setValue('description', product?.description);
            setValue('ingredients', product?.ingredients);

            if (brands.length > 0) setValue('brand', product?.brand?.id);
            if (packagingTypes.length > 0) setValue('packaging_type', product?.packaging_type?.id);
            if (taxes.length > 0) setValue('tax', product?.tax?.id);
            if (sizeUnits.length > 0) setValue('size_unit', product?.size_unit?.id);

            if (categories.length > 0) setcheckedCategories(product?.categories?.map((item) => item.id.toString()));

            setProductImages(product?.images);
        }
    }, [product, brands, taxes, sizeUnits, packagingTypes, categories]);

    const handleCheck = (event) => {
        var updatedList = [...checkedCategories];
        if (event.target.checked) {
            updatedList = [...checkedCategories, event.target.value];
        } else {
            updatedList.splice(checkedCategories.indexOf(event.target.value), 1);
        }
        setcheckedCategories(updatedList);
    };

    const renderCategory = (item, depth = 0) => {
        return (
            <div className={`category category--depth-${depth}`} key={item.id}>
                <label className="form-check">
                    <input
                        className="form-check-input"
                        value={item.id}
                        type="checkbox"
                        checked={checkedCategories.includes(item.id + '')}
                        onChange={handleCheck}
                    />
                    <span className="form-check-label">{item.name}</span>
                </label>
                {item.children !== null && item.children?.length > 0 ? (
                    <div className="category-group">{item.children.map((item) => renderCategory(item, depth + 1))}</div>
                ) : null}
            </div>
        );
    };

    return (
        <section className="content-main edit">
            <div className="row">
                <div className="col-lg-8">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="col-12">
                            <div className="content-header">
                                <h2 className="content-title">
                                    {product != undefined ? 'Bewerk artikel' : 'Nieuw artikel'}
                                </h2>
                                <div>
                                    <Link
                                        to={routes.products.detail
                                            .replace(':prod_id', product?.id)
                                            .replace(':prod_name', helpers.urlEncode(product?.name))}
                                        target="_blank"
                                        className="btn btn-md rounded font-sm hover-up mr-5"
                                    >
                                        Bekijk product
                                    </Link>
                                    <button type="submit" className="btn btn-md rounded font-sm hover-up">
                                        Opslaan
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="card mb-4">
                            <div className="card-header">
                                <h4>Stamgegevens</h4>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="mb-4">
                                            <label className="form-label">Artikelnummer</label>
                                            <div className="row gx-2">
                                                <input
                                                    disabled={product != undefined}
                                                    type="text"
                                                    placeholder="Artikelnummer..."
                                                    className="form-control"
                                                    {...register('item_number', {})}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="mb-4">
                                            <label className="form-label">Artikelnaam</label>
                                            <div className="row gx-2">
                                                <input
                                                    type="text"
                                                    placeholder="Naam..."
                                                    {...register('name', {})}
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <label className="form-check mb-4">
                                        <input className="form-check-input" type="checkbox" 
                                                    {...register('active', {})} />
                                        <span className="form-check-label"> Actief </span>
                                    </label>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="mb-4">
                                            <label className="form-label">Merk</label>
                                            <select className="form-select" {...register('brand', { required: true })}>
                                                <option>Geen merk</option>
                                                {brands.map((item, index) => (
                                                    <option key={index} value={item.id}>
                                                        {item.name}
                                                    </option>
                                                ))}
                                                <option value="-1">Nieuw merk...</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="mb-4">
                                            {brand === '-1' ? (
                                                <>
                                                    <label className="form-label">Nieuw merk:</label>
                                                    <input
                                                        placeholder="Naam..."
                                                        {...register('brand_new', {})}
                                                        className="form-control"
                                                    />
                                                </>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-8">
                                        <div className="mb-4">
                                            <label className="form-label">Prijs bruto</label>
                                            <div className="row gx-2">
                                                <input
                                                    placeholder="0.00"
                                                    {...register('price_gross', {})}
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <label className="form-label">BTW</label>
                                        <select className="form-select" {...register('tax', { required: true })}>
                                            {taxes.map((item, index) => (
                                                <option key={index} value={item.id}>
                                                    {item.name} - {item.percentage}%
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                {/* 50 Doos 30 gram */}
                                <div className="row">
                                    <div className="col-lg-3">
                                        <label className="form-label">Aantal per colli</label>
                                        <input placeholder="0" {...register('amount', {})} className="form-control" />
                                    </div>
                                    <div className="col-lg-3">
                                        <label className="form-label">Verpakking</label>
                                        <select
                                            className="form-select"
                                            {...register('packaging_type', { required: true })}
                                        >
                                            {packagingTypes.map((item, index) => (
                                                <option key={index} value={item.id}>
                                                    {item.name}
                                                </option>
                                            ))}
                                            <option value="-1">Nieuwe verpakking...</option>
                                        </select>
                                        {packaging_type === '-1' ? (
                                            <input
                                                placeholder="0"
                                                {...register('packaging_type_new', {})}
                                                className="form-control"
                                            />
                                        ) : null}
                                    </div>
                                    <div className="col-lg-3">
                                        <label className="form-label">Grootheid</label>
                                        <input
                                            placeholder="0"
                                            type="number"
                                            {...register('size', {})}
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="col-lg-3">
                                        <label className="form-label">Eenheid</label>
                                        <select className="form-select" {...register('size_unit', { required: true })}>
                                            {sizeUnits.map((item, index) => (
                                                <option key={index} value={item.id}>
                                                    {item.unit}
                                                </option>
                                            ))}
                                            <option value="-1">Nieuwe eenheid...</option>
                                        </select>

                                        {size_unit === '-1' ? (
                                            <input
                                                placeholder="0"
                                                {...register('size_unit_new', {})}
                                                className="form-control"
                                            />
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card mb-4">
                            <div className="card-header">
                                <h4>Productbeschrijving</h4>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="mb-4">
                                            <label htmlFor="product_name" className="form-label">
                                                Productomschrijving
                                            </label>
                                            <textarea
                                                placeholder="Productomschrijving..."
                                                {...register('description', { required: true, maxLength: 1000 })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="mb-4">
                                            <label htmlFor="product_name" className="form-label">
                                                Ingrediënten
                                            </label>
                                            <textarea
                                                placeholder="Ingrediënten..."
                                                {...register('ingredients', { required: true, maxLength: 1000 })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <AssignProductSelector
                            title="Productalternatieven"
                            productAlternatives={product?.product_alternatives}
                            changeCallback={(data) => setProductAlternatives(data)}
                            newProduct={productId == undefined}
                        />
                        <AssignProductSelector
                            title="Upsells"
                            productAlternatives={product?.product_upsells}
                            changeCallback={(data) => setProductUpsells(data)}
                            newProduct={productId == undefined}
                        />

                        <div className="col-12">
                            <div className="content-header">
                                <h2 className="content-title"></h2>
                                <div>
                                    <button type="submit" className="btn btn-md rounded font-sm hover-up">
                                        Opslaan
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                    <hr />
                    {productId && product !== null ? (
                        <AssignProductPromotion product={product} callback={getProduct} />
                    ) : null}
                </div>
                <div className="col-lg-4">
                    <div className="card mb-4">
                        <div className="card-header">
                            <h4>Afbeeldingen</h4>
                        </div>
                        <div className="card-body">
                            <div className="input-upload">
                                <div className="images">
                                    {productImages.map((image, index) => (
                                        <div
                                            key={index}
                                            className="uploadedImage"
                                            onClick={() => handleDeleteImage(image.id)}
                                        >
                                            <img
                                                src={helpers.localImage(image.path, { width: 400, height: 400 })}
                                                alt=""
                                            />
                                        </div>
                                    ))}
                                </div>
                                <input className="form-control" type="file" onChange={handleFileUpload} />
                            </div>
                        </div>
                    </div>
                    <div className="card mb-4">
                        <div className="card-header">
                            <h4>Categorie</h4>
                        </div>
                        <div className="card-body">
                            <div className="row gx-2">
                                <div className="col-12">
                                    <div className="mb-4">
                                        <label htmlFor="product_name" className="form-label">
                                            Tags
                                        </label>
                                        <div className="category-group">
                                            {categories.map((item) => renderCategory(item))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
