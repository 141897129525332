import axios from 'axios';
import { check } from 'prettier';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import useApiUrl from './useApiUrlHook';
import useRouteList from './useRouteList';

export default function useAuth() {
    const [csrf, setCsrf] = useState(null);
    const [auth, setAuth] = useState(false);
    const [user, setUser] = useState(null);
    const [error, setError] = useState(null);

    const apiUrl = useApiUrl();
    const navigate = useNavigate();
    const routes = useRouteList();

    useEffect(() => {
        const csrfSet = document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*\=\s*([^;]*).*$)|^.*$/, '$1');

        async function getCsrf() {
            axios
                .get(`${apiUrl}/sanctum/csrf-cookie`, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                .then(() => {
                    setCsrf(true);
                });
        }

        if (csrfSet.length > 0) {
            setCsrf(true);
        } else if (apiUrl !== null && csrf === null) getCsrf();
    }, [apiUrl, csrf]);

    useEffect(() => {
        if (apiUrl && user === null && csrf) {
            getUser();
        }
    }, [apiUrl]);

    async function login(username, password) {
        setError(null);

        await axios
            .post(
                `${apiUrl}/auth/login`,
                { email: username, password },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                },
            )
            .then((response) => {
                setAuth(true);
                getUser(true);
                return;
            })
            .catch((error) => {
                toast.error(`Inloggen mislukt; ${error?.response?.data?.message}`);
            });
    }

    async function register(name, email, password, password_confirmation) {
        setError(null);

        await axios
            .post(
                `${apiUrl}/auth/register`,
                { name, email, password, password_confirmation },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                },
            )
            .then((response) => {
                navigate('/account/login');
                return;
            })
            .catch((error) => {
                console.error(error);
                setError('Er ging iets mis bij het registreren.');
            });
    }

    async function forgotPasswordGetLink(email) {
        setError(null);

        await axios
            .post(
                `${apiUrl}/auth/forgot-password`,
                { email },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                },
            )
            .then((response) => {
                navigate('/account/login');
                return;
            })
            .catch((error) => {
                toast.error(`Registreren mislukt; ${error?.response?.data?.message}`);
            });
    }

    async function completeForgotPassword(token, email, password, password_confirmation) {
        if (password !== password_confirmation) {
            toast.error('Wachtwoorden komen niet overeen.');
            return;
        }

        await axios
            .post(
                `${apiUrl}/auth/reset-password`,
                { token, email, password, password_confirmation },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                },
            )
            .then((response) => {
                toast.success('Wachtwoord gewijzigd!');
                navigate('/account/login');
                return;
            })
            .catch((error) => {
                toast.error(`Wachtwoord wijzigen mislukt; ${error?.response?.data?.message}`);
            });
    }

    async function logout() {
        setError(null);

        await axios
            .post(
                `${apiUrl}/auth/logout`,
                {},
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                },
            )
            .then((response) => {
                setAuth(false);
                setUser(null);
                navigate(routes.login);
                return;
            })
            .catch((error) => {
                toast.error(`Uitloggen mislukt; ${error?.response?.data?.message}`);
            });
    }

    async function getUser(checkVerified = false) {
        setError(null);

        await axios
            .get(`${apiUrl}/user`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then((response) => {
                setUser(response.data);
                setAuth(true);
                return;
            })
            .catch((error) => {
                if(checkVerified) {
                    toast.error(error?.response?.data?.message);
                    logout();
                }
                setAuth(false);
            });
    }

    async function updateUser(name, email) {
        await axios
            .put(`${apiUrl}/auth/user/profileinformation`, { name, email })
            .then((response) => {
                getUser();
                toast.success('Opgeslagen!');
                return;
            })
            .catch((error) => {
                toast.error(`Er gings iets mis; ${error?.response?.data?.message}`);
            });
    }

    async function updatePassword(current_password, password, password_confirmation) {
        await axios
            .put(`${apiUrl}/auth/user/password`, { current_password, password, password_confirmation })
            .then(() => {
                toast.success('Wijzigingen opgeslagen, log opnieuw in.');
                setTimeout(() => {
                    logout();
                }, 500);
            })
            .catch((error) => {
                toast.error(`Er gings iets mis; ${error?.response?.data?.message}`);
            });
    }

    const updateDetails = (data) => {
        axios
            .put(`${apiUrl}/auth/user/profileinformation`, data)
            .then(() => {
                logout();
                toast.success('Wijzigingen opgeslagen, log opnieuw in.');
            })
            .catch((error) => {
                toast.error(`Er gings iets mis; ${error?.response?.data?.message}`);
            });
    };

    return {
        auth,
        user,
        error,
        getUser,
        login,
        register,
        logout,
        forgotPasswordGetLink,
        completeForgotPassword,
        updateUser,
        updatePassword,
        updateDetails
    };
}
