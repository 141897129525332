import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useApiUrl from '../../framework/hooks/useApiUrlHook';
import useAuth from '../../framework/hooks/useAuth';
import useRouteList from '../../framework/hooks/useRouteList';

export default function LoginPage() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const routes = useRouteList();
    const auth = useAuth();
    const navigate = useNavigate();
    const apiUrl = useApiUrl();

    const handleLogin = (e) => {
        e.preventDefault();

        auth.login(username, password)
    };

    useEffect(() => {
        if(auth.auth) {
            const referrer = localStorage.getItem('referrer');
            if(referrer) {
                localStorage.removeItem('referrer');

                navigate(referrer.replace(apiUrl.replace('/api', ''), ''));
            } else {
                navigate('/');
            }
        }
    }, [auth]);

    return (
        <>
            <div className="page-content pt-150 pb-150">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-10 col-md-12 m-auto">
                            <div className="row">
                                <div className="col-lg-6 pr-30 d-none d-lg-block">
                                    <img className="border-radius-15" src={`/assets/imgs/page/login-1.png`} alt="" />
                                </div>
                                <div className="col-lg-6 col-md-8">
                                    <div className="login_wrap widget-taber-content background-white">
                                        <div className="padding_eight_all bg-white">
                                            <div className="heading_s1">
                                                <h1 className="mb-5">Login</h1>
                                                <p className="mb-30">
                                                    Nog geen account? <Link to={routes.register}>Registreer</Link>
                                                </p>
                                            </div>
                                            <form onSubmit={handleLogin}>
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        required=""
                                                        name="email"
                                                        placeholder="Username or Email *"
                                                        value={username}
                                                        onChange={(e) => setUsername(e.target.value)}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <input
                                                        required=""
                                                        type="password"
                                                        name="password"
                                                        placeholder="Your password *"
                                                        value={password}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                    />
                                                </div>
                                                <div className="login_footer form-group mb-50">
                                                    <Link className="text-muted" to={routes.account.password_reset_request}>
                                                        Wachtwoord vergeten?
                                                    </Link>
                                                </div>
                                                <div className="form-group">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-heading btn-block hover-up"
                                                        name="login"
                                                    >
                                                        Log in
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
