import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import useApiUrl from '../../framework/hooks/useApiUrlHook';
import useAuth from '../../framework/hooks/useAuth';
import useHelpers from '../../framework/hooks/useHelpers';
import useMenu from '../../framework/hooks/useMenuHook';
import useRouteList from '../../framework/hooks/useRouteList';
import Search from '../ecommerce/Search';

import 'react-toastify/dist/ReactToastify.css';

import useCart from '../../framework/hooks/useCart';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setCart as setCartUnbound } from '../../redux/actions/cartActions';

export default function Header({ totalCartItems, toggleClick, totalFavouriteItems }) {
    const [isToggled, setToggled] = useState(false);
    const [scroll, setScroll] = useState(false);
    const [categories, setCategories] = useState(null);

    const dispatch = useDispatch();
    const { cart } = useSelector((state) => state);
    const setCart = bindActionCreators(setCartUnbound, dispatch);

    const apiUrl = useApiUrl();
    const menu = useMenu();
    const routes = useRouteList();
    const auth = useAuth();
    const helpers = useHelpers();

    const cartHook = useCart();

    useEffect(() => {
        const getCartEffect = async () => {
            const cartContent = await cartHook.getCart();
            setCart(cartContent);
        };
        if (cart?.items == null && apiUrl !== null) getCartEffect();
    }, [cart, apiUrl]);

    useEffect(() => {
        document.addEventListener('scroll', () => {
            const scrollCheck = window.scrollY >= 100;
            if (scrollCheck !== scroll) {
                setScroll(scrollCheck);
            }
        });
    });

    useEffect(() => {
        async function getCategories() {
            axios.get(`${apiUrl}/product-categories`).then((response) => {
                setCategories(response.data);
            });
        }

        if (apiUrl && categories === null) getCategories();
    }, [apiUrl]);

    const handleToggle = () => setToggled(!isToggled);

    return (
        <>
            <header className="header-area header-style-1 header-height-2">
                <div className="mobile-promotion">
                    <span>
                        <Link style={{ color: '#fff' }} to="/account/registreer">
                            Nog geen klant? Meld je nu aan!
                        </Link>
                    </span>
                </div>
                <div className="header-top header-top-ptb-1 d-none d-lg-block">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-3 col-lg-4">
                                <div className="header-info">
                                    <ul>
                                        {menu?.map((item, key) => (
                                            <li key={key}>
                                                <Link to={item.url_titel}>{item.titel}</Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-4">
                                <div className="text-center">
                                    <div id="news-flash" className="d-inline-block">
                                        <ul>
                                            <li>
                                                <Link to="/account/registreer">Nog geen klant? Meld je nu aan!</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4">
                                <div className="header-info header-info-right">
                                    <ul>
                                        <li>
                                            Hulp nodig? Bel ons:&nbsp;
                                            <a href="tel:0575 516461">
                                                <strong className="text-brand">0575 516461</strong>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header-middle header-middle-ptb-1 d-none d-lg-block">
                    <div className="container">
                        <div className="header-wrap">
                            <div className="logo logo-width-1">
                                <Link to={routes.home}>
                                    <img src="/assets/imgs/theme/logo.svg" alt="logo" />
                                </Link>
                            </div>
                            <div className="header-right">
                                <Search />
                                <div className="header-action-right">
                                    <div className="header-action-2">
                                        <div className="header-action-icon-2">
                                            <Link to={routes.favourites}>
                                                <img
                                                    className="svgInject"
                                                    alt="Evara"
                                                    src="/assets/imgs/theme/icons/icon-heart.svg"
                                                />
                                                {totalFavouriteItems > 0 ? (
                                                    <span className="pro-count blue">{totalFavouriteItems}</span>
                                                ) : null}
                                            </Link>
                                            <Link to={routes.favourites}>
                                                <span className="lable">Favorieten</span>
                                            </Link>
                                        </div>
                                        <div className="header-action-icon-2">
                                            <Link to={routes.order.cart} className="mini-cart-icon">
                                                <img alt="Evara" src="/assets/imgs/theme/icons/icon-cart.svg" />
                                                {cart?.items?.length > 0 ? (
                                                    <span className="pro-count blue">{cart?.items?.length}</span>
                                                ) : null}
                                            </Link>
                                            <Link to={routes.order.cart}>
                                                <span className="lable">Winkelwagen</span>
                                            </Link>
                                        </div>

                                        <div className="header-action-icon-2">
                                            <a>
                                                <img
                                                    className="svgInject"
                                                    alt="Nest"
                                                    src="/assets/imgs/theme/icons/icon-user.svg"
                                                />
                                            </a>
                                            <Link to={auth.auth && auth.user ? routes.account.dashboard : routes.login}>
                                                <span className="lable ml-0">
                                                    {auth.auth && auth.user ? `Welkom ${auth.user.name}` : 'Log in'}
                                                </span>
                                            </Link>
                                            <div className="cart-dropdown-wrap cart-dropdown-hm2 account-dropdown">
                                                <ul>
                                                    {!auth.auth ? (
                                                        <>
                                                            <li>
                                                                <Link to={routes.login}>
                                                                    <i className="fi fi-rs-user mr-10"></i>
                                                                    Inloggen
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to={routes.register}>
                                                                    <i className="fi fi-rs-user mr-10"></i>
                                                                    Registreren
                                                                </Link>
                                                            </li>
                                                        </>
                                                    ) : (
                                                        <li>
                                                            <a onClick={auth.logout}>
                                                                <i className="fi fi-rs-sign-out mr-10"></i>
                                                                Log uit
                                                            </a>
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={
                        scroll
                            ? 'header-bottom header-bottom-bg-color sticky-bar stick'
                            : 'header-bottom header-bottom-bg-color sticky-bar'
                    }
                >
                    <div className="container">
                        <div className="header-wrap header-space-between position-relative">
                            <div className="logo logo-width-1 d-block d-lg-none">
                                <a>
                                    <img src="/assets/imgs/theme/logo.svg" alt="logo" />
                                </a>
                            </div>
                            <div className="header-nav d-none d-lg-flex">
                                <div className="main-menu main-menu-padding-1 main-menu-lh-2 d-none d-lg-block  font-heading">
                                    <nav>
                                        <ul>
                                            <li>
                                                <Link to="/">Home</Link>
                                            </li>

                                            {categories?.map((item, index) => {
                                                return (
                                                    <li className="position-static" key={index}>
                                                        <Link
                                                            to={routes.products.category
                                                                .replace(':cat_id', item.id)
                                                                .replace(':cat_name', helpers.urlEncode(item.name))}
                                                        >
                                                            {item.name}
                                                            {item.children !== null ? (
                                                                <i className="fi-rs-angle-down"></i>
                                                            ) : null}
                                                        </Link>
                                                        {item.children !== null ? (
                                                            <ul className="mega-menu">
                                                                {item.children.map((sub, subIndex) => {
                                                                    return (
                                                                        <li
                                                                            className="sub-mega-menu sub-mega-menu-width-22"
                                                                            key={subIndex}
                                                                        >
                                                                            <Link
                                                                                to={routes.products.category
                                                                                    .replace(':cat_id', sub.id)
                                                                                    .replace(
                                                                                        ':cat_name',
                                                                                        helpers.urlEncode(sub.name),
                                                                                    )}
                                                                                className="menu-title"
                                                                            >
                                                                                {sub.name}
                                                                            </Link>
                                                                            <ul>
                                                                                {sub.children.map(
                                                                                    (subsub, subsubIndex) => {
                                                                                        return (
                                                                                            <li key={subsubIndex}>
                                                                                                <Link
                                                                                                    to={routes.products.category
                                                                                                        .replace(
                                                                                                            ':cat_id',
                                                                                                            subsub.id,
                                                                                                        )
                                                                                                        .replace(
                                                                                                            ':cat_name',
                                                                                                            helpers.urlEncode(
                                                                                                                subsub.name,
                                                                                                            ),
                                                                                                        )}
                                                                                                >
                                                                                                    {subsub.name}
                                                                                                </Link>
                                                                                            </li>
                                                                                        );
                                                                                    },
                                                                                )}
                                                                            </ul>
                                                                        </li>
                                                                    );
                                                                })}
                                                            </ul>
                                                        ) : null}
                                                    </li>
                                                );
                                            })}

                                            <Link to={routes.products.promotions}>Aanbiedingen</Link>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            <div className="hotline d-none d-lg-flex">
                                <img src="/assets/imgs/theme/icons/icon-headphone.svg" alt="hotline" />
                                <p>
                                    <a href="tel:0575 516461">
                                        0575 516461
                                        <span>Hulp nodig? Bel ons!</span>
                                    </a>
                                </p>
                            </div>

                            <div className="header-action-icon-2 d-block d-lg-none">
                                <div className="burger-icon burger-icon-white" onClick={toggleClick}>
                                    <span className="burger-icon-top"></span>
                                    <span className="burger-icon-mid"></span>
                                    <span className="burger-icon-bottom"></span>
                                </div>
                            </div>

                            <div className="header-action-right d-block d-lg-none">
                                <div className="header-action-2">
                                    <div className="header-action-icon-2">
                                        <Link to={routes.favourites}>
                                            <img alt="Evara" src="/assets/imgs/theme/icons/icon-heart.svg" />
                                        </Link>
                                    </div>
                                    <div className="header-action-icon-2">
                                        <Link to={routes.order.cart} className="mini-cart-icon">
                                            <img alt="Evara" src="/assets/imgs/theme/icons/icon-cart.svg" />
                                            {cart?.items?.length > 0 ? (
                                                <span className="pro-count blue">{cart?.items?.length}</span>
                                            ) : null}
                                        </Link>
                                        <div className="cart-dropdown-wrap cart-dropdown-hm2">
                                            <ul>
                                                <li>
                                                    <div className="shopping-cart-img">
                                                        <a>
                                                            <img alt="Evara" src="/assets/imgs/shop/thumbnail-3.jpg" />
                                                        </a>
                                                    </div>
                                                    <div className="shopping-cart-title">
                                                        <h4>
                                                            <a>Plain Striola Shirts</a>
                                                        </h4>
                                                        <h3>
                                                            <span>1 × </span>
                                                            $800.00
                                                        </h3>
                                                    </div>
                                                    <div className="shopping-cart-delete">
                                                        <a>
                                                            <i className="fi-rs-cross-small"></i>
                                                        </a>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="shopping-cart-img">
                                                        <a>
                                                            <img alt="Evara" src="/assets/imgs/shop/thumbnail-4.jpg" />
                                                        </a>
                                                    </div>
                                                    <div className="shopping-cart-title">
                                                        <h4>
                                                            <a>Macbook Pro 2022</a>
                                                        </h4>
                                                        <h3>
                                                            <span>1 × </span>
                                                            $3500.00
                                                        </h3>
                                                    </div>
                                                    <div className="shopping-cart-delete">
                                                        <a>
                                                            <i className="fi-rs-cross-small"></i>
                                                        </a>
                                                    </div>
                                                </li>
                                            </ul>
                                            <div className="shopping-cart-footer">
                                                <div className="shopping-cart-total">
                                                    <h4>
                                                        Total
                                                        <span>$383.00</span>
                                                    </h4>
                                                </div>
                                                <div className="shopping-cart-button">
                                                    <a>View cart</a>
                                                    <a>Checkout</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <ToastContainer />
        </>
    );
}
