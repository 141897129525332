import axios from 'axios';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import useApiUrl from '../../framework/hooks/useApiUrlHook';
import useAuth from '../../framework/hooks/useAuth';
import useHelpers from '../../framework/hooks/useHelpers';
import usePayments from '../../framework/hooks/usePayments';
import useRouteList from '../../framework/hooks/useRouteList';

import './scss/checkoutPage.scss';

export default function CheckoutPage() {
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [invoiceAddress, setInvoiceAddress] = useState(null);
    const [company, setCompany] = useState(null);
    const apiUrl = useApiUrl();
    const navigate = useNavigate();
    const { paymentMethods } = usePayments();

    const routes = useRouteList();

    const { cart } = useSelector((state) => state);
    const { money, localImage, getProductCurrentPrice, getProductOldPrice } = useHelpers();

    const {
        register,
        handleSubmit,
        getValues,
        watch,
        formState: { errors },
    } = useForm();

    const watchPaymentMethod = watch('paymentMethod');

    useEffect(() => {
        const getCompany = async () => {
            axios.get(`${apiUrl}/company/checkout`).then((response) => setCompany(response.data));
        };

        if (company === null && apiUrl !== null) {
            getCompany();
        }
    }, [apiUrl]);

    useEffect(() => {
        if (company !== null) {
            setInvoiceAddress(company?.addresses?.find((i) => (i.type = 'invoice')));
        }
    }, [company]);

    const onSubmit = () => {
        const orderData = {};

        orderData.location = selectedAddress;

        if (orderData.location === -1) {
            orderData.location = {
                name: getValues('name'),
                telephone: getValues('telephone'),
                address: getValues('address'),
                postcode: getValues('postcode'),
                city: getValues('city'),
                administrative_area: getValues('administrative_area'),
                countrycode: 'nl',
            };

            if (
                orderData.location.name == '' ||
                orderData.location.telephone == '' ||
                orderData.location.address == '' ||
                orderData.location.postcode == '' ||
                orderData.location.city == '' ||
                orderData.location.administrative_area == ''
            ) {
                toast.error('Vul alle velden in om een nieuw adres toe te voegen.');
                return;
            }
        }

        orderData.paymentInfo = {
            method: getValues('paymentMethod') ?? '-1',
            bank: getValues('bank') ?? null,
        }

        orderData.deliveryDate = getValues('deliveryDate');

        if (orderData.deliveryDate == -1) {
            orderData.deliveryDate = getValues('deliveryDateCustom');
        }

        if (orderData.deliveryDate === null) {
            toast.error('Kies een geldige leverdatum.');
            return;
        }

        const comment = getValues('comment');
        if (comment !== '') {
            orderData.comment = getValues('comment');
        }

        axios.post(`${apiUrl}/order/checkout`, { orderData }).then((response) => {
            const data = response.data;

            if (data.transactionId) {
                navigate(routes.order.payment.replace(':transactionId', data.transactionId));
            } else if (data.order.id) {
                toast('Bestelling geplaatst');
                navigate(routes.order.complete.replace(':orderId', data.order.id));
            }
        });
    };

    const cartItems = null;

    return (
        <section className="mt-50 mb-50">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 mb-40">
                            <h1 className="heading-2 mb-10">Kassa</h1>
                            <div className="d-flex justify-content-between">
                                <h6 className="text-body">
                                    Je hebt <span className="text-brand">{cart?.items?.length}</span>{' '}
                                    {cart?.items?.length > 1 ? 'producten' : 'product'} in je winkelwagen
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="mb-25">
                                <h4>Factuuradres</h4>
                            </div>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>Naam</td>
                                        <td>{invoiceAddress?.name}</td>
                                    </tr>
                                    <tr>
                                        <td>Adres</td>
                                        <td>{invoiceAddress?.street_address}</td>
                                    </tr>
                                    <tr>
                                        <td>Postcode, plaats</td>
                                        <td>
                                            {invoiceAddress?.postal_code}, {invoiceAddress?.city}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Provincie</td>
                                        <td>{invoiceAddress?.administrative_area}</td>
                                    </tr>
                                    <tr>
                                        <td>Telefoon</td>
                                        <td>{invoiceAddress?.telephone}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="mb-25">
                                <h4>Leveradres</h4>
                            </div>
                            <div className="deliveryLocations">
                                {company?.addresses
                                    ?.filter((item) => item.type === 'delivery')
                                    .map((item, key) => {
                                        if (selectedAddress === null && key === 0) {
                                            setSelectedAddress(item.id);
                                        }
                                        return (
                                            <div
                                                key={key}
                                                className="option"
                                                onClick={() => setSelectedAddress(item.id)}
                                            >
                                                <div
                                                    className={`fakeRadio ${
                                                        selectedAddress === item.id ? 'active' : null
                                                    }`}
                                                ></div>
                                                <div className="inner">
                                                    <div className="line">
                                                        {item.name}, {item.telephone}
                                                    </div>
                                                    <div className="line">{item.street_address}</div>
                                                    <div className="line">
                                                        {item.postal_code}, {item.city}
                                                    </div>
                                                    <div className="line">
                                                        {item.administrative_area}, {item.country}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                <div className="option" onClick={() => setSelectedAddress(-1)}>
                                    <div
                                        className={`fakeRadio ${
                                            company?.addresses?.filter((item) => item.type === 'delivery').length ===
                                                0 || selectedAddress === -1
                                                ? 'active'
                                                : null
                                        }`}
                                    ></div>
                                    <div className="inner">
                                        <h3>Nieuw adres</h3>
                                        <div className="form-group">
                                            <input type="text" placeholder="Naam" {...register('name', {})} />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="tel"
                                                placeholder="Telefoonnummer"
                                                {...register('telephone', {})}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                placeholder="Adres + huisnummer"
                                                {...register('address', {})}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input type="text" placeholder="Postcode" {...register('postcode', {})} />
                                        </div>
                                        <div className="form-group">
                                            <input type="text" placeholder="Plaatsnaam" {...register('city', {})} />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                placeholder="Provincie"
                                                {...register('administrative_area', {})}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="border p-40 cart-totals ml-30 mb-50">
                                <div className="d-flex align-items-end justify-content-between mb-30">
                                    <h4>Je bestelling</h4>
                                    <h6 className="text-muted">Subtotaal</h6>
                                </div>
                                <div className="divider-2 mb-30"></div>
                                <div className="table-responsive order_table">
                                    <table className="table no-border">
                                        <tbody>
                                            {cart?.items?.map((item, i) => (
                                                <tr key={i}>
                                                    {item?.product?.images === undefined ||
                                                    item?.product?.images.length < 1 ? (
                                                        <td className="image product-thumbnail" />
                                                    ) : (
                                                        <td className="image product-thumbnail">
                                                            <img
                                                                src={localImage(item?.product?.images[0].path, {
                                                                    width: 150,
                                                                    height: 150,
                                                                })}
                                                            />
                                                        </td>
                                                    )}
                                                    <td>
                                                        <div className="product-category">
                                                            {item?.product?.brand?.name}
                                                        </div>
                                                        <h6 className="w-160 mb-5">{item?.product?.name}</h6>
                                                        <div>
                                                            <span className="font-small text-muted">
                                                                {item?.product?.amount > 1
                                                                    ? item?.product?.amount
                                                                    : null}{' '}
                                                                {item?.product?.packaging_type?.name}{' '}
                                                                {item?.product?.size} {item?.product?.size_unit?.unit}
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <h6 className="text-muted pl-20 pr-20">x {item?.amount}</h6>
                                                    </td>
                                                    <td className="text-right price">
                                                        <h4 className="text-body">
                                                            {money(
                                                                item?.amount *
                                                                    getProductCurrentPrice(item?.product, item?.amount),
                                                            )}
                                                        </h4>
                                                    </td>
                                                </tr>
                                            ))}
                                            <tr>
                                                <td className="image product-thumbnail" />
                                                <td>
                                                    <div className="product-category">Totaal excl. BTW</div>
                                                </td>
                                                <td />
                                                <td className="text-right price">
                                                    <h4 className="text-body">{money(cart?.total_gross ?? 0)}</h4>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="image product-thumbnail" />
                                                <td>
                                                    <div className="product-category">Totaal incl. BTW</div>
                                                </td>
                                                <td />
                                                <td className="text-right price">
                                                    <h4 className="text-body">{money(cart?.total_net ?? 0)}</h4>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div className="bt-1 border-color-1 mt-30 mb-30"></div>
                                <div className="payment_method">
                                    <div className="mb-25">
                                        <h5>Leveringsdatum</h5>
                                    </div>
                                    <div className="payment_option">
                                        {company?.delivery_days
                                            ?.map((item) => {
                                                var d = new Date();
                                                d.setDate(d.getDate() + ((item.day + 7 - d.getDay()) % 7 || 7));

                                                return { ...item, date: d };
                                            })
                                            // @ts-ignore
                                            .sort((a, b) => new Date(a.date) - new Date(b.date))
                                            .map((item, index) => (
                                                <label key={index} className="custom-radio">
                                                    <input {...register('deliveryDate')} type="radio" value={item.id} />
                                                    <span>
                                                        {item.date.toLocaleDateString('nl-NL', {
                                                            weekday: 'long',
                                                            year: 'numeric',
                                                            month: 'long',
                                                            day: 'numeric',
                                                        })}
                                                    </span>
                                                </label>
                                            ))}

                                        <label className="custom-radio">
                                            <input {...register('deliveryDate')} type="radio" value={-1} />
                                            <span>
                                                <input
                                                    type="datetime-local"
                                                    placeholder="date"
                                                    {...register('deliveryDateCustom', {})}
                                                />
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div className="payment_method">
                                    <div className="mt-25 mb-25">
                                        <h5>Opmerking toevoegen</h5>
                                    </div>
                                    <div className="payment_option">
                                        <textarea {...register('comment', { required: false })} cols={30} rows={10} />
                                    </div>
                                </div>
                                <div className="payment_method">
                                    <div className="mt-25 mb-25">
                                        <h5>Betaalwijze</h5>
                                    </div>
                                    <div className="payment_option">
                                        <label className="custom-radio">
                                            <input {...register('paymentMethod')} type="radio" value={-1} />
                                            <span>Op rekening</span>
                                        </label>
                                        {paymentMethods.map((item, index) => (
                                            <label className="custom-radio" key={index}>
                                                <input {...register('paymentMethod')} type="radio" value={item.id} />
                                                <span>
                                                    {item.name}
                                                    {item?.banks?.length && watchPaymentMethod === item.id ? (
                                                        <select className="bankSelect" {...register('bank')}>
                                                            {item?.banks?.map((item, index) => (
                                                                <option key={index} value={item.id}>
                                                                    {item.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    ) : (
                                                        ''
                                                    )}
                                                </span>
                                            </label>
                                        ))}
                                    </div>
                                </div>
                                <button type="submit" className="btn btn-fill-out btn-block mt-30">
                                    Nu bestellen
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </section>
    );
}
