import Navigation from '../components/Navigation';
import Preloader from '../components/Preloader';
import usePage from '../framework/hooks/usePageHook';

export default function ContentPage({ page }) {
    const pageObj = usePage(page.id);

    if (!page) return <Preloader />;

    return (
        <>
            <Navigation page={page.id} />
            <div>
                <h1>
                    {pageObj?.titel} <br /> <em>{pageObj?.sub_paginatitel}</em>
                </h1>
                <div className="content" dangerouslySetInnerHTML={{ __html: pageObj?.inhoud }} />
            </div>
        </>
    );
}
