import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useApiUrl from '../../framework/hooks/useApiUrlHook';
import useHelpers from '../../framework/hooks/useHelpers';
import useMenu from '../../framework/hooks/useMenuHook';
import useRouteList from '../../framework/hooks/useRouteList';

export default function Footer() {
    const [categories, setCategories] = useState(null);

    const menu = useMenu();
    const routes = useRouteList();
    const helpers = useHelpers();

    const apiUrl = useApiUrl();

    useEffect(() => {
        async function getCategories() {
            axios.get(`${apiUrl}/product-categories`).then((response) => {
                setCategories(response.data);
            });
        }

        if (apiUrl && categories === null) getCategories();
    }, [apiUrl]);

    return (
        <>
            <footer className="main">
                <section className="section-padding footer-mid">
                    <div className="container pt-15 pb-20">
                        <div className="row">
                            <div className="col">
                                <div
                                    className="widget-about font-md mb-md-3 mb-lg-3 mb-xl-0  wow animate__animated animate__fadeInUp"
                                    data-wow-delay="0"
                                >
                                    <div className="logo  mb-30">
                                        <Link to="/" className="mb-15">
                                            <img src="/assets/imgs/theme/logo.svg" alt="logo" />
                                        </Link>
                                        <p className="font-lg text-heading">Groothandel in voor de Horeca</p>
                                    </div>
                                    <ul className="contact-infor">
                                        <li>
                                            <img src="/assets/imgs/theme/icons/icon-location.svg" alt="" />
                                            <strong>Adres: </strong> <span>Zonnehorst 4, 7207 BT Zutphen</span>
                                        </li>
                                        <li>
                                            <img src="/assets/imgs/theme/icons/icon-contact.svg" alt="" />
                                            <strong>Bel ons:</strong>
                                            <span>(+31) 0575 516 461</span>
                                        </li>
                                        <li>
                                            <img src="/assets/imgs/theme/icons/icon-email-2.svg" alt="" />
                                            <strong>Email:</strong>
                                            <span>info@combigro.nl</span>
                                        </li>
                                        <li>
                                            <img src="/assets/imgs/theme/icons/icon-clock.svg" alt="" />
                                            <strong>Hours:</strong>
                                            <br />
                                            <span>8.00 tot 17.00 uur, ma - vr</span>
                                            <br />
                                            <span>Gesloten, za</span>
                                            <br />
                                            <span>11.00 tot 12.00 uur, zo</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div
                                className="footer-link-widget col  wow animate__animated animate__fadeInUp"
                                data-wow-delay=".1s"
                            >
                                <h4 className="widget-title">Website</h4>
                                <ul className="footer-list  mb-sm-5 mb-md-0">
                                    {menu?.map((item, index) => {
                                        return (
                                            <li key={index}>
                                                <Link to={item.url_titel}>{item.titel}</Link>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                            <div
                                className="footer-link-widget col  wow animate__animated animate__fadeInUp"
                                data-wow-delay=".2s"
                            >
                                <h4 className="widget-title ">Mijn account</h4>
                                <ul className="footer-list  mb-sm-5 mb-md-0">
                                    <li>
                                        <Link to={routes.account.dashboard}>Mijn account</Link>
                                    </li>
                                    <li>
                                        <Link to={routes.account.bestellingen}>Mijn bestellingen</Link>
                                    </li>
                                    <li>
                                        <Link to={routes.favourites}>Mijn favorieten</Link>
                                    </li>
                                    <li>
                                        <Link to={routes.account.adressen}>Mijn adressen</Link>
                                    </li>
                                    <li>
                                        <Link to={routes.account.gegevens}>Mijn gegevens</Link>
                                    </li>
                                    <li>
                                        <Link to={routes.order.cart}>Mijn winkelwagen</Link>
                                    </li>
                                </ul>
                            </div>
                            <div
                                className="footer-link-widget col  wow animate__animated animate__fadeInUp"
                                data-wow-delay=".3s"
                                style={{ flex: '3 0 0%' }}
                            >
                                <h4 className="widget-title ">Producten</h4>
                                <ul className="footer-list  mb-sm-5 mb-md-0" style={{ columnCount: 3 }}>
                                    {categories?.map((item, index) => {
                                        return (
                                            <li key={index}>
                                                <Link
                                                    to={routes.products.category
                                                        .replace(':cat_id', item.id)
                                                        .replace(':cat_name', helpers.urlEncode(item.name))}
                                                >
                                                    {item.name}
                                                </Link>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="container pb-30  wow animate__animated animate__fadeInUp" data-wow-delay="0">
                    <div className="row align-items-center">
                        <div className="col-12 mb-30">
                            <div className="footer-bottom"></div>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6">
                            <p className="font-sm mb-0">
                                &copy; {(new Date()).getFullYear()}, <strong className="text-brand">{process.env.MIX_REACT_APP_APP_NAME}</strong>{' '}
                                <br />
                                All rights reserved
                            </p>
                        </div>
                        <div className="col-xl-4 col-lg-6 text-center d-none d-xl-block">
                            <div className="hotline d-lg-inline-flex mr-30">
                                <img src="/assets/imgs/theme/icons/phone-call.svg" alt="hotline" />
                                <p>
                                    1900 - 6666<span>Working 8:00 - 22:00</span>
                                </p>
                            </div>
                            <div className="hotline d-lg-inline-flex">
                                <img src="/assets/imgs/theme/icons/phone-call.svg" alt="hotline" />
                                <p>
                                    1900 - 8888<span>24/7 Support Center</span>
                                </p>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 text-end d-none d-md-block">
                            <div className="mobile-social-icon">
                                <h6>Follow Us</h6>
                                <a href="#">
                                    <img src="/assets/imgs/theme/icons/icon-facebook-white.svg" alt="" />
                                </a>
                                <a href="#">
                                    <img src="/assets/imgs/theme/icons/icon-twitter-white.svg" alt="" />
                                </a>
                                <a href="#">
                                    <img src="/assets/imgs/theme/icons/icon-instagram-white.svg" alt="" />
                                </a>
                                <a href="#">
                                    <img src="/assets/imgs/theme/icons/icon-pinterest-white.svg" alt="" />
                                </a>
                                <a href="#">
                                    <img src="/assets/imgs/theme/icons/icon-youtube-white.svg" alt="" />
                                </a>
                            </div>
                            <p className="font-sm">Up to 15% discount on your first subscribe</p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}
