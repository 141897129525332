import { ActionTypes } from "../constants/action-types"

const initialState = { items: null };

const cartReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ActionTypes.SET_CART:
            return {...state, ...payload};
        default:
            return state;
    }
}

export default cartReducer;