export default function useHelpers() {
    const formatter = new Intl.NumberFormat('nl-NL', {
        style: 'currency',
        currency: 'EUR',
    });

    function urlEncode(url) {
        if (url === undefined) return '';

        let modifiedString = url.trim().toLowerCase();
        modifiedString = modifiedString.replace(/[^a-z0-9\-\s]/gi, '');
        modifiedString = modifiedString.replace(/\s/g, '-');

        return encodeURIComponent(modifiedString);
    }

    function productImage(array, index) {
        if (array == null || array.length === 0) {
            return '/assets/imgs/shop/product-placeholder.jpg';
        }

        const url = array[index];

        if (url?.path === '' || url?.path === undefined) {
            return '/assets/imgs/shop/product-placeholder.jpg';
        }
        return url?.path;
    }

    function localImage(path, options) {
        const { width = 100, height = 100, method = 'ratio', quality = 100 } = { ...options };

        if (path == null || path.indexOf('https') > -1) return path;

        return `/api/img/local/${method}/${width}/${height}/${quality}/${path}`;
    }

    function money(double) {
        return formatter.format(double);
    }

    function getProductCurrentPrice(product, amount = false) {
        if(product === null) return null;
        
        if (product?.promotion_price_gross !== undefined) {
            if(!(amount && product?.promotion_minimum_products > 1 && amount < product?.promotion_minimum_products)) {
                return product.promotion_price_gross;
            }
        }
        if (product?.discount_price_gross !== undefined) return product.discount_price_gross;
        if (product?.price_gross !== undefined) return product.price_gross;
        return 0;
    }

    function getProductOldPrice(product, amount = false) {
        if(product === null || product.price_gross == undefined) return null;

        if (product?.promotion_price_gross !== undefined) {
            if (product?.discount_price_gross !== undefined && (amount && product?.promotion_minimum_products > 1 && amount < product?.promotion_minimum_products)) return product.discount_price_gross;
            if (product?.price_gross !== undefined) return product.price_gross;
        }
        
        if (product.discount_price_gross !== undefined) return product.price_gross;

        return null;
    }

    function getProductDiscountString(product) {
        if(product === null) return null;
        
        if (product?.promotion_price_gross !== undefined) {
            let string;
            if (product?.promotion_percentage_gross !== undefined) {
                string = `${product.promotion_percentage_gross}% korting`;
            } else {
                string = `${money(product.promotion_price_gross)} per stuk`;
            }

            if (product?.promotion_minimum_products !== undefined && product?.promotion_minimum_products > 1) {
                string += `, bij aankoop van minimaal ${product.promotion_minimum_products} producten.`;
            }
            return string;
        }

        return null;
    }

    function getMissingDiscount(product, amount) {
        if(product === null || amount === null) return null;
        
        if (product?.promotion_price_gross !== undefined) {
            if(amount && product?.promotion_minimum_products > 1 && amount < product?.promotion_minimum_products) {
                return `Je mist korting! Voeg nog ${product?.promotion_minimum_products - amount} product(en) toe en betaal slechts ${money(getProductCurrentPrice(product))} per stuk!`;
            }
        }

        return false;
    }

    return {
        urlEncode,
        productImage,
        money,
        localImage,
        getProductCurrentPrice,
        getProductOldPrice,
        getProductDiscountString,
        getMissingDiscount
    };
}
