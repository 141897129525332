import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useApiUrl from '../../../framework/hooks/useApiUrlHook';
import useRouteList from '../../../framework/hooks/useRouteList';

export default function CompanyOverview() {
    const [companies, setCompanies] = useState(null);
    const [query, setQuery] = useState('');

    const routes = useRouteList();
    const apiUrl = useApiUrl();

    useEffect(() => {
        const getCompanies = () => {
            axios
                .post(`${apiUrl}/company/admin/minified`, {
                    query: query === '' || query.length < 3 ? null : query,
                })
                .then((response) => setCompanies(response.data));
        };

        if (apiUrl !== null) getCompanies();
    }, [apiUrl, query]);

    return (
        <section className="content-main">
            <div className="content-header">
                <h2 className="content-title">Debiteuren</h2>
                <div>
                    <Link to={routes.backoffice.companies_create} className="btn btn-primary">
                        <i className="material-icons md-plus"></i> Create new
                    </Link>
                </div>
            </div>
            <div className="card mb-4">
                <header className="card-header">
                    <div className="row gx-3">
                        <div className="col-lg-4 col-md-6 me-auto">
                            <input
                                type="text"
                                placeholder="Search..."
                                className="form-control"
                                value={query}
                                onChange={(e) => setQuery(e.target.value)}
                            />
                        </div>
                    </div>
                </header>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th>Debiteurnummer</th>
                                    <th>Debiteur</th>
                                    <th>Email</th>
                                    <th>Geregistreerd op</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {companies?.map((item, index) => (
                                    <tr key={index}>
                                        <td>
                                            {item?.debtor_code}
                                        </td>
                                        <td width="40%">
                                            <Link
                                                to={routes.backoffice.companies_edit.replace(':comp_id', item.id)}
                                                className="itemside"
                                            >
                                                <div className="info pl-3">
                                                    <h6 className="mb-0 title">{item?.name}</h6>
                                                </div>
                                            </Link>
                                        </td>
                                        <td>{item.email_contact}</td>
                                        <td>{new Date(item?.created_at * 1000).toLocaleDateString('nl-NL')}</td>
                                        <td className="text-end">
                                            <Link
                                                to={routes.backoffice.companies_edit.replace(':comp_id', item.id)}
                                                className="btn btn-sm btn-brand rounded font-sm mt-15"
                                            >
                                                Debiteur bekijken
                                            </Link>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
    );
}
