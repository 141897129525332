import SwiperCore, { EffectFade, Navigation, Pagination } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import Search from "../ecommerce/Search";

SwiperCore.use([Navigation, Pagination, EffectFade]);

export default function Intro() {

    return (
        <>
            <div
                className="hero-slider-1 style-4 dot-style-1 dot-style-1-position-1"
            >
                <div>
                    <div
                        className="single-hero-slider rectangle single-animation-wrap"
                        style={{
                            backgroundImage:
                                "url(assets/imgs/slider/slider-5.png)",
                        }}
                    >
                        <div className="slider-content">
                            <h1 className="display-2 mb-40">
                                Wij hebben onze mooiste producten
                            </h1>
                            <p className="mb-65">
                                alvast voor je klaargezet
                            </p>
                            <Search header={false} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

};