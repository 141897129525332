import { useEffect } from 'react';
import { useState } from 'react';

const CheckBox = ({ filter, handleCheckBox, activeFilters }) => {
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        console.log(activeFilters);
        if(activeFilters.includes(filter?.id)) {
            setChecked(true);
        }
    }, [filter, activeFilters]);

    return (
        <div
            onClick={() => {
                handleCheckBox(filter?.id, !checked);
                setChecked(!checked);
            }}
        >
            <input type="checkbox" className="form-check-input" checked={checked} onChange={() => {setChecked(!checked)}} />
            <label className="form-check-label" htmlFor={filter?.value} style={{ textTransform: 'capitalize' }}>
                {' '}
                {filter?.name}
            </label>
            <br />
        </div>
    );
};

export default CheckBox;
