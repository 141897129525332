import axios from "axios";
import { useState, useEffect } from "react";
import { useParams } from "react-router";
import UserBasedataManager from "../../../components/backoffice/UserBasedataManager";
import useApiUrl from "../../../framework/hooks/useApiUrlHook";

export default function UserManage() {
    const [user, setUser] = useState(null);

    const { id } = useParams();
    const apiUrl = useApiUrl();

    const getUser = () => {
        axios.get(`${apiUrl}/user/admin/${id}`).then((response) => setUser(response.data));
    };

    useEffect(() => {
        if (apiUrl !== null && id !== null && id !== undefined) getUser();
    }, [apiUrl, id]);

    return (
        <section className="content-main edit">
            <div className="row">
                <div className="col-lg-8">
                    <div className="col-12">
                        <div className="content-header">
                            <h2 className="content-title">{user?.name ?? 'Nieuwe gebruiker'}</h2>
                        </div>
                    </div>

                    <UserBasedataManager user={user} callback={getUser} />

                    <hr />
                </div>
                <div className="col-lg-4">
                    <h2>Sidebar</h2>
                </div>
            </div>
        </section>
    );
}