import axios from 'axios';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import useApiUrl from './useApiUrlHook';
import { setCart as setCartUnbound } from '../../redux/actions/cartActions';

export default function useCart() {
    const apiUrl = useApiUrl();
    
    const dispatch = useDispatch();
    const setCart = bindActionCreators(setCartUnbound, dispatch);

    const getCart = () => {
        if (apiUrl !== null) {
            return axios.get(`${apiUrl}/cart`).then((response) => {
                const data = response.data;

                setCart(data);

                return data;
            });
        }
    };

    const addToCart = (prod_id, amount = 1) => {
        if (apiUrl !== null) {
            axios
                .post(`${apiUrl}/cart`, {
                    id: prod_id,
                    amount: amount,
                })
                .then((response) => {
                    toast.success('Product toegevoegd aan winkelwagen');
                    getCart();
                });
        }
    };

    const modifyQuantity = (prod_id, value) => {
        if (apiUrl !== null) {
            axios
                .put(`${apiUrl}/cart`, {
                    id: prod_id,
                    amount: value,
                })
                .then((response) => {
                    toast.success('Winkelwagen geüpdatet');
                    getCart();
                });
        }
    };

    const deleteFromCart = (prod_id) => {
        if (apiUrl !== null) {
            axios.delete(`${apiUrl}/cart/${prod_id}`).then((response) => {
                toast.info('Product verwijderd');
                getCart();
            });
        }
    };

    const clearCart = () => {
        if (apiUrl !== null) {
            axios.delete(`${apiUrl}/cart`).then((response) => {
                toast.info('Winkelwagen geleegd');
                getCart();
            });
        }
    };

    const toggleWishlist = async (productId) => {
        if (apiUrl !== null) {
            return await axios.post(`${apiUrl}/favourites/${productId}/toggle`).then((response) => {
                getCart();
                const res = response.data;

                if(res) {
                    toast.success('Product aan favorieten toegevoegd!');
                } else {
                    toast.info('Product uit favorieten verwijderd!');
                }

                return response.data;
            });
        }
    }

    return { addToCart, modifyQuantity, deleteFromCart, clearCart, getCart, toggleWishlist };
}
