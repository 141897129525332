import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import useApiUrl from '../../framework/hooks/useApiUrlHook';
import useHelpers from '../../framework/hooks/useHelpers';
import useRouteList from '../../framework/hooks/useRouteList';
import Search from '../ecommerce/Search';

const MobileMenu = ({ isToggled, toggleClick }) => {
    const [isActive, setIsActive] = useState({
        status: false,
        key: '',
    });

    const [categories, setCategories] = useState(null);

    const apiUrl = useApiUrl();
    const routes = useRouteList();
    const helpers = useHelpers();

    useEffect(() => {
        async function getCategories() {
            axios.get(`${apiUrl}/product-categories`).then((response) => {
                setCategories(response.data);
            });
        }

        if (apiUrl && categories === null) getCategories();
    }, [apiUrl]);

    const handleToggle = (key) => {
        if (isActive.key === key) {
            setIsActive({
                status: false,
                key: key,
            });
        } else {
            setIsActive({
                status: true,
                key,
            });
        }
    };

    return (
        <>
            <div
                className={
                    isToggled
                        ? 'mobile-header-active mobile-header-wrapper-style sidebar-visible'
                        : 'mobile-header-active mobile-header-wrapper-style'
                }
            >
                <div className="mobile-header-wrapper-inner">
                    <div className="mobile-header-top">
                        <div className="mobile-header-logo">
                            <a>
                                <img src="/assets/imgs/theme/logo.svg" alt="logo" />
                            </a>
                        </div>
                        <div className="mobile-menu-close close-style-wrap close-style-position-inherit">
                            <button className="close-style search-close" onClick={toggleClick}>
                                <i className="icon-top"></i>
                                <i className="icon-bottom"></i>
                            </button>
                        </div>
                    </div>
                    <div className="mobile-header-content-area">
                        <div className="mobile-search search-style-2 mobile-header-border">
                            <Search />
                        </div>
                        <div className="mobile-menu-wrap mobile-header-border">
                            <nav>
                                <ul className="mobile-menu">
                                    <li>
                                        <Link to={routes.home}>Home</Link>
                                    </li>

                                    {categories?.map((item, index) => {
                                        return (
                                            <li
                                                className={`${item.children !== null ? 'menu-item-has-children' : ''} ${
                                                    isActive.key === index ? 'active' : ''
                                                }`}
                                                key={index}
                                            >
                                                {item.children !== null ? (
                                                    <span className="menu-expand" onClick={() => handleToggle(index)}>
                                                        <i className="fi-rs-angle-small-down"></i>
                                                    </span>
                                                ) : null}
                                                <Link
                                                    to={routes.products.category
                                                        .replace(':cat_id', item.id)
                                                        .replace(':cat_name', helpers.urlEncode(item.name))}
                                                >
                                                    {item.name}
                                                </Link>
                                                {item.children !== null ? (
                                                    <ul className={isActive.key === index ? 'dropdown' : 'd-none'}>
                                                        {item.children.map((sub, subIndex) => {
                                                            return (
                                                                <li key={subIndex}>
                                                                    <Link
                                                                        to={routes.products.category
                                                                            .replace(':cat_id', sub.id)
                                                                            .replace(
                                                                                ':cat_name',
                                                                                helpers.urlEncode(sub.name),
                                                                            )}
                                                                        className="menu-title"
                                                                    >
                                                                        {sub.name}
                                                                    </Link>
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                ) : null}
                                            </li>
                                        );
                                    })}

                                    <li>
                                        <Link to={routes.products.promotions}>Aanbiedingen</Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div className="mobile-header-info-wrap mobile-header-border">
                            <div className="single-mobile-header-info mt-30">
                                <a> Our location </a>
                            </div>
                            <div className="single-mobile-header-info">
                                <a>Log In / Sign Up </a>
                            </div>
                            <div className="single-mobile-header-info">
                                <a>(+01) - 2345 - 6789 </a>
                            </div>
                        </div>
                        <div className="mobile-social-icon">
                            <h5 className="mb-15 text-grey-4">Follow Us</h5>
                            <a>
                                <img src="/assets/imgs/theme/icons/icon-facebook.svg" alt="" />
                            </a>
                            <a>
                                <img src="/assets/imgs/theme/icons/icon-twitter.svg" alt="" />
                            </a>
                            <a>
                                <img src="/assets/imgs/theme/icons/icon-instagram.svg" alt="" />
                            </a>
                            <a>
                                <img src="/assets/imgs/theme/icons/icon-pinterest.svg" alt="" />
                            </a>
                            <a>
                                <img src="/assets/imgs/theme/icons/icon-youtube.svg" alt="" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MobileMenu;
