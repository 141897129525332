import Preloader from '../components/Preloader';
import usePage from '../framework/hooks/usePageHook';
import Intro from '../components/sliders/Intro';
import { Link } from 'react-router-dom';
import CategorySlider from '../components/sliders/Category';
import CategoryTab from '../components/ecommerce/CategoryTab';
import useApiUrl from '../framework/hooks/useApiUrlHook';
import { useEffect } from 'react';
import axios from 'axios';

export default function HomePage({ page }) {
    const pageObj = usePage(page.id);

    if (!page) return <Preloader />;
    return (
        <>
            <section className="home-slider position-relative mb-30">
                <div className="home-slide-cover" style={{zIndex: 10}}>
                    <Intro />
                </div>
            </section>
            <section className="product-tabs section-padding position-relative">
                <div className="container">
                    <div className="col-lg-12">
                        <CategoryTab />
                    </div>
                </div>
            </section>
        </>
    );
}
