import { Link } from 'react-router-dom';
import ProductsTable from '../../../components/backoffice/productsTable/ProductsTable';
import useRouteList from '../../../framework/hooks/useRouteList';

export default function ProductOverview() {
    const routes = useRouteList();

    return (
        <section className="content-main">
            <div className="content-header">
                <div>
                    <h2 className="content-title card-title">Productoverzicht</h2>
                    <p>Alle producten in het assoritment</p>
                </div>
                <div>
                    <Link to={routes.backoffice.products_create} className="btn btn-primary btn-sm rounded">
                        Nieuw artikel
                    </Link>
                </div>
            </div>
            <ProductsTable />
        </section>
    );
}
