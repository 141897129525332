import React, { useState } from 'react';
import ApolloMarkdown from '../../framework/components/ApolloMarkdown';

const ProductTab = ({ product }) => {
    const [activeIndex, setActiveIndex] = useState(1);

    const handleOnClick = (index) => {
        setActiveIndex(index);
    };

    if( !(product?.description?.length > 0) && !(product?.ingredients?.length > 0)) return null;

    return (
        <div className="product-info">
            <div className="tab-style3">
                <ul className="nav nav-tabs text-uppercase">
                    {product?.description?.length > 0 ? (
                        <li className="nav-item">
                            <a
                                className={activeIndex === 1 ? 'nav-link active' : 'nav-link'}
                                id="Description-tab"
                                data-bs-toggle="tab"
                                onClick={() => handleOnClick(1)}
                            >
                                Productomschrijving
                            </a>
                        </li>
                    ) : null}
                    {product?.ingredients?.length > 0 ? (
                        <li className="nav-item">
                            <a
                                className={activeIndex === 2 ? 'nav-link active' : 'nav-link'}
                                id="Ingredients-info-tab"
                                data-bs-toggle="tab"
                                onClick={() => handleOnClick(2)}
                            >
                                Ingrediënten
                            </a>
                        </li>
                    ) : null}
                </ul>
                <div className="tab-content shop_info_tab entry-main-content">
                    {product?.description?.length > 0 ? (
                        <div
                            className={activeIndex === 1 ? 'tab-pane fade show active' : 'tab-pane fade'}
                            id="Description"
                        >
                            <div className="">
                                <ApolloMarkdown inline={false}>{product?.description}</ApolloMarkdown>
                            </div>
                        </div>
                    ) : null}
                    {product?.ingredients?.length > 0 ? (
                        <div
                            className={activeIndex === 2 ? 'tab-pane fade show active' : 'tab-pane fade'}
                            id="Additional-info"
                        >
                            <div className="">
                                <ApolloMarkdown inline={false}>{product?.ingredients}</ApolloMarkdown>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default ProductTab;
