import { Link } from 'react-router-dom';

export default function NotFoundPage() {
    return (
        <main className="main page-404">
            <div className="page-content pt-150 pb-150">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-10 col-md-12 m-auto text-center">
                            <p className="mb-20">
                                <img src="assets/imgs/page/page-404.png" alt="" className="hover-up" />
                            </p>
                            <h1 className="display-2 mb-30">Pagina niet gevonden</h1>
                            <p className="font-lg text-grey-700 mb-30">
                                De link waarop u hebt geklikt, is mogelijk verbroken of de pagina is mogelijk verwijderd.
                                <br />
                                Ga terug naar de {' '}
                                <Link to="/">
                                    {' '}
                                    <span> homepagina</span>
                                </Link>.
                            </p>
                            <Link to="/" className="btn btn-default submit-auto-width font-xs hover-up mt-30">
                                <i className="fi-rs-home mr-5"></i> Terug naar home
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}
