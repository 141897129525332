import axios from 'axios';
import { useEffect, useState } from 'react';
import useApiUrl from './useApiUrlHook';

export default function useMenu() {
    const [menu, setMenu] = useState(null);
    const apiUrl = useApiUrl();

    useEffect(() => {
        async function getMenu() {
            if (apiUrl === null) return;

            await axios
                .get(`${apiUrl}/page/menu`)
                .then((response) => {
                    setMenu(response.data);
                })
                .catch((error) => {
                    throw new Error(error);
                });
        }
        if (menu === null) {
            getMenu();
        }
    }, [menu, apiUrl]);

    return menu;
}
