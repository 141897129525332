import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import StatusPill from "../../../components/backoffice/orders/StatusPill";
import useApiUrl from "../../../framework/hooks/useApiUrlHook";
import useHelpers from "../../../framework/hooks/useHelpers";
import useRouteList from "../../../framework/hooks/useRouteList";
import OrderDetailTab from "./OrderDetailTab";

export default function AccountOrdersTab() {
    const [orders, setOrders] = useState([]);

    const apiUrl = useApiUrl();
    const routes = useRouteList();
    const {money} = useHelpers();

    const { id: orderId } = useParams();


    useEffect(() => {
        if(apiUrl !== null && orders.length === 0) {
            axios.get(`${apiUrl}/order`)
                .then((response) => setOrders(response.data))
                .catch(() => toast.error('Het is niet gelukt om de order op te halen'));
        }
    }, [apiUrl]);

    if(orderId) return <OrderDetailTab />;

    return (
        <div className="card">
            <div className="card-header">
                <h3 className="mb-0">Mijn bestellingen</h3>
            </div>
            <div className="card-body">
                <div className="table-responsive">
                    <table className="table orderOverviewTable">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Besteldatum</th>
                                <th>Leverdatum</th>
                                <th>Status</th>
                                <th>Totaal (excl)</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orders?.length ?
                                orders.map((item, index) => <tr key={index}>
                                    <td>#{item.id}</td>
                                    <td>{new Date(item?.created_at * 1000).toLocaleDateString('nl-NL')}</td>
                                    <td>{new Date(item?.delivery_date).toLocaleDateString('nl-NL')}</td>
                                    <td><StatusPill statusHistory={item?.status} /></td>
                                    <td>{money(item?.total_gross)}</td>
                                    <td><Link to={routes.account.bestelling_detail.replace(":order_id", item.id)}>Bekijk</Link></td>
                                </tr>)
                            : null}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}
