import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import useApiUrl from '../../../framework/hooks/useApiUrlHook';
import useRouteList from '../../../framework/hooks/useRouteList';

function Column({ title, field }) {
    return <td data-for={field}>{title}</td>;
}

export default function CategoriesTable({forceUpdate}) {
    const [results, setResults] = useState(null);
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const [query, setQuery] = useState('');
    const [amount, setAmount] = useState(100);

    const routes = useRouteList();
    const apiUrl = useApiUrl();

    const { sub } = useParams();

    useEffect(() => {
        if (apiUrl !== null) {
            if(sub !== null && sub !== "" && sub !== undefined) {
                axios
                    .get(`${apiUrl}/product-categories/breadcrumbs${sub ? '/' + sub : ''}`)
                    .then((response) => setBreadcrumbs(response.data));
            } else {
                setBreadcrumbs([]);
            }

            axios
                .get(`${apiUrl}/product-categories/admin${sub ? '/' + sub : ''}`)
                .then((response) => setResults(response.data));
        }
    }, [apiUrl, query, amount, sub, forceUpdate]);

    const columns = [
        { title: 'ID', field: 'id' },
        { title: 'Naam', field: 'name' },
        { title: '' },
    ];

    return (
        <>
            <div className="breadcrumbs">
                <Link to={routes.backoffice.product_categories}>Alle</Link> - 
                {breadcrumbs.map((item, index) => {
                    return (
                        <Link key={index} to={routes.backoffice.product_categories_edit.replace(':cat_id', item.id)}>{item.name}</Link>
                    )
                })}
            </div>
            <table>
                <thead>
                    <tr>
                        {columns.map((column, i) => (
                            <Column key={i} {...column} />
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {results?.map((item, i) => {
                        return (
                            <tr key={i}>
                                {columns.map((column, key) => {
                                    if (column.fn !== undefined) {
                                        return <td key={key}>{column.fn(item)}</td>;
                                    } else if (column.field !== undefined) {
                                        return <td key={key}>{item[column.field]}</td>;
                                    }
                                })}
                                <td>
                                    <Link to={routes.backoffice.product_categories_edit.replace(':cat_id', item.id)}>Edit</Link>
                                </td>
                            </tr>
                        );
                    }) ?? 'Loading...'}
                </tbody>
            </table>
        </>
    );
}
