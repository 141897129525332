import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import CategoriesTable from '../../../components/backoffice/categoriesTable/CategoriesTable';
import EditCategoryForm from '../../../components/backoffice/categoriesTable/EditCategoryForm';
import NewCategoryForm from '../../../components/backoffice/categoriesTable/NewCategoryForm';
import useApiUrl from '../../../framework/hooks/useApiUrlHook';
import useRouteList from '../../../framework/hooks/useRouteList';

export default function ProductCategoryOverview() {
    const [category, setCategory] = useState(null);
    const [updates, setUpdates] = useState(0);

    const routes = useRouteList();
    const { sub } = useParams();
    const apiUrl = useApiUrl();

    const getCategory = () => {
        if (sub === undefined) setCategory(null);

        axios.get(`${apiUrl}/product-categories/admin/${sub}/info`).then((response) => setCategory(response.data));

        setUpdates(updates + 1);
    };

    useEffect(() => {
        if (apiUrl !== null) getCategory();
    }, [sub, apiUrl]);

    return (
        <section className="content-main">
            <div className="content-header">
                <div>
                    <h2 className="content-title card-title">Productcategorieën</h2>
                </div>
                <div>
                    <Link to={routes.backoffice.products_create} className="btn btn-primary btn-sm rounded">
                        Nieuw artikel
                    </Link>
                </div>
            </div>
            <div className="card mb-4">
                <div className="card-body">
                    {sub !== undefined ? <h2>{category?.name}</h2> : null}
                    <CategoriesTable forceUpdate={updates}/>
                </div>
            </div>
            {!sub ? null : (
                <EditCategoryForm id={sub} category={category} callback={getCategory} />
            )}
            <NewCategoryForm parent={sub} category={category} callback={getCategory} />
        </section>
    );
}
