import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import useApiUrl from '../../../framework/hooks/useApiUrlHook';

export default function AssignProductSelector({
    // title = '',
    storedCompanyUsers = [],
    changeCallback = (companyUsers) => {},
    // text = ''
}) {
    const [query, setQuery] = useState('');
    const [companyUsers, setCompanyUsers] = useState(null);
    const [results, setResults] = useState([]);

    const apiUrl = useApiUrl();

    useEffect(() => {
        if (query !== null) {
            if (query === '') {
                setResults([]);
            } else {
                axios
                    .post(`${apiUrl}/user/admin/searchUncoupled`, {
                        query,
                        exclude: companyUsers?.map((alt) => alt.id),
                    })
                    .then((response) => setResults(response.data));
            }
        }
    }, [query, companyUsers]);

    useEffect(() => changeCallback(companyUsers), [companyUsers]);

    useEffect(() => {
        if (companyUsers === null) {
            setCompanyUsers(storedCompanyUsers);
        }
    }, [storedCompanyUsers]);

    const handleAddAlternative = (user) => setCompanyUsers([...companyUsers, user]);

    const handleRemoveAlternative = (user) => {
        var updatedList = [...companyUsers];

        const index = updatedList.findIndex((item) => item.id === user);

        if (index > -1) {
            updatedList.splice(index, 1);
            setCompanyUsers(updatedList);
        }
    };

    return (
        <div className="listmember card mb-4">
            <div className="card-header">
                <h4>Gekoppelde gebruikers</h4>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-lg-6">
                        <label className="form-label">Zoek gebruiker</label>
                        <div className="row gx-2">
                            <input
                                type="text"
                                placeholder="Gebruiker..."
                                className="form-control"
                                onChange={(e) => setQuery(e.target.value)}
                                value={query}
                            />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="titleCol">Gekoppelde gebruikers:</div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="mb-4">
                            <div className="results">
                                {results?.map((curUser, index) => {
                                    return (
                                        <div className="resultRow" key={index}>
                                            <div className="code">{curUser.item_number}</div>
                                            <div className="title">
                                                {curUser.name}, {curUser.email}
                                            </div>
                                            <div className="action" onClick={() => handleAddAlternative(curUser)}>
                                                +
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="mb-4">
                            <div className="results">
                                {companyUsers?.map((curUser, index) => {
                                    return (
                                        <div className="resultRow" key={index}>
                                            <div className="title">
                                                {curUser.name}, {curUser.email}
                                            </div>
                                            <div className="action" onClick={() => handleRemoveAlternative(curUser.id)}>
                                                X
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
