import axios from 'axios';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import StatusPill from '../../../components/backoffice/orders/StatusPill';
import Invoice from '../../../components/ecommerce/Invoice';
import useApiUrl from '../../../framework/hooks/useApiUrlHook';
import useHelpers from '../../../framework/hooks/useHelpers';
import useRouteList from '../../../framework/hooks/useRouteList';

import './scss/orderDetailTab.scss';

export default function OrderDetailTab() {
    const { id: orderId } = useParams();
    const [order, setOrder] = useState(null);

    const apiUrl = useApiUrl();
    const routes = useRouteList();
    const { money, localImage, urlEncode, productImage } = useHelpers();

    useEffect(() => {
        const getOrder = () => {
            axios.get(`${apiUrl}/order/${orderId}/get`).then((response) => setOrder(response.data));
        };

        if (apiUrl !== null && orderId !== null) getOrder();
    }, [apiUrl, orderId]);

    return (
        <>
            <h2 className="mb-4">
                Bestelling <span className="text-brand">{order?.id}</span>
            </h2>
            <table className="table orderTable">
                <thead>
                    <tr>
                        <td width={'40%'}>Product</td>
                        <td width={'20%'}>Stuksprijs</td>
                        <td width={'20%'}>Aantal</td>
                        <td width={'20%'} className="text-end">
                            Subtotaal
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {order?.order_lines?.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>
                                    <Link
                                        target="_blank"
                                        className="itemside"
                                        to={routes.products.detail
                                            .replace(':prod_id', item?.product?.id)
                                            .replace(':prod_name', urlEncode(item?.product?.name))}
                                    >
                                        <div className="left">
                                            {item?.product?.images === undefined || item?.product?.images.length < 1 ? (
                                                <div style={{ width: '50px', height: '50px' }}></div>
                                            ) : (
                                                <img
                                                    src={localImage(productImage(item?.product?.images, 0), {
                                                        width: 50,
                                                        height: 50,
                                                    })}
                                                    width="40"
                                                    height="40"
                                                    className="img-xs"
                                                    alt="Item"
                                                />
                                            )}
                                        </div>
                                        <div className="info">
                                            <span>{item?.name}</span>
                                            <br />
                                            <small>#{item?.item_number}</small>
                                        </div>
                                    </Link>
                                </td>
                                <td>{money(item?.price_single_gross)}</td>
                                <td>{item?.amount}</td>
                                <td className="text-end">{money(item?.price_total_gross)}</td>
                            </tr>
                        );
                    })}

                    <tr>
                        <td colSpan={4}>
                            <article className="float-end">
                                <dl className="dlist">
                                    <dt>Subtotaal:</dt>
                                    <dd>{money(order?.total_gross)}</dd>
                                </dl>
                                <dl className="dlist">
                                    <dt>BTW:</dt>
                                    <dd>{money(order?.total_net - order?.total_gross)}</dd>
                                </dl>
                                <dl className="dlist">
                                    <dt>Totaal:</dt>
                                    <dd>
                                        <b className="h5">{money(order?.total_net)}</b>
                                    </dd>
                                </dl>
                                <dl className="dlist">
                                    <dt className="text-muted">Status:</dt>
                                    <dd>
                                        <StatusPill statusHistory={order?.status} />
                                    </dd>
                                </dl>
                            </article>
                        </td>
                    </tr>
                </tbody>
            </table>
            <Invoice order={order} />
        </>
    );
}
