import axios from 'axios';
import { useEffect } from 'react';
import useApiUrl from '../../framework/hooks/useApiUrlHook';
import SingleProduct from './SingleProduct';

import { useDispatch, useSelector } from 'react-redux';
import { setProducts as setProductsUnbound } from '../../redux/actions/productActions';

import '../../views/catalog/scss/products.scss';
import { bindActionCreators } from 'redux';

export default function CategoryTab() {
    const { products } = useSelector((state) => state);

    const dispatch = useDispatch();

    const setProducts = bindActionCreators(setProductsUnbound, dispatch);

    const apiUrl = useApiUrl();

    useEffect(() => {
        async function getProducts() {
            await axios.get(`${apiUrl}/products`).then((response) => {
                setProducts({ list: response.data, type: "home" });
            });
        }

        if (apiUrl && products.type !== "home") getProducts();
    }, [apiUrl]);

    return (
        <>
            <div className="section-title style-2 wow animate__animated animate__fadeIn">
                <h3>Populaire producten</h3>
            </div>

            <div className="row product-grid-3">
                {products?.list?.map((product, i) => (
                    <div className="col-lg-1-5 col-md-4 col-12 col-sm-6" key={i}>
                        <SingleProduct
                            product={product}
                            addToCart={undefined}
                            addToCompare={undefined}
                            addToWishlist={undefined}
                            openQuickView={undefined}
                        />
                    </div>
                ))}
            </div>
        </>
    );
}
