import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import AddressManager from '../../../components/backoffice/components/AddressManager';
import AssignProductSelector from '../../../components/backoffice/products/AssignProductSelector';
import CompanyBasedataManager from '../../../components/backoffice/companies/CompanyBasedataManager';
import CompanyInvoiceAddressManager from '../../../components/backoffice/companies/CompanyInvoiceAddressManager';
import CompanyUserSelector from '../../../components/backoffice/companies/CompanyUserSelector';
import useApiUrl from '../../../framework/hooks/useApiUrlHook';
import DeliveryDateSelector from '../../../components/backoffice/components/DeliveryDateSelector';

import '../scss/edit.scss';

export default function CompanyManage() {
    const [company, setCompany] = useState(null);
    const [deliveryAddresses, setDeliveryAdresses] = useState(null);
    const [invoiceAddress, setInvoiceAdress] = useState(null);

    const { id } = useParams();
    const apiUrl = useApiUrl();

    const getCompany = (companyId = null) => {
        axios.get(`${apiUrl}/company/admin/${id}`).then((response) => setCompany(response.data));
    };

    useEffect(() => {
        if (apiUrl !== null && id !== null && id !== undefined) getCompany();
    }, [apiUrl, id]);

    useEffect(() => {
        const invoice = company?.addresses?.find((a) => a.type === 'invoice');
        if (invoice) {
            setInvoiceAdress(invoice);
        } else {
            setInvoiceAdress(null);
        }

        const delivery = company?.addresses?.filter((a) => a.type === 'delivery');
        if (delivery) {
            setDeliveryAdresses(delivery);
        } else {
            setDeliveryAdresses(null);
        }
    }, [company]);

    const handleUpdateFavourites = (data) => {
        if (!company || company == null) return;

        const favourites = data?.map((i) => i.id);
        const prevFavourites = company?.favourites?.map((i) => i.product.id);

        if (
            favourites !== undefined &&
            JSON.stringify(favourites) != JSON.stringify(prevFavourites) &&
            company !== null
        ) {
            axios.post(`${apiUrl}/favourites/admin/${company.id}/overwrite`, { favourites }).then(() => {
                toast('Favorieten zijn bijgewerkt!');
            });
        }
    };

    const handleUpdateUsers = (data) => {
        if (!company || company == null) return;

        const users = data?.map((i) => i.id);
        const prevUsers = company?.users?.map((i) => i.id);

        if (
            users !== undefined &&
            JSON.stringify(users) != JSON.stringify(prevUsers) &&
            company !== null
        ) {
            axios.post(`${apiUrl}/user/admin/${company.id}/updateCompany`, { users }).then(() => {
                toast('Gebruikers zijn bijgewerkt!');
            });
        }
    }

    return (
        <section className="content-main edit">
            <div className="row">
                <div className="col-lg-8">
                    <div className="col-12">
                        <div className="content-header">
                            <h2 className="content-title">{company?.name ?? 'Nieuw bedrijf'}</h2>
                        </div>
                    </div>

                    <CompanyBasedataManager company={company} callback={getCompany} />

                    {company == null ? (
                        'Vul stamgegevens in om door te gaan'
                    ) : (
                        <>
                            <CompanyInvoiceAddressManager
                                companyId={company?.id}
                                address={invoiceAddress}
                                callback={getCompany}
                            />

                            <AddressManager
                                title="Leveradressen"
                                companyId={company?.id}
                                addresses={deliveryAddresses}
                                callback={getCompany}
                            />

                            <AssignProductSelector
                                title="Favorieten"
                                productAlternatives={company?.favourites?.map((c) => c.product)}
                                changeCallback={handleUpdateFavourites}
                                newProduct={false}
                                text={'Favorieten worden automatisch opgeslagen'}
                            />

                            <CompanyUserSelector
                                storedCompanyUsers={company?.users}
                                changeCallback={handleUpdateUsers}
                            />
                        </>
                    )}

                    <hr />
                </div>
                <div className="col-lg-4">
                    {company == null ? (
                        ''
                    ) : (
                        <DeliveryDateSelector
                            companyId={company?.id}
                            deliveryDates={company?.delivery_days}
                            callback={getCompany}
                        />
                    )}
                </div>
            </div>
        </section>
    );
}
