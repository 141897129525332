import { useState } from 'react';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import useAuth from '../hooks/useAuth';
import useRouteList from '../hooks/useRouteList';

export default function ApolloProtected({ role, children }) {
    const [allowed, setAllowed] = useState(false);

    const auth = useAuth();
    const routes = useRouteList();

    const navigate = useNavigate();

    useEffect(() => {
        if (auth.user && auth.user.role_id >= role) {
            setAllowed(true);
        }
    }, [auth, auth.user]);

    if (!auth.user && auth.error === null) return 'Authenticating user';
    
    if (allowed) return children;

    if(auth.user == null) {
        window.localStorage.setItem('referrer', window.location.href);
        
        navigate(routes.login);
        
        setTimeout(() => {
            toast.warning('Je moet ingelogd zijn om deze pagina te bekijken. Log in!');
        }, 500);
    }


    return (
        <>
            Kon gebruiker niet authenticeren, <Link to={routes.login}>log in.</Link>
        </>
    );
}
