import { useEffect } from 'react';
import { useState } from 'react';

export default function Pagination({ totalPages = 1, currentClientPage = 1, callback }) {
    const [pageList, setPageList] = useState([]);

    useEffect(() => {
        const pageArray = [];

        for (let i = 1; i < totalPages; i++) {
            pageArray.push(
                <li
                    key={i}
                    onClick={() => callback(i)}
                    className={currentClientPage === i ? 'page-item active' : 'page-item'}
                >
                    <a className="page-link">{i}</a>
                </li>,
            );
        }

        setPageList(pageArray);
    }, [totalPages, totalPages, currentClientPage]);

    return (
        <>
            <ul className="pagination justify-content-start">
                {pageList.length <= 1 ? null : (
                    <li onClick={() => callback('-1')} className="page-item">
                        {currentClientPage === 1 ? null : (
                            <a className="page-link">
                                <i className="fi-rs-angle-double-small-left"></i>
                            </a>
                        )}
                    </li>
                )}

                {pageList}

                {pageList.length <= 1 ? null : (
                    <li onClick={() => callback('+1')} className="page-item">
                        {currentClientPage >= totalPages ? null : (
                            <div className="page-link">
                                <i className="fi-rs-angle-double-small-right"></i>
                            </div>
                        )}
                    </li>
                )}
            </ul>
        </>
    );
}
