import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import useAuth from '../../../framework/hooks/useAuth';
import useRouteList from '../../../framework/hooks/useRouteList';
import AccountAddressesTab from './AccountAddressesTab';
import AccountHomeTab from './AccountHomeTab';
import AccountOrdersTab from './AccountOrdersTab';
import AccountSettingsTab from './AccountSettingsTab';

export default function Account() {
    const [activeIndex, setActiveIndex] = useState(1);

    const { route } = useParams();
    const routes = useRouteList();
    const auth = useAuth();

    useEffect(() => {
        const subPage = subPages.find((item) => item.slug === route);

        if (subPage) {
            setActiveIndex(subPage.index);
        }
    }, [route]);

    const subPages = [
        { index: 1, slug: undefined },
        { index: 2, slug: 'bestellingen' },
        { index: 3, slug: 'adressen' },
        { index: 4, slug: 'gegevens' },
    ];

    return (
        <>
            <div className="page-content pt-150 pb-150">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 m-auto">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="dashboard-menu">
                                        <ul className="nav flex-column" role="tablist">
                                            <li className="nav-item">
                                                <Link
                                                    to={routes.account.dashboard}
                                                    className={activeIndex === 1 ? 'nav-link active' : 'nav-link'}
                                                >
                                                    <i className="fi-rs-settings-sliders mr-10"></i>Home
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link
                                                    to={routes.account.bestellingen}
                                                    className={activeIndex === 2 ? 'nav-link active' : 'nav-link'}
                                                >
                                                    <i className="fi-rs-shopping-bag mr-10"></i>Mijn bestellingen
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link
                                                    to={routes.account.adressen}
                                                    className={activeIndex === 3 ? 'nav-link active' : 'nav-link'}
                                                >
                                                    <i className="fi-rs-location-alt mr-10"></i>Mijn adressen
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link
                                                    to={routes.account.gegevens}
                                                    className={activeIndex === 5 ? 'nav-link active' : 'nav-link'}
                                                >
                                                    <i className="fi-rs-user mr-10"></i>Mijn gegevens
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <a onClick={auth.logout} className="nav-link">
                                                    <i className="fi-rs-sign-out mr-10"></i>Log uit
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-9">
                                    <div className="tab-content account dashboard-content pl-50">
                                        <div
                                            className='tab-pane fade active show'
                                        >
                                            { activeIndex === 1 ? <AccountHomeTab /> : null }
                                            { activeIndex === 2 ? <AccountOrdersTab /> : null }
                                            { activeIndex === 3 ? <AccountAddressesTab /> : null }
                                            { activeIndex === 4 ? <AccountSettingsTab /> : null }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
