import axios from 'axios';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import useApiUrl from '../../../framework/hooks/useApiUrlHook';

export default function CompanyInvoiceAddressManager({ address, companyId, callback }) {
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();
    const apiUrl = useApiUrl();

    useEffect(() => {
        if (address) {
            setValue('name', address?.name);
            setValue('telephone', address?.telephone);
            setValue('street_address', address?.street_address);
            setValue('postal_code', address?.postal_code);
            setValue('city', address?.city);
            setValue('administrative_area', address?.administrative_area);
            setValue('country', address?.country);
        }
    }, [address]);

    const onSubmit = (data) => {
        axios
            .post(`${apiUrl}/address/${address ? address.id : companyId }/${address ? 'update' : 'add'}`, {
                type: 'invoice',
                ...address,
                ...data,
            })
            .then(() => {
                if(address) {
                    toast('Adres bijgewerkt!');
                } else {
                    toast('Adres opgeslagen!');
                }
                callback(data);
            });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="card mb-4">
                <div className="card-header">
                    <h4>Factuuradres</h4>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="mb-4">
                                <label className="form-label">
                                    Adres naam
                                    <input
                                        placeholder="Factuuradres"
                                        className="form-control"
                                        {...register('name', {})}
                                    />
                                </label>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="mb-4">
                                <label className="form-label">
                                    Telefoonnummer
                                    <input
                                        placeholder="Overijssel"
                                        className="form-control"
                                        {...register('telephone', {})}
                                    />
                                </label>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="mb-4">
                                <label className="form-label">
                                    Straat en huisnummer
                                    <input
                                        placeholder="Schoolstraat 1"
                                        className="form-control"
                                        {...register('street_address', {})}
                                    />
                                </label>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="mb-4">
                                <label className="form-label">
                                    Postcode
                                    <input
                                        placeholder="1234 AB"
                                        className="form-control"
                                        {...register('postal_code', {})}
                                    />
                                </label>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="mb-4">
                                <label className="form-label">
                                    Plaats
                                    <input
                                        placeholder="Albasserdam"
                                        className="form-control"
                                        {...register('city', {})}
                                    />
                                </label>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="mb-4">
                                <label className="form-label">
                                    Provincie
                                    <input
                                        placeholder="Overijssel"
                                        className="form-control"
                                        {...register('administrative_area', {})}
                                    />
                                </label>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="mb-4">
                                <label className="form-label">
                                    Land
                                    <select className="form-control" {...register('country', {})}>
                                        <option value="NL">NL</option>
                                        <option value="DE">DE</option>
                                        <option value="BE">BE</option>
                                    </select>
                                </label>
                            </div>
                        </div>

                        <div className="col-lg-12">
                            <button type="submit" className="btn btn-md rounded font-sm hover-up">
                                Opslaan
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}
