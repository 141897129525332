import { ActionTypes } from "../constants/action-types";

export const setCart = (cart) => {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.SET_CART,
            payload: cart
        });
    }
}
