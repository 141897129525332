import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import useApiUrl from '../../../framework/hooks/useApiUrlHook';
import useAuth from '../../../framework/hooks/useAuth';

export default function AccountSettingsTab() {
    const [data, setData] = useState({});
    const [password, setPassword] = useState({});

    const { user, logout, updatePassword, updateDetails } = useAuth();
    const apiUrl = useApiUrl();

    useEffect(() => {
        setData(user);
    }, [user]);

    const handleUpdateDetails = () => {
        updateDetails(data);
    };

    const handleUpdatePassword = () => {
        updatePassword(password?.current_password, password?.password, password?.password_confirmation);
    };

    return (
        <div className="card">
            <div className="card-header">
                <h5>Accountgegevens aanpassen</h5>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="form-group col-md-12">
                        <label>Voor- en achternaam</label>
                        <input
                            className="form-control"
                            name="dname"
                            type="text"
                            value={data?.name}
                            onChange={(e) => setData({ ...data, name: e.target.value })}
                        />
                    </div>
                    <div className="form-group col-md-12">
                        <label>Email Adres</label>
                        <input
                            className="form-control"
                            name="email"
                            type="email"
                            value={data?.email}
                            onChange={(e) => setData({ ...data, email: e.target.value })}
                        />
                    </div>
                    <div className="col-md-12">
                        <button
                            onClick={handleUpdateDetails}
                            className="btn btn-fill-out submit font-weight-bold mb-24"
                        >
                            Gegevens bijwerken
                        </button>
                    </div>
                    <hr />
                    <h5>Wachtwoord aanpassen</h5>
                    <div className="form-group col-md-12">
                        <label>
                            Huidig wachtwoord <span className="required">*</span>
                        </label>
                        <input
                            className="form-control"
                            name="password"
                            value={password?.current_password}
                            onChange={(e) => setPassword({ ...password, current_password: e.target.value })}
                            type="password"
                        />
                    </div>
                    <div className="form-group col-md-12">
                        <label>
                            Nieuw wachtwoord <span className="required">*</span>
                        </label>
                        <input
                            className="form-control"
                            name="password"
                            value={password?.password}
                            onChange={(e) => setPassword({ ...password, password: e.target.value })}
                            type="password"
                        />
                    </div>
                    <div className="form-group col-md-12">
                        <label>
                            Bevestig nieuw wachtwoord <span className="required">*</span>
                        </label>
                        <input
                            className="form-control"
                            name="password_confirmation"
                            value={password?.password_confirmation}
                            onChange={(e) => setPassword({ ...password, password_confirmation: e.target.value })}
                            type="password"
                        />
                    </div>
                    <div className="col-md-12">
                        <button onClick={handleUpdatePassword} className="btn btn-fill-out submit font-weight-bold">
                            Nieuw wachtwoord opslaan
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
