import axios from 'axios';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import useApiUrl from '../../../framework/hooks/useApiUrlHook';

export default function NewCategoryForm({ parent, category, callback }) {
    const apiUrl = useApiUrl();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    const onSubmit = (data) => {
        axios.post(`${apiUrl}/product-categories/admin/add`, {
            name: data.category_title,
            parent: parent ?? 0
        }).then(() => {
            toast(`Categorie '${data.category_title}' toegevoegd`);
            if(callback) callback();
            reset();
        });
    }

    return (
        <div className="card mb-4">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="card-body">
                    <h3>Nieuwe {category ? `subcategorie van ${category?.name}` : 'categorie'}</h3>
                    <div className="mb-4">
                        <label className="form-label">Categorietitel</label>
                        <div className="row gx-2">
                            <input
                                type="text"
                                placeholder="Categorietitel..."
                                className="form-control"
                                {...register('category_title', {})}
                            />
                        </div>
                    </div>
                    <button className="btn btn-primary btn-sm rounded">Toevoegen</button>
                </div>
            </form>
        </div>
    );
}
