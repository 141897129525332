import { CookieConsentProvider } from '@use-cookie-consent/react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Helmet } from 'react-helmet';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ForgotPasswordpage from '../views/authentication/ForgotPasswordPage';
import LoginPage from '../views/authentication/LoginPage';
import RegisterPage from '../views/authentication/RegisterPage';
import ResetPasswordPage from '../views/authentication/ResetPasswordPage';
import CompanyManage from '../views/backoffice/companies/CompanyManage';
import CompanyOverview from '../views/backoffice/companies/CompanyOverview';
import OrderManage from '../views/backoffice/orders/OrderManage';
import OrderOverview from '../views/backoffice/orders/OrderOverview';
import ProductCategoryOverview from '../views/backoffice/productCategories/ProductCategoryOverview';
import ProductManage from '../views/backoffice/products/ProductManage';
import ProductOverview from '../views/backoffice/products/ProductOverview';
import PromotionsOverview from '../views/backoffice/promotions/PromotionsOverview';
import UserManage from '../views/backoffice/users/UserManage';
import UserOverview from '../views/backoffice/users/UserOverview';
import CategoryPage from '../views/catalog/CategoryPage';
import ProductPage from '../views/catalog/product/ProductPage';
import PromotionsPage from '../views/catalog/PromotionsPage';
import AccountPage from '../views/shop/account/AccountPage';
import OrderDetailPage from '../views/shop/account/OrderDetailTab';
import CartPage from '../views/shop/cartPage';
import CheckoutPage from '../views/shop/CheckoutPage';
import ConfirmationPage from '../views/shop/ConfirmationPage';
import FavouritesPage from '../views/shop/FavouritesPage';
import PaymentPage from '../views/shop/PaymentPage';
import TransactionEndPage from '../views/shop/TransactionEndPage';
import ApolloCookieConsent from './components/ApolloCookieConsent';
import ApolloProtected from './components/ApolloProtected';
import useRolesList from './hooks/useRolesList';
import ResolvePage from './ResolvePage';

export default function Apollo() {
    const roles = useRolesList();
    return (
        <GoogleReCaptchaProvider reCaptchaKey={process.env.MIX_REACT_APP_RECAPTCHA}>
            <BrowserRouter>
                <CookieConsentProvider>
                    <Helmet>
                        <title>{process.env.MIX_REACT_APP_APP_NAME}</title>
                    </Helmet>
                    <Routes>
                        <Route path="/">
                            <Route index element={<ResolvePage children={undefined} />} />

                            {/* Project specific routes */}
                            <Route path="account">
                                <Route path="login" element={<ResolvePage><LoginPage /></ResolvePage>}/>
                                <Route path="register" element={<ResolvePage><RegisterPage /></ResolvePage>}/>

                                <Route path="passwordreset">
                                    <Route index element={<ResolvePage><ForgotPasswordpage /></ResolvePage>}/>
                                    <Route path=":token/:email" element={<ResolvePage><ResetPasswordPage /></ResolvePage>}/>
                                </Route>
                                <Route path="dashboard">
                                    <Route index element={<ApolloProtected role={roles.debtor}><ResolvePage><AccountPage /></ResolvePage></ApolloProtected>} />
                                    <Route path=':route' element={<ApolloProtected role={roles.debtor}><ResolvePage><AccountPage /></ResolvePage></ApolloProtected>} />
                                    <Route path=':route/:id' element={<ApolloProtected role={roles.debtor}><ResolvePage><AccountPage /></ResolvePage></ApolloProtected>} />
                                </Route>
                            </Route>

                            <Route path='bestellen'>
                                <Route path='winkelwagen' element={<ApolloProtected role={roles.debtor}><ResolvePage><CartPage /></ResolvePage></ApolloProtected>} />
                                <Route path='gegevens' element={<ApolloProtected role={roles.debtor}><ResolvePage><CheckoutPage /></ResolvePage></ApolloProtected>} />
                                <Route path='betaling/:transactionId' element={<ApolloProtected role={roles.debtor}><ResolvePage><PaymentPage /></ResolvePage></ApolloProtected>} />
                                <Route path='bedankt/:orderId' element={<ApolloProtected role={roles.debtor}><ResolvePage><ConfirmationPage /></ResolvePage></ApolloProtected>} />
                                <Route path='transactionEnd' element={<TransactionEndPage/>} />
                            </Route>

                            <Route path='favorieten' element={<ApolloProtected role={roles.debtor}><ResolvePage><FavouritesPage /></ResolvePage></ApolloProtected>} />

                            <Route path='assortiment'>
                                <Route path='categorie/:cat_id/:cat_name'>
                                    <Route index element={<ResolvePage><CategoryPage/></ResolvePage>} />
                                </Route>

                                <Route path='zoeken/:query' element={<ResolvePage><CategoryPage /></ResolvePage>} />
                                
                                <Route path='aanbiedingen'>
                                    <Route index element={<ResolvePage><PromotionsPage/></ResolvePage>} />
                                    <Route path=':cat_id' element={<ResolvePage><PromotionsPage/></ResolvePage>} />
                                </Route>

                                <Route path={':prod_id/:prod_name'} element={<ResolvePage><ProductPage /></ResolvePage>} />
                            </Route>

                            <Route path='backoffice'>
                                <Route index element={<ApolloProtected role={roles.admin}><ResolvePage backend={true}><h1>ADMINNNN</h1></ResolvePage></ApolloProtected>} />

                                <Route path='products'>
                                    <Route index element={<ApolloProtected role={roles.admin}><ResolvePage backend={true}><ProductOverview /></ResolvePage></ApolloProtected>} />
                                    <Route path="create" element={<ApolloProtected role={roles.admin}><ResolvePage backend={true}><ProductManage /></ResolvePage></ApolloProtected>} />
                                    <Route path=":prod_id/edit" element={<ApolloProtected role={roles.admin}><ResolvePage backend={true}><ProductManage /></ResolvePage></ApolloProtected>} />
                                    <Route path=":prod_id" element={<ApolloProtected role={roles.admin}><ResolvePage backend={true}><ProductOverview /></ResolvePage></ApolloProtected>} />
                                </Route>
                                <Route path='product-categories'>
                                    <Route path=':sub' element={<ApolloProtected role={roles.admin}><ResolvePage backend={true}><ProductCategoryOverview /></ResolvePage></ApolloProtected>} />
                                    <Route index element={<ApolloProtected role={roles.admin}><ResolvePage backend={true}><ProductCategoryOverview /></ResolvePage></ApolloProtected>} />
                                </Route>
                                
                                <Route path='companies'>
                                    <Route index element={<ApolloProtected role={roles.admin}><ResolvePage backend={true}><CompanyOverview /></ResolvePage></ApolloProtected>} />
                                    <Route path='create' element={<ApolloProtected role={roles.admin}><ResolvePage backend={true}><CompanyManage /></ResolvePage></ApolloProtected>} />
                                    <Route path=':id' element={<ApolloProtected role={roles.admin}><ResolvePage backend={true}><CompanyManage /></ResolvePage></ApolloProtected>} />
                                </Route>
                                
                                <Route path='orders'>
                                    <Route index element={<ApolloProtected role={roles.admin}><ResolvePage backend={true}><OrderOverview /></ResolvePage></ApolloProtected>} />
                                    <Route path=':id' element={<ApolloProtected role={roles.admin}><ResolvePage backend={true}><OrderManage /></ResolvePage></ApolloProtected>} />
                                </Route>

                                <Route path='promotions'>
                                    <Route index element={<ApolloProtected role={roles.admin}><ResolvePage backend={true}><PromotionsOverview /></ResolvePage></ApolloProtected>} />
                                    {/* <Route path='create' element={<ApolloProtected role={roles.admin}><ResolvePage backend={true}><UserManage /></ResolvePage></ApolloProtected>} /> */}
                                    {/* <Route path=':id' element={<ApolloProtected role={roles.admin}><ResolvePage backend={true}><UserManage /></ResolvePage></ApolloProtected>} /> */}
                                </Route>

                                <Route path='users'>
                                    <Route index element={<ApolloProtected role={roles.admin}><ResolvePage backend={true}><UserOverview /></ResolvePage></ApolloProtected>} />
                                    <Route path='create' element={<ApolloProtected role={roles.admin}><ResolvePage backend={true}><UserManage /></ResolvePage></ApolloProtected>} />
                                    <Route path=':id' element={<ApolloProtected role={roles.admin}><ResolvePage backend={true}><UserManage /></ResolvePage></ApolloProtected>} />
                                </Route>
                            </Route> 

                            {/* Framework routes */}
                            <Route path=":path">
                                <Route path=":id" element={<ResolvePage />} />
                                <Route path=":id/:urlTitle" element={<ResolvePage detailPage />} />
                                <Route index element={<ResolvePage />} />
                            </Route>
                        </Route>
                    </Routes>
                    <ApolloCookieConsent />
                </CookieConsentProvider>
            </BrowserRouter>
        </GoogleReCaptchaProvider>
    );
}
