// eslint-disable-next-line import/no-extraneous-dependencies
import React from 'react';
import axios from "axios";

import { Provider } from 'react-redux';
import store from './redux/store';

import { render } from 'react-dom';
import Apollo from './framework/Apollo';

import '../sass/theme/main.scss';

axios.defaults.withCredentials = true

// eslint-disable-next-line react/jsx-filename-extension
render(<Provider store={store}><Apollo /></Provider>, document.getElementById('app'));
