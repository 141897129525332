import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useApiUrl from '../../framework/hooks/useApiUrlHook';
import useAuth from '../../framework/hooks/useAuth';
import useCart from '../../framework/hooks/useCart';
import useHelpers from '../../framework/hooks/useHelpers';
import useRouteList from '../../framework/hooks/useRouteList';

function FavouriteRow({ item, callback = () => {} }) {
    const { urlEncode, money, localImage } = useHelpers();
    const routes = useRouteList();

    const {addToCart, toggleWishlist} = useCart();

    const [amount, setAmount] = useState(1);

    useEffect(() => {
        if(amount < 1) setAmount(1);
    }, [amount]);

    const handleAddProduct = () => addToCart(item.id, amount);

    const handleToggleWishlist = () => {
        toggleWishlist(item.product.id);
        callback();
    }

    useEffect(() => {
        const int = parseInt(amount);
        if(isNaN(int)) {
            setAmount(0);
        } else {
            setAmount(int);
        }
    }, [amount]);

    return (
        <tr className="pt-30">
            <td className="image product-thumbnail">
                <img
                    src={localImage(item?.product?.images[0]?.path, {
                        width: 150,
                        height: 150,
                    })}
                />
            </td>

            <td className="product-des product-name">
                <div className="product-category">
                    <Link to="/products">{item?.product?.brand?.name}</Link>
                </div>
                <h6 className="product-name">
                    <Link
                        to={routes.products.detail
                            .replace(':prod_id', item?.product?.id)
                            .replace(':prod_name', urlEncode(item?.product?.name))}
                    >
                        {item?.product?.name}
                    </Link>
                </h6>
                <div>
                    <span className="font-small text-muted">
                        {item?.product?.amount > 1 ? item?.product?.amount : null} {item?.product?.packaging_type?.name}{' '}
                        {item?.product?.size} {item?.product?.size_unit?.unit}
                    </span>
                </div>
            </td>
            <td className="price" data-title="Price">
                <h4 className="text-brand">
                    {item?.product?.discount_price_gross && item?.product?.discount_price_gross !== null
                        ? money(item?.product?.discount_price_gross)
                        : money(item?.product?.price_gross)}
                    {item?.product?.discount_price_gross && item?.product?.discount_price_gross !== null ? (
                        <span className="old-price">{money(item?.product?.price_gross)}</span>
                    ) : null}
                </h4>
            </td>
            <td className="text-center detail-info" data-title="Stock">
                <div className="detail-extralink mr-15">
                    <div className="detail-qty border radius ">
                        <div
                        onClick={(e) => setAmount(amount - 1)}
                            className="qty-down"
                        >
                            <i className="fi-rs-angle-small-down"></i>
                        </div>
                        <input type="text" className="qty-val" value={amount} onChange={(e) => setAmount(e.target.value)} />
                        <div
                            onClick={(e) => setAmount(amount + 1)}
                            className="qty-up"
                        >
                            <i className="fi-rs-angle-small-up"></i>
                        </div>
                    </div>
                </div>
            </td>
            <td className="text-right" data-title="Cart">
                <div className="btn" onClick={handleAddProduct}>
                    <i className="fi-rs-shopping-cart mr-5"></i> In winkelwagen
                </div>
            </td>
            <td className="action" data-title="Remove">
                <div className="btn" onClick={handleToggleWishlist}>
                    <i className="fi-rs-cross mr-5"></i> Verwijder
                </div>
            </td>
        </tr>
    );
}

export default function FavouritesPage() {
    const [wishlist, setWishlist] = useState(null);

    const apiUrl = useApiUrl();
    const { user } = useAuth();
    
    const getWishlist = () => {
        axios.get(`${apiUrl}/favourites`).then((response) => {
            setWishlist(response.data);
        });
    };

    useEffect(() => {
        if (apiUrl !== null && wishlist === null) getWishlist();
    }, [apiUrl]);

    return (
        <section className="mt-50 mb-50">
            <div className="container">
                <div className="row">
                    <div className="col-xl-10 col-lg-1 mb-40 m-auto">
                        <h1 className="heading-2 mb-10">
                            Favorieten van {user?.company?.name ?? user?.name ?? '...'}
                        </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-10 col-lg-12 m-auto">
                        {wishlist?.length > 0 ? (
                            <div className="table-responsive shopping-summery">
                                <table className="table table-wishlist">
                                    <thead>
                                        <tr className="main-heading">
                                            <th className="custome-checkbox start pl-30" colSpan={2}>
                                                Product
                                            </th>
                                            <th scope="col">Stuksprijs</th>
                                            <th scope="col">Aantal</th>
                                            <th scope="col">Toevoegen</th>
                                            <th scope="col" className="end">
                                                Verwijderen
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {wishlist?.map((item, i) => (
                                            <FavouriteRow item={item} key={i} callback={getWishlist} />
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <h4 className="mb-0">No Products</h4>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
}
