import { Link } from 'react-router-dom';
import useMenu from '../framework/hooks/useMenuHook';

export default function Navigation() {
    const menu = useMenu();

    return (
        <nav>
            <ul>
                {menu?.map((e, i) => (
                    <li key={i}>
                        <Link to={`/${e.url_titel}`}>{e.titel}</Link>

                        {e.sub === null ? (
                            ''
                        ) : (
                            <ul className="sub">
                                {e.sub.map((e2, i2) => (
                                    <li key={i2}>
                                        <Link to={`/${e2.url_titel}`}>{e2.titel}</Link>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </li>
                ))}
            </ul>
        </nav>
    );
}
