export default function ShowSelect({ }) {
    return (
        <>
            <div className="sort-by-product-wrap">
                <div className="sort-by">
                    <span>
                        <i className="fi-rs-apps"></i>
                        Show:
                    </span>
                </div>
                <div className="sort-by-dropdown-wrap custom-select">
                    <select>
                        <option value={0}>{0}</option>
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                    </select>
                </div>
            </div>
        </>
    );
}
