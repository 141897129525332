import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import ProductTab from '../../../components/elements/ProductTab';
import RelatedSlider from '../../../components/sliders/Related';
import ThumbSlider from '../../../components/sliders/Thumb';
import useApiUrl from '../../../framework/hooks/useApiUrlHook';
import useCart from '../../../framework/hooks/useCart';
import useHelpers from '../../../framework/hooks/useHelpers';
import useRouteList from '../../../framework/hooks/useRouteList';

export default function ProductPage({ productObj = null, quickView = false }) {
    const [isFavourite, setIsFavourite] = useState(false);

    const [quantity, setQuantity] = useState(1);
    const [product, setProduct] = useState(productObj);
    const [amount, setAmount] = useState(1);

    useEffect(() => {
        if (amount < 1) setAmount(1);
    }, [amount]);

    useEffect(() => {
        const int = parseInt(amount);
        if (isNaN(int)) {
            setAmount(0);
        } else {
            setAmount(int);
        }
    }, [amount]);

    const params = useParams();
    const apiUrl = useApiUrl();

    const helpers = useHelpers();

    const routes = useRouteList();

    const { addToCart, toggleWishlist } = useCart();

    const handleCart = (product) => {
        addToCart(product.id, amount);
    };

    const handleWishlist = async (product) => {
        const res = await toggleWishlist(product.id);
        setIsFavourite(res);
    };

    useEffect(() => {
        if (product) {
            setIsFavourite(product?.isFavourite ? true : false);
        }
    }, [product]);

    useEffect(() => {
        async function getProduct() {
            await axios.get(`${apiUrl}/products/${params.prod_id}`).then((response) => {
                setProduct(response.data);
            });
        }

        if (apiUrl && product === null) getProduct();
    }, [apiUrl]);

    return (
        <>
            <section className="mt-50 mb-50">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-9">
                            <div className="product-detail accordion-detail">
                                <div className="row mb-50  mt-30">
                                    <div className="col-md-6 col-sm-12 col-xs-12 mb-md-0 mb-sm-5">
                                        <div className="detail-gallery">
                                            <span className="zoom-icon">
                                                <i className="fi-rs-search"></i>
                                            </span>

                                            <div className="product-image-slider">
                                                <ThumbSlider product={product} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 col-xs-12">
                                        <div className="detail-info  pr-30 pl-30">
                                            <div className="product-category">
                                                <Link to="/products">{product?.brand?.name}</Link>
                                            </div>
                                            <h2 className="title-detail">{product?.name}</h2>
                                            <div className="clearfix product-price-cover">
                                                <div className="product-price primary-color float-left">
                                                    {product?.price_gross ? (
                                                        <span className="current-price text-brand">
                                                            {helpers.money(helpers.getProductCurrentPrice(product))}
                                                        </span>
                                                    ) : (
                                                        <span>
                                                            <Link to={routes.login}>Log in om prijzen te zien</Link>
                                                        </span>
                                                    )}
                                                    <span>
                                                        {helpers.getProductOldPrice(product) !== null ? (
                                                            <>
                                                                <span className="save-price font-md color3 ml-15">
                                                                    {helpers.getProductDiscountString(product)}
                                                                </span>
                                                                <span className="old-price">
                                                                    {helpers.money(helpers.getProductOldPrice(product))}
                                                                </span>
                                                            </>
                                                        ) : null}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="attr-detail attr-color mb-15">
                                                <div>
                                                    <span className="font-small text-muted">
                                                        {product?.amount > 1 ? product?.amount : null}{' '}
                                                        {product?.packaging_type?.name} {product?.size}{' '}
                                                        {product?.size_unit?.unit}
                                                    </span>
                                                </div>
                                            </div>

                                            {product?.description > 0 ? (
                                                <div className="short-desc mb-30">
                                                    <strong>Beschrijving</strong>
                                                    <p className="font-lg">{product?.description}</p>
                                                </div>
                                            ) : null}

                                            {product?.ingredients?.length > 0 ? (
                                                <div className="short-desc mb-30">
                                                    <strong>Ingrediënten</strong>
                                                    <p className="font-lg">{product?.ingredients}</p>
                                                </div>
                                            ) : null}

                                            <div className="bt-1 border-color-1 mt-30 mb-30"></div>
                                            {product?.price_gross ? (
                                                <div className="detail-extralink">
                                                    <div className="detail-qty border radius ">
                                                        <div
                                                            onClick={(e) => setAmount(amount - 1)}
                                                            className="qty-down"
                                                        >
                                                            <i className="fi-rs-angle-small-down"></i>
                                                        </div>
                                                        <input
                                                            type="text"
                                                            className="qty-val"
                                                            value={amount}
                                                            onChange={(e) => setAmount(e.target.value)}
                                                        />
                                                        <div onClick={(e) => setAmount(amount + 1)} className="qty-up">
                                                            <i className="fi-rs-angle-small-up"></i>
                                                        </div>
                                                    </div>
                                                    <div className="product-extra-link2">
                                                        <button
                                                            className="button button-add-to-cart"
                                                            onClick={(e) => handleCart(product)}
                                                        >
                                                            In winkelwagen
                                                        </button>
                                                        <div
                                                            aria-label="Add To Wishlist"
                                                            className={`action-btn hover-up ${
                                                                isFavourite ? 'active' : ''
                                                            }`}
                                                            onClick={() => handleWishlist(product)}
                                                        >
                                                            <i className="fi-rs-heart"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>

                                <ProductTab product={product} />

                                {product?.product_upsells?.length ? (
                                    <div className="row mt-60">
                                        <div className="col-12">
                                            <h3 className="section-title style-1 mb-30">Gerelateerde producten</h3>
                                        </div>
                                        <div className="col-12">
                                            <div className="row related-products position-relative">
                                                <RelatedSlider products={product?.product_upsells} />
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>

                        <div
                            className="col-xl-3 primary-sidebar sticky-sidebar"
                            // style="position: relative; overflow: visible; box-sizing: border-box; min-height: 1px;"
                        >
                            <div
                                className="theiaStickySidebar"
                                // style="padding-top: 0px; padding-bottom: 1px; position: static; transform: none; top: 0px; left: 1653.44px;"
                            >
                                {product?.product_alternatives?.length ? (
                                    <div className="sidebar-widget product-sidebar mb-30 p-30 bg-grey border-radius-10">
                                        <h5 className="section-title style-1 mb-30">Alternatieve producten</h5>
                                        {product?.product_alternatives?.map((item, index) => {
                                            return (
                                                <Link
                                                    key={index}
                                                    className="single-post clearfix"
                                                    to={routes.products.detail
                                                        .replace(':prod_id', item?.id)
                                                        .replace(':prod_name', helpers.urlEncode(item?.name))}
                                                >
                                                    <div className="image">
                                                        <img
                                                            src={helpers.localImage(
                                                                helpers.productImage(item?.images, 0),
                                                                {
                                                                    width: 250,
                                                                    height: 250,
                                                                },
                                                            )}
                                                            alt="#"
                                                        />
                                                    </div>
                                                    <div className="content pt-10">
                                                        <div className="product-badges product-badges-position product-badges-mrg">
                                                            {helpers.getProductDiscountString(item) !== null && (
                                                                <span className="sale">Aanbieding!</span>
                                                            )}
                                                        </div>

                                                        <div className="product-category">
                                                            <Link to="/products">{item?.brand?.name}</Link>
                                                        </div>
                                                        <span className="font-small text-muted">
                                                            {product?.amount > 1 ? product?.amount : null}{' '}
                                                            {product?.packaging_type?.name} {product?.size}{' '}
                                                            {product?.size_unit?.unit}
                                                        </span>
                                                        <h5>{item?.name}</h5>
                                                        {item.price_gross ? (
                                                            <>
                                                                <div className="font-small text-muted sale">
                                                                    {helpers.getProductDiscountString(item)}
                                                                </div>
                                                                <div className="product-card-bottom">
                                                                    <div className="product-price">
                                                                        <span>
                                                                            {helpers.money(
                                                                                helpers.getProductCurrentPrice(item),
                                                                            )}
                                                                        </span>
                                                                        {helpers.getProductOldPrice(item) !== null ? (
                                                                            <span className="old-price">
                                                                                {helpers.money(
                                                                                    helpers.getProductOldPrice(item),
                                                                                )}
                                                                            </span>
                                                                        ) : null}
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <Link to={routes.login}>Log in om prijzen te zien</Link>
                                                        )}
                                                    </div>
                                                </Link>
                                            );
                                        })}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
