import axios from 'axios';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';

import { Helmet } from 'react-helmet';
import HomePage from '../views/HomePage';
import ContentPage from '../views/ContentPage';
import useApiUrl from './hooks/useApiUrlHook';
import Header from '../components/layout/Header';
import MobileMenu from '../components/layout/MobileMenu';
import Footer from '../components/layout/Footer';
import HeaderBackend from '../components/layout/HeaderBackend';
import ContentBackend from '../components/layout/ContentBackend';
import NotFoundPage from '../views/NotFoundPage';

/**
 * Catchall for unmatched routes reroutes to correct view.
 */
export default function ResolvePage({ backend = false, children }) {
    const { path } = useParams();
    const apiUrl = useApiUrl();

    const location = useLocation();

    const [page, setPage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const [isToggled, setToggled] = useState(false);
    const toggleClick = () => {
        setToggled(!isToggled);
        isToggled
            ? document.querySelector('body').classList.remove('mobile-menu-active')
            : document.querySelector('body').classList.add('mobile-menu-active');
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    useEffect(() => {
        if (apiUrl === null) return;

        axios
            .post(`${apiUrl}/page`, { path })
            .then((response) => {
                setPage(response.data);
            })
            .catch((error) => {
                setPage({ script: '404', titel: 'Pagina niet gevonden' });
            });
    }, [path, apiUrl]);

    if (errorMessage !== null) return <div>Foutmelding page {errorMessage}</div>;

    if (page === null && children === undefined) return <div>Loading </div>;

    let pageElement;

    switch (page?.script) {
        case '404':
            return (
                <>
                    {children === null ? (
                        <Helmet>
                            <title>
                                {page.titletag !== '' ? page.titletag : page.titel} -{' '}
                                {process.env.MIX_REACT_APP_APP_NAME}
                            </title>
                            <meta
                                name="name"
                                content={`${page.titletag !== '' ? page.titletag : page.titel} - ${
                                    process.env.MIX_REACT_APP_APP_NAME
                                }`}
                            />
                            {page.image !== null && page.image !== '' ? (
                                <meta
                                    name="image"
                                    content={`${apiUrl}/img/exactg/website/1920/1080/100/${page.image}`}
                                />
                            ) : null}
                            <meta name="description" content={page.description} />
                            <meta name="keywords" content={page.keywords} />
                        </Helmet>
                    ) : null}
                    <Header isToggled={isToggled} toggleClick={toggleClick} />
                    <MobileMenu isToggled={isToggled} toggleClick={toggleClick} />
                    <NotFoundPage />
                    <Footer />
                </>
            );
        case 'home':
            pageElement = <HomePage page={page} />;
            break;
        default:
            pageElement = <ContentPage page={page} />;
            break;
    }

    if (!backend) {
        return (
            <>
                {children === null ? (
                    <Helmet>
                        <title>
                            {page.titletag !== '' ? page.titletag : page.titel} - {process.env.MIX_REACT_APP_APP_NAME}
                        </title>
                        <meta
                            name="name"
                            content={`${page.titletag !== '' ? page.titletag : page.titel} - ${
                                process.env.MIX_REACT_APP_APP_NAME
                            }`}
                        />
                        {page.image !== null && page.image !== '' ? (
                            <meta name="image" content={`${apiUrl}/img/exactg/website/1920/1080/100/${page.image}`} />
                        ) : null}
                        <meta name="description" content={page.description} />
                        <meta name="keywords" content={page.keywords} />
                    </Helmet>
                ) : null}
                <Header isToggled={isToggled} toggleClick={toggleClick} />
                <MobileMenu isToggled={isToggled} toggleClick={toggleClick} />
                {children ?? pageElement}
                <Footer />
            </>
        );
    } else {
        return (
            <>
                {children === null ? (
                    <Helmet>
                        <title>Dashboard - {process.env.MIX_REACT_APP_APP_NAME}</title>
                    </Helmet>
                ) : null}
                <div className="backend">
                    <HeaderBackend />
                    <ContentBackend>{children ?? pageElement}</ContentBackend>
                </div>
            </>
        );
    }
}
