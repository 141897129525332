import { Helmet } from 'react-helmet';
import useHelpers from '../../framework/hooks/useHelpers';
import './scss/invoice.scss';

export default function Invoice({ order }) {
    const { money } = useHelpers();

    return (
        <>
            <Helmet
                style={[
                    {
                        cssText: `
                    @media print {
                        header, footer { display: none; }
                    }
                    `,
                    },
                ]}
            />
            <div id="invoice" className="invoice invoice-content invoice-2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="invoice-inner">
                                <div className="invoice-info" id="invoice_wrapper">
                                    <div className="invoice-header">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="invoice-numb">
                                                    <h4 className="invoice-header-1 mb-10 mt-20">
                                                        Bestelling: <span className="text-brand">{order?.id}</span>
                                                    </h4>
                                                    <h6 className="">
                                                        Geplaatst op:{' '}
                                                        {new Date(order?.created_at * 1000).toLocaleDateString('nl-NL')}
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="invoice-name text-end">
                                                    <div className="logo">
                                                        <a href="index.html">
                                                            <img src="/assets/imgs/theme/logo.svg" alt="logo" />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="invoice-top">
                                        <div className="row">
                                            <div className="col-lg-9 col-md-6">
                                                <div className="invoice-number">
                                                    <h4 className="invoice-title-1 mb-10">Factuuradres</h4>
                                                    <p className="invoice-addr-1">
                                                        <strong>{order?.invoice_address?.name}</strong>
                                                        <br />
                                                        {order?.invoice_address?.telephone}
                                                        <br />
                                                        {order?.invoice_address?.street_address}
                                                        <br />
                                                        {order?.invoice_address?.postal_code}{' '}
                                                        {order?.invoice_address?.city}
                                                        <br />
                                                        {order?.invoice_address?.administrative_area}
                                                        <br />
                                                        {order?.invoice_address?.country}
                                                        <br />
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="invoice-number">
                                                    <h4 className="invoice-title-1 mb-10">Leveradres</h4>
                                                    <p className="invoice-addr-1">
                                                        <strong>{order?.delivery_address?.name}</strong>
                                                        <br />
                                                        {order?.delivery_address?.telephone}
                                                        <br />
                                                        {order?.delivery_address?.street_address}
                                                        <br />
                                                        {order?.delivery_address?.postal_code}{' '}
                                                        {order?.delivery_address?.city}
                                                        <br />
                                                        {order?.delivery_address?.administrative_area}
                                                        <br />
                                                        {order?.delivery_address?.country}
                                                        <br />
                                                        Bezorgdatum: {new Date(order?.delivery_date).toLocaleDateString('nl-NL')}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="invoice-center">
                                        <div className="table-responsive">
                                            <table className="table table-striped invoice-table">
                                                <thead className="bg-active">
                                                    <tr>
                                                        <th>Product</th>
                                                        <th className="text-center">Stuksprijs</th>
                                                        <th className="text-center">Aantal</th>
                                                        <th className="text-right">Subtotaal</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {order?.order_lines?.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>
                                                                    <div className="item-desc-1">
                                                                        <span>{item?.name}</span>
                                                                        <small>#{item?.item_number}</small>
                                                                    </div>
                                                                </td>
                                                                <td className="text-center">
                                                                    {money(item?.price_single_gross)}
                                                                </td>
                                                                <td className="text-center">{item?.amount}</td>
                                                                <td className="text-right">
                                                                    {money(item?.price_total_gross)}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}

                                                    <tr>
                                                        <td colSpan={3} className="text-end f-w-600">
                                                            Subtotaal
                                                        </td>
                                                        <td className="text-right">{money(order?.total_gross)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={3} className="text-end f-w-600">
                                                            BTW
                                                        </td>
                                                        <td className="text-right">
                                                            {money(order?.total_net - order?.total_gross)}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={3} className="text-end f-w-600">
                                                            Totaal
                                                        </td>
                                                        <td className="text-right f-w-600">
                                                            {money(order?.total_net)}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="invoice-btn-section clearfix d-print-none">
                                    <a onClick={() => window.print() } className="btn btn-lg btn-custom btn-print hover-up">
                                        {' '}
                                        <img src="assets/imgs/theme/icons/icon-print.svg" alt="" /> Afdrukken{' '}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
