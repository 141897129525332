import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import StatusPill from '../../../components/backoffice/orders/StatusPill';
import useApiUrl from '../../../framework/hooks/useApiUrlHook';
import useHelpers from '../../../framework/hooks/useHelpers';
import useRouteList from '../../../framework/hooks/useRouteList';

export default function OrderManage() {
    const [order, setOrder] = useState(null);
    const [statuses, setStatuses] = useState([]);
    const [internalNote, setInternalNote] = useState('');
    const [selectedStatus, setSelectedStatus] = useState(-1);

    const apiUrl = useApiUrl();
    const routes = useRouteList();

    const { id } = useParams();
    const { money, localImage, urlEncode } = useHelpers();

    useEffect(() => {
        if (apiUrl !== null && order == null) {
            axios
                .get(`${apiUrl}/order/admin/${id}/get`)
                .then((response) => {
                    setOrder(response.data);
                    setInternalNote(response.data.internal_note);
                })
                .catch(() => {
                    toast.error('Er ging iets mis met het ophalen van deze bestelling');
                });
        }

        if (apiUrl !== null && statuses.length == 0) {
            axios
                .get(`${apiUrl}/status`)
                .then((response) => {
                    setStatuses(response.data);
                })
                .catch(() => {
                    toast.error('Er ging iets mis met het ophalen van de statussen');
                });
        }
    }, [apiUrl, order]);

    const handleNewStatus = (status) => {
        if (!status || status < 0) {
            toast.error('Kies een geldige status');
        } else if (apiUrl !== null || (order !== null && order.id && status)) {
            axios
                .post(`${apiUrl}/order/admin/${order.id}/status/${status}`)
                .then(() => {
                    setOrder(null);
                    setSelectedStatus(-1);
                    toast.success('Nieuwe status opgeslagen!');
                })
                .catch(() => {
                    toast.error('Kon de nieuwe status niet opslaan. (E-01)');
                });
        } else {
            toast.error('Kon de nieuwe status niet opslaan. (E-02)');
        }
    };

    const saveInternalNote = () => {
        if (apiUrl !== null) {
            axios.put(`${apiUrl}/order/admin/${order.id}/note`, {note: internalNote})
            .then(() => {
                toast.success('Opmerking opgeslagen!');
            })
            .catch(() => toast.error("Kon opmerking niet opslaan, probeer het later opnieuw."));
        }
    };

    return (
        <section className="content-main">
            <div className="content-header">
                <div>
                    <h2 className="content-title card-title">Bestelling</h2>
                    <p>Details voor order id: {order?.id}</p>
                </div>
            </div>
            <div className="card">
                <header className="card-header">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6 mb-lg-0 mb-15">
                            <span>
                                {' '}
                                <i className="material-icons md-calendar_today"></i>{' '}
                                <b>{new Date(order?.created_at * 1000).toLocaleDateString('nl-NL')}</b>{' '}
                            </span>{' '}
                            <br />
                            <small className="text-muted">Order ID: {order?.id}</small>
                        </div>
                        <div className="col-lg-6 col-md-6 ms-auto text-md-end">
                            <select
                                className="form-select d-inline-block mb-lg-0 mr-5 mw-200"
                                value={selectedStatus}
                                onChange={(e) => setSelectedStatus(e.target.value)}
                            >
                                <option disabled value={-1}>
                                    Change status
                                </option>
                                {statuses.map((item, index) => (
                                    <option key={index} value={item.id}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                            <div
                                className="btn btn-primary"
                                onClick={() => {
                                    handleNewStatus(selectedStatus);
                                }}
                            >
                                Save
                            </div>
                            <a className="btn btn-secondary print ms-2" href="#">
                                <i className="icon material-icons md-print"></i>
                            </a>
                        </div>
                    </div>
                </header>
                <div className="card-body">
                    <div className="row mb-50 mt-20 order-info-wrap">
                        <div className="col-md-4">
                            <article className="icontext align-items-start">
                                <span className="icon icon-sm rounded-circle bg-primary-light">
                                    <i className="text-primary material-icons md-person"></i>
                                </span>
                                <div className="text">
                                    <h6 className="mb-1">Klant</h6>
                                    <p className="mb-1">
                                        {order?.company?.name} <br />
                                        {order?.user ? (
                                            <>
                                                T.a.v. {order?.user?.name} <br />
                                            </>
                                        ) : null}
                                        {order?.user ? (
                                            <>
                                                {order?.user?.email} <br />
                                            </>
                                        ) : null}
                                        {order?.invoice_address?.telephone}
                                        <br />
                                        <br />
                                        {order?.invoice_address?.street_address}
                                        <br />
                                        {order?.invoice_address?.postal_code} {order?.invoice_address?.city}
                                        <br />
                                        {order?.invoice_address?.administrative_area}, {order?.invoice_address?.country}
                                        <br />
                                    </p>
                                </div>
                            </article>
                        </div>
                        <div className="col-md-4">
                            <article className="icontext align-items-start">
                                <span className="icon icon-sm rounded-circle bg-primary-light">
                                    <i className="text-primary material-icons md-local_shipping"></i>
                                </span>
                                <div className="text">
                                    <h6 className="mb-1">Leveradres</h6>
                                    <p className="mb-1">
                                        {order?.delivery_address?.name} <br />
                                        {order?.user ? (
                                            <>
                                                T.a.v. {order?.user?.name} <br />
                                            </>
                                        ) : null}
                                        {order?.user ? (
                                            <>
                                                {order?.user?.email} <br />
                                            </>
                                        ) : null}
                                        {order?.delivery_address?.telephone}
                                        <br />
                                        <br />
                                        {order?.delivery_address?.street_address}
                                        <br />
                                        {order?.delivery_address?.postal_code} {order?.delivery_address?.city}
                                        <br />
                                        {order?.delivery_address?.administrative_area},{' '}
                                        {order?.delivery_address?.country}
                                        <br />
                                    </p>
                                </div>
                            </article>
                        </div>
                        <div className="col-md-4">
                            <article className="icontext align-items-start">
                                <span className="icon icon-sm rounded-circle bg-primary-light">
                                    <i className="text-primary material-icons md-place"></i>
                                </span>
                                <div className="text">
                                    <h6 className="mb-1">Bezorgdatum</h6>
                                    <p className="mb-1">{new Date(order?.delivery_date).toLocaleDateString('nl-NL')}</p>
                                </div>
                            </article>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <td width={'40%'}>Product</td>
                                            <td width={'20%'}>Stuksprijs</td>
                                            <td width={'20%'}>Aantal</td>
                                            <td width={'20%'} className="text-end">
                                                Subtotaal
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {order?.order_lines?.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        <Link
                                                            target="_blank"
                                                            className="itemside"
                                                            to={routes.products.detail
                                                                .replace(':prod_id', item?.product?.id)
                                                                .replace(':prod_name', urlEncode(item?.product?.name))}
                                                        >
                                                            <div className="left">
                                                                {item?.product?.images === undefined ||
                                                                item?.product?.images.length < 1 ? (
                                                                    <div
                                                                        style={{ width: '40px', height: '40px' }}
                                                                    ></div>
                                                                ) : (
                                                                    <img
                                                                        src={localImage(item?.product?.images[0].path, {
                                                                            width: 50,
                                                                            height: 50,
                                                                        })}
                                                                        width="40"
                                                                        height="40"
                                                                        className="img-xs"
                                                                        alt="Item"
                                                                    />
                                                                )}
                                                            </div>
                                                            <div className="info">
                                                                <span>{item?.name}</span>
                                                                <br />
                                                                <small>#{item?.item_number}</small>
                                                            </div>
                                                        </Link>
                                                    </td>
                                                    <td>{money(item?.price_single_gross)}</td>
                                                    <td>{item?.amount}</td>
                                                    <td className="text-end">{money(item?.price_total_gross)}</td>
                                                </tr>
                                            );
                                        })}

                                        <tr>
                                            <td colSpan={4}>
                                                <article className="float-end">
                                                    <dl className="dlist">
                                                        <dt>Subtotaal:</dt>
                                                        <dd>{money(order?.total_gross)}</dd>
                                                    </dl>
                                                    <dl className="dlist">
                                                        <dt>BTW:</dt>
                                                        <dd>{money(order?.total_net - order?.total_gross)}</dd>
                                                    </dl>
                                                    <dl className="dlist">
                                                        <dt>Totaal:</dt>
                                                        <dd>
                                                            <b className="h5">{money(order?.total_net)}</b>
                                                        </dd>
                                                    </dl>
                                                    <dl className="dlist">
                                                        <dt className="text-muted">Status:</dt>
                                                        <dd>
                                                            <StatusPill statusHistory={order?.status} />
                                                        </dd>
                                                    </dl>
                                                </article>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-lg-1"></div>
                        <div className="col-lg-4">
                            <div className="box shadow-sm bg-light">
                                <h6 className="mb-15">Historie</h6>
                                {order?.status?.map((stat, index) => {
                                    return (
                                        <p key={index}>
                                            {new Date(stat?.pivot.created_at).toLocaleDateString('nl-NL')} - {stat.name}
                                        </p>
                                    );
                                })}
                            </div>
                            <div className="box shadow-sm bg-light mt-4">
                                <h6 className="mb-15">Opmerking klant</h6>
                                <p>{order?.comment}</p>
                            </div>
                            <div className="h-25 pt-4">
                                <div className="mb-3">
                                    <label>Interne opmerking</label>
                                    <textarea
                                        className="form-control"
                                        name="notes"
                                        id="notes"
                                        placeholder="Type some note"
                                        value={internalNote}
                                        onChange={(e) => setInternalNote(e.target.value)}
                                    />
                                </div>
                                <button className="btn btn-primary" onClick={saveInternalNote}>
                                    Save note
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
