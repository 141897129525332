import { useEffect, useState } from 'react';
import CheckBox from './Checkbox';

export default function VendorFilter({ brands, updateVendors, activeFilters }) {
    const [vendors, setVendorCheckbox] = useState(null);
    const [selectedVendors, setVendor] = useState([]);

    const handleCheckBox = (brandId, checked = false) => {
        let updateVendors = [...selectedVendors];

        if (checked) {
            if(!updateVendors.includes(brandId)) {
                updateVendors = [...updateVendors, brandId];
            }
        } else {
            updateVendors.splice(updateVendors.indexOf(brandId), 1);
        }

        setVendor([...updateVendors]);
    };

    useEffect(() => {
        updateVendors(selectedVendors);
    }, [selectedVendors]);

    useEffect(() => {
        setVendorCheckbox(brands);
    }, [brands]);

    if (vendors === null) return null;

    return (
        <>
            <div className="custome-checkbox">
                {vendors?.map((item, key) => (
                    <CheckBox
                        key={key}
                        filter={item}
                        activeFilters={selectedVendors}
                        handleCheckBox={handleCheckBox}
                    />
                ))}
            </div>
        </>
    );
}
