import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import useAuth from '../../framework/hooks/useAuth';
import useRouteList from '../../framework/hooks/useRouteList';

export default function RegisterPage() {
    const routes = useRouteList();
    const auth = useAuth();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();


    const onSubmit = (data) => {
        auth.register(data.username, data.email, data.password, data.password_confirmation);
    };

    return (
        <>
            <div className="page-content pt-150 pb-150">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-10 col-md-12 m-auto">
                            <div className="row">
                                <div className="col-lg-6 col-md-8">
                                    <div className="login_wrap widget-taber-content background-white">
                                        <div className="padding_eight_all bg-white">
                                            <div className="heading_s1">
                                                <h1 className="mb-5">Registreren</h1>
                                                <p>
                                                    Heb je al een account? <Link to={routes.login}>Log dan in!</Link>
                                                </p>
                                            </div>
                                            <form onSubmit={handleSubmit(onSubmit)}>
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        placeholder="Voor- en achternaam"
                                                        {...register('username', {})}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <input type="text" placeholder="Email" {...register('email', {})} />
                                                </div>
                                                <div className="form-group">
                                                    <input
                                                        type="password"
                                                        placeholder="Wachtwoord"
                                                        {...register('password', {})}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <input
                                                        type="password"
                                                        placeholder="Herhaal wachtwoord"
                                                        {...register('password_confirmation', {})}
                                                    />
                                                </div>
                                                <div className="form-group mb-30">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-fill-out btn-block hover-up font-weight-bold"
                                                        name="login"
                                                    >
                                                        Registreren
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
